import { FlatList, ListRenderItemInfo, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import React, { useEffect, useMemo, useState } from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Contribution } from './types'
import { ContributionCard } from './components/ContributionCard'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useTheme } from '../../hooks/use-theme'
import { BumpDetailsProvider } from './components/BumpDetailsProvider'
import { ConfirmCensureProvider } from './components/ConfirmCensureProvider'
import {
  ContributionStatus,
  ContributionType,
  useContributionsForMyArticlesQuery,
} from '../../generated/graphql'
import ContributionTypeSelector from './components/ContributionTypeSelector'
import { SortSelector } from './components/SortSelector'
import _orderBy from 'lodash/orderBy'
import orderBy from 'lodash/orderBy'
import { BumpJustificationProvider } from './components/BumpJustificationProvider'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'

export function DecisionsScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'Decisions'>) {
  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const globalStyles = useGlobalStyles()
  const [typeFilter, setTypeFilter] = useState<ContributionType | undefined>(
    undefined
  )
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  const { loading, data, refetch } = useContributionsForMyArticlesQuery({
    variables: {
      status: ContributionStatus.Pending,
    },
    fetchPolicy: 'cache-and-network',
  })
  const isFocused = useIsFocused()
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])
  const contributions: Contribution[] = useMemo(
    () =>
      orderBy(
        (data?.contributionsForMyArticles || [])
          .filter((c) => c.contributionType != ContributionType.Reserved)
          .filter((c) => c.status == ContributionStatus.Pending)
          .filter((c) => !typeFilter || c.contributionType == typeFilter),
        ['createdAt'],
        [sortDirection]
      ),
    [data?.contributionsForMyArticles, typeFilter, sortDirection]
  )

  const styles = useMemo(
    () =>
      StyleSheet.create({
        empty: {
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        },
        list: {
          marginTop: theme.spacing(5),
          flex: 1,
        },
        menuRow: {
          marginBottom: theme.spacing(3),
          marginHorizontal: theme.spacing(5),
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        },
        sortBy: {},
        typeMenu: {
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          columnGap: theme.spacing(2),
        },
      }),
    [theme]
  )

  const renderItem = ({ item }: ListRenderItemInfo<Contribution>) => {
    return (
      <ContributionCard
        style={{ marginBottom: theme.spacing(4) }}
        key={item.id}
        showStatus={false}
        showEdit={true}
        contribution={item}
      />
    )
  }

  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <View style={styles.list}>
        <View style={styles.menuRow}>
          <View style={styles.sortBy}>
            <SortSelector
              direction={sortDirection}
              onDirection={setSortDirection}
            />
          </View>
          <View style={styles.typeMenu}>
            <Text>{t('Type')}</Text>
            <ContributionTypeSelector
              onSelect={setTypeFilter}
              selected={typeFilter}
            />
          </View>
        </View>
        {contributions.length > 0 ? (
          <BumpDetailsProvider>
            <BumpJustificationProvider>
              <ConfirmCensureProvider>
                <FlatList
                  style={{
                    paddingHorizontal: theme.spacing(5),
                  }}
                  data={contributions}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.id}
                  ListFooterComponent={<View style={{ height: 200 }}></View>}
                />
              </ConfirmCensureProvider>
            </BumpJustificationProvider>
          </BumpDetailsProvider>
        ) : (
          <View style={styles.empty}>
            <Text
              variant={'titleMedium'}
              style={{ color: theme.palettes.neutral[70]! }}
            >
              {t('No decisions')}
            </Text>
          </View>
        )}
      </View>
    </ScreenWrapper>
  )
}

export default DecisionsScreen
