import * as React from 'react'
import { Appbar } from 'react-native-paper'
import { getHeaderTitle } from '@react-navigation/elements'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { useTheme } from '../../../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import { AppNavigatorParams } from '../../../navigation/types'
import { useHeaderButton } from '../../../contexts/HeaderButtonProvider'
import LanguageButtonMenu from '../../../components/LanugageButtonMenu'

export interface ProfileNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function ProfileNavigationBar({
  navigation,
  route,
  options,
  back,
}: ProfileNavigationBarProps) {
  const theme = useTheme()
  const { t } = useTranslation('screens')
  const title = t(getHeaderTitle(options, route.name))
  const { icon, handler } = useHeaderButton('profileEdit')

  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={title} />
      {icon && (
        <Appbar.Action
          icon={icon}
          disabled={!handler}
          onPress={() => handler?.()}
        />
      )}
      <LanguageButtonMenu />
    </Appbar.Header>
  )
}
