import * as React from 'react'
import { Appbar, Divider, Menu } from 'react-native-paper'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconFlagChina, IconFlagUK } from '../utils/meldd-icons'

export default function LanguageButtonMenu() {
  const [visible, setVisible] = React.useState(false)
  const { t, i18n } = useTranslation('menuItems')

  const anchor = useMemo(() => {
    return (
      <Appbar.Action
        icon={i18n.language === 'cn' ? IconFlagChina : IconFlagUK}
        onPress={() => setVisible(true)}
      />
    )
  }, [i18n.language])
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={anchor}>
      <Menu.Item
        leadingIcon={IconFlagUK}
        onPress={() => {
          i18n.changeLanguage('en')
          setVisible(false)
        }}
        title={t('English')}
      />
      <Divider />
      <Menu.Item
        leadingIcon={IconFlagChina}
        onPress={() => {
          i18n.changeLanguage('cn')
          setVisible(false)
        }}
        title={t('Chinese')}
      />
    </Menu>
  )
}
