import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { useIsFocused } from '@react-navigation/native'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Text } from 'react-native-paper'
import { NonEditableContributionList } from './components/NonEditableContributionList'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'

export function UserContributionsScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'UserContributions'>) {
  const isFocused = useIsFocused()
  const { contributions, title } = route.params
  const globalStyles = useGlobalStyles()

  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <Text
        style={{ marginVertical: 20, textAlign: 'center' }}
        variant={'headlineSmall'}
      >
        {title}
      </Text>
      <NonEditableContributionList
        contributions={contributions}
        hideFilters={true}
      />
    </ScreenWrapper>
  )
}
export default UserContributionsScreen
