import { AntDesign, FontAwesome } from '@expo/vector-icons'
import { MaterialIcons } from '@expo/vector-icons'
import { OpaqueColorValue, TextProps } from 'react-native'
import colors from '../constants/colors'

type IconProps = TextProps & {
  size?: number
  color?: string | OpaqueColorValue
}
/**
 * Simple theme like structure to reduce magic constants
 */

export const Theme = {
  color: {
    disabled: colors.primaryInactive,
    separator: 'rgba(0,0,0,.1)',
    primary: {
      main: colors.primary,
      light: colors.primaryB,
    },
    warning: {
      main: colors.accent,
      light: colors.accent,
    },
  },
  padding: {
    '1': 4,
    '2': 8,
    '3': 12,
    '4': 16,
  },
  size: {
    icon: 24,
    iconButton: 32,
  },
  icons: {
    edit: (props: IconProps) => <FontAwesome {...props} name="pencil-square" />,
    eye: (props: IconProps) => <MaterialIcons {...props} name="visibility" />,
    import: (props: IconProps) => (
      <MaterialIcons {...props} name="file-download" />
    ),
    add: (props: IconProps) => <AntDesign {...props} name="plus" />,
  },
}
