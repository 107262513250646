import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { Appbar, Divider, Menu } from 'react-native-paper'
import { AppNavigatorParams } from '../navigation/types'
import { useLogoutMutation } from '../generated/graphql'
import { useContext } from 'react'
import { AuthContext } from '../auth/auth-context'
import { usePushToken } from '../contexts/PushTokenProvider'
import { useTranslation } from 'react-i18next'
import { IconVisualization } from '../utils/meldd-icons'
import { Env } from '../env'
import { useHeaderButton } from '../contexts/HeaderButtonProvider'
import { Platform } from 'react-native'

interface NavigationBarMenuProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export default function NavigationBarMenu(props: NavigationBarMenuProps) {
  const [visible, setVisible] = React.useState(false)
  const { navigation } = props
  const { handler } = useHeaderButton('visualizationCluster')
  const [LogoutUser, { client }] = useLogoutMutation()
  const authCtx = useContext(AuthContext)
  const pushToken = usePushToken()
  const { userId } = useContext(AuthContext)
  const { t } = useTranslation('menuItems')

  async function logout() {
    authCtx.clearCurrentUser()
    try {
      await LogoutUser(
        pushToken ? { variables: { input: { token: pushToken } } } : undefined
      )
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * NOTE! If you change state and then navigate, the state change fires on an unmounting component.
   * Thats why we have a timeout of 1 ms
   */
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Appbar.Action icon="dots-vertical" onPress={() => setVisible(true)} />
      }>
      <Menu.Item
        leadingIcon="information-outline"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('Intro'), 1)
        }}
        title={t('Intro')}
      />
      {Platform.select({
        native: (
          <Menu.Item
            leadingIcon={IconVisualization}
            onPress={() => {
              setVisible(false)
              setTimeout(() => {
                handler ? handler() : navigation.navigate('Visualization', {})
              }, 1)
            }}
            title={t('Visualization')}
          />
        ),
        default: null,
      })}
      <Menu.Item
        leadingIcon="account-outline"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('Profile', { userId }), 1)
        }}
        title={t('Profile')}
      />

      <Menu.Item
        leadingIcon="account"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('FeatureToggles'), 1)
        }}
        title={t('AboutUs')}
      />

      <Divider />
      <Menu.Item
        leadingIcon="logout"
        onPress={() => {
          setVisible(false)
          logout().then()
        }}
        title={t('Log out')}
      />
    </Menu>
  )
}
