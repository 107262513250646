import React from 'react'
import { View, ActivityIndicator, StyleSheet, Image, Text } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Icon } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

interface EmptyListProps {
  message?: string // Message to display below the image
  loading?: boolean // If loading we dont show empty data
}

const EmptyList: React.FC<EmptyListProps> = ({ message, loading }) => {
  const { colors } = useTheme()
  const { t } = useTranslation('common')

  if (loading) return null
  return (
    <View style={styles.container}>
      <Icon source={'inbox-outline'} size={48} />
      <Text style={[styles.message, { color: colors.text }]}>
        {message || t('empty-list')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: 300,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  message: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 32,
    paddingHorizontal: 20,
  },
})

export { EmptyList }
