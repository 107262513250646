import { StackScreenProps } from '@react-navigation/stack'
import * as Clipboard from 'expo-clipboard'
import { IconButton } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { Env } from '../../env'
import { Contribution, useContributionByIdQuery } from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { AppNavigatorParams } from '../../navigation/types'
import { MelddTaskTargetType } from '../tasks/task.types'
import { BumpDetailsProvider } from './components/BumpDetailsProvider'
import { BumpJustificationProvider } from './components/BumpJustificationProvider'
import { ConfirmCensureProvider } from './components/ConfirmCensureProvider'
import { ContributionCard } from './components/ContributionCard'

export function PerspectiveContributionScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'PerspectiveContribution'>) {
  const { contributionId } = route.params
  const theme = useTheme()
  const globalStyles = useGlobalStyles()

  const { data } = useContributionByIdQuery({ variables: { contributionId } })
  const contribution = data?.contributionById as Contribution

  const saveContributionUrl = async () => {
    let urlToClipBoard = `${Env.WEB_APP_URL}/PerspectiveContribution?contributionId=${contributionId}`
    Toast.show('URL copied!', { position: Toast.positions.TOP })
    await Clipboard.setStringAsync(urlToClipBoard)
  }

  if (!contribution) {
    return null
  }

  return (
    <ScreenWrapper style={[globalStyles.pageContainer]}>
      <BumpDetailsProvider>
        <BumpJustificationProvider>
          <ConfirmCensureProvider>
            <ContributionCard
              style={{ marginHorizontal: theme.spacing(5) }}
              showStatus={true}
              showEdit={false}
              contribution={contribution}
              children={
                <IconButton
                  mode="contained-tonal"
                  size={20}
                  icon="content-copy"
                  onPress={() => saveContributionUrl()}
                />
              }
            />
            <MButton
              targetType={MelddTaskTargetType.ARTICLE_TASK}
              targetId={contribution.articleId}
              preview={''}
            />
          </ConfirmCensureProvider>
        </BumpJustificationProvider>
      </BumpDetailsProvider>
    </ScreenWrapper>
  )
}
export default PerspectiveContributionScreen
