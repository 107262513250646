import * as React from 'react'
import { useMemo } from 'react'
import { Button, Card, Text } from 'react-native-paper'
import { Article } from '../../screens/PerspectiveCards/types'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../hooks/use-theme'
import { ContributionsTypesCount } from '../ContributionsTypesCount'
import { Dimensions, StyleSheet, View } from 'react-native'
import { ReadOnlyArticle } from './ReadOnlyArticle'
import { AppNavigation } from '../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { VoteButton, VoteButtonCluster } from '../vote/VoteButton'

interface PerspectiveCardProps {
  perspective: Article
  cluster: VoteButtonCluster
}

const width = Dimensions.get('window').width
const boxOverflow = 30
const boxWidth = width - 2 * boxOverflow

export function PerspectiveCard(props: PerspectiveCardProps) {
  const { perspective, cluster } = props
  const { t } = useTranslation('clusters')
  const navigation = useNavigation<AppNavigation>()
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        card: {
          flex: 1,
          height: 1, // To trigger the flex on iOS
          flexDirection: 'column',
        },
        content: {
          backgroundColor: theme.colors.surface,
          flex: 1,
          paddingHorizontal: 0,
          margin: 0,
        },
        title: {
          flex: 0,
          paddingVertical: theme.spacing(3),
          paddingHorizontal: theme.spacing(4),
        },
        subTitle: {
          color: theme.colors.onSurfaceVariant,
          flexDirection: 'row',
          columnGap: theme.spacing(1),
          marginTop: theme.spacing(3),
        },
        actions: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: theme.spacing(3),
          paddingHorizontal: theme.spacing(4),
        },
      }),
    [theme]
  )

  return (
    <Card
      style={styles.card}
      elevation={5}
      mode={'elevated'}
      contentStyle={{ flex: 1 }}
    >
      <View style={styles.title}>
        <Text variant={'titleLarge'} numberOfLines={1} ellipsizeMode={'tail'}>
          {perspective.title}
        </Text>
        <ContributionsTypesCount
          perspectiveId={perspective.id}
          style={styles.subTitle}
          contributions={perspective.contributions}
        />
      </View>
      <Card.Content style={styles.content}>
        <ReadOnlyArticle html={perspective.latestContent} width={boxWidth} />
      </Card.Content>
      <Card.Actions>
        <VoteButton cluster={cluster} perspective={perspective} />
        <Button
          mode="contained"
          onPress={() =>
            navigation.navigate('Perspective', {
              articleId: perspective.id,
            })
          }
        >
          {t('Open')}
        </Button>
      </Card.Actions>
    </Card>
  )
}
