import * as React from 'react'
import { View, StyleSheet, ActivityIndicator, StyleProp } from 'react-native'
import { AppNavigation } from '../../../navigation/types'
import { useInboundOutboundContributionsQuery } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { TitledTable } from '../../../components/TitledTable/TitledTable'
import { DataTable, Text, TouchableRipple } from 'react-native-paper'
import SingleCell from '../../../components/TitledTable/SingleCell'
import { useMemo } from 'react'
import { useTheme } from '../../../hooks/use-theme'
import { Icon } from '../../../components/Icon'
import {
  Icon3dChallenge,
  Icon3dEdit,
  Icon3dInquiry,
} from '../../../utils/meldd-icons'
import { ViewStyle } from 'react-native'
import { useAuthContext } from '../../../auth/auth-context'

interface UserContributionsProps {
  userId: string
  style?: StyleProp<ViewStyle>
}

export function UserContributions(props: UserContributionsProps) {
  const { userId } = props
  const { t } = useTranslation('contributions')
  const { authAction } = useAuthContext()
  const theme = useTheme()
  const navigation = useNavigation<AppNavigation>()
  const {
    loading: loading,
    error,
    data: data,
  } = useInboundOutboundContributionsQuery({ variables: { userId } })

  const inboundOutbound = data?.inboundOutboundContributions
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {},
        typeCol: {
          flex: 2,
          alignItems: 'center',
        },
        row: { paddingRight: 0 },

        valueCol: {
          alignItems: 'center',
          flex: 0.66,
          justifyContent: 'center',
          paddingHorizontal: 20,
        },
        inquiry: {
          backgroundColor: theme.colors.inquiry50,
        },
        challenge: {
          backgroundColor: theme.colors.challenge50,
        },
        edit: {
          backgroundColor: theme.colors.edit50,
        },
        corank: {
          flexDirection: 'row',
          marginVertical: 20,
          columnGap: 10,
        },
      }),
    [theme]
  )
  const corank = useMemo(() => {
    if (loading && !inboundOutbound) return <ActivityIndicator size={12} />
    if (error)
      return (
        <Text variant={'titleMedium'} style={{ color: theme.colors.primary }}>
          ??
        </Text>
      )
    return (
      <Text variant={'titleMedium'} style={{ color: theme.colors.primary }}>
        {inboundOutbound!.corank}
      </Text>
    )
  }, [loading, error, inboundOutbound])

  const content = useMemo(() => {
    if (loading && !inboundOutbound) return <SingleCell message={''} />
    if (error)
      return (
        <SingleCell key="0" message={t('Failed to load user contributions')} />
      )

    const { inbound, outbound } = inboundOutbound!
    return (
      <>
        <DataTable.Row style={styles.row} pointerEvents={'box-none'}>
          <DataTable.Cell style={styles.typeCol}>
            {t('Inbound A/R')}
          </DataTable.Cell>
          <TouchableRipple
            style={[
              styles.valueCol,
              { backgroundColor: theme.colors.inquiry50 },
            ]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: inbound.inquiry.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{inbound.inquiry.percentage}</Text>
          </TouchableRipple>
          <TouchableRipple
            style={[
              styles.valueCol,
              { backgroundColor: theme.colors.challenge50 },
            ]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: inbound.challenge.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{inbound.challenge.percentage}</Text>
          </TouchableRipple>
          <TouchableRipple
            style={[styles.valueCol, { backgroundColor: theme.colors.edit50 }]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: inbound.edit.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{inbound.edit.percentage}</Text>
          </TouchableRipple>
        </DataTable.Row>
        <DataTable.Row style={styles.row} pointerEvents={'box-none'}>
          <DataTable.Cell style={styles.typeCol}>
            {t('Outbound A/R')}
          </DataTable.Cell>
          <TouchableRipple
            style={[
              styles.valueCol,
              { backgroundColor: theme.colors.inquiry50 },
            ]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: outbound.inquiry.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{outbound.inquiry.percentage}</Text>
          </TouchableRipple>
          <TouchableRipple
            style={[
              styles.valueCol,
              { backgroundColor: theme.colors.challenge50 },
            ]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: outbound.challenge.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{outbound.challenge.percentage}</Text>
          </TouchableRipple>
          <TouchableRipple
            style={[styles.valueCol, { backgroundColor: theme.colors.edit50 }]}
            onPress={() => {
              authAction(navigation, () => navigation.navigate('UserContributions', {
                contributions: outbound.edit.contributions,
                title: t('Inbound A/R Inquiry'),
              }))
            }}>
            <Text>{outbound.edit.percentage}</Text>
          </TouchableRipple>
        </DataTable.Row>
      </>
    )
  }, [inboundOutbound, loading, error])
  return (
    <View style={props.style}>
      <View style={styles.corank}>
        <Text variant={'titleMedium'} style={{ color: theme.colors.primary }}>
          {t('CoRank')}:
        </Text>
        {corank}
      </View>
      <TitledTable
        headerStyle={styles.row}
        header={
          <>
            <DataTable.Title style={styles.typeCol}>
              {t('Type')}
            </DataTable.Title>
            <DataTable.Title style={styles.valueCol}>
              <Icon source={Icon3dInquiry} size={18} />
            </DataTable.Title>
            <DataTable.Title style={styles.valueCol}>
              <Icon source={Icon3dChallenge} size={18} />
            </DataTable.Title>
            <DataTable.Title style={styles.valueCol}>
              <Icon source={Icon3dEdit} size={18} />
            </DataTable.Title>
          </>
        }>
        {content}
      </TitledTable>
    </View>
  )
}
