import * as React from 'react'
import { createContext, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SocketEvents, useSocketRoom } from '../socket/socket.hooks'
import { useAuthContext } from '../auth/auth-context'
import Toast from 'react-native-root-toast'
import { getTargetDetail, MelddTasks } from '../screens/tasks/task.types'

interface NotificationProviderProps {
  children: React.ReactNode
}

interface NotificationProviderShape {}

const NotificationProviderContext = createContext<NotificationProviderShape>({})

/**
 *  Global Hooks for notifications
 */
export default function NotificationProvider(props: NotificationProviderProps) {
  const { userId } = useAuthContext()
  const { t } = useTranslation('notifications')
  // Task Completed Notifications
  const onTaskCompleted = useCallback((task: MelddTasks) => {
    if (!task) {
      return
    }
    Toast.show(t('task-completed') + ': ' + task.title, {
      duration: Toast.durations.LONG,
      position: Toast.positions.TOP,
    })
  }, [])
  useSocketRoom(userId).event<MelddTasks>(
    SocketEvents.TaskCompleted,
    onTaskCompleted
  )
  return (
    <NotificationProviderContext.Provider
      value={{}}
      children={props.children}
    />
  )
}
