import ShimmerPlaceHolder, {
  createShimmerPlaceHolder,
} from 'expo-shimmer-placeholder'
import { LinearGradient } from 'expo-linear-gradient'
import { createRef, useEffect } from 'react'
import { Animated, View } from 'react-native'
import Row from '../containers/Row'

const ShimmerPlaceholder = createShimmerPlaceHolder(LinearGradient)
export const AvatarLineSkeleton = ({
  size = 24,
  width = '100%',
}: {
  size?: number
  width?: number | string
}) => {
  // Handle animation
  const avatarRef = createRef<ShimmerPlaceHolder>()
  const firstLineRef = createRef<ShimmerPlaceHolder>()

  useEffect(() => {
    if (!avatarRef.current || !firstLineRef.current) return

    const animation = Animated.stagger(400, [
      avatarRef.current?.getAnimated(),
      Animated.parallel([firstLineRef.current?.getAnimated()]),
    ])
    Animated.loop(animation).start()
  }, [])

  return (
    <Row alignItems="center">
      <ShimmerPlaceholder
        width={size}
        height={size}
        style={{ marginRight: 10 }}
        ref={avatarRef}
        shimmerStyle={[{ borderRadius: size / 2 }]}
        stopAutoRun
      />
      <View style={{ justifyContent: 'space-between' }}>
        <ShimmerPlaceholder
          ref={firstLineRef}
          stopAutoRun
          height={size / 2}
          width={width}
        />
      </View>
    </Row>
  )
}
