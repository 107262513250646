import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { useWechatSignatureMutation } from '../generated/graphql'
import { isWeChatBrowser } from '../utils/wechat.login'
import { WechatSDK, wechatShare, WechatShareData } from '../utils/wechat.share'

type ShareData = {
  title: string
  description: string
}

// Internal hook
const useWechatShare = () => {
  const [wx, setWx] = useState<WechatSDK | null>(null)
  const [isReady, setReady] = useState(false)

  if (Platform.OS !== 'web') {
    return wechatShare.createMock(setWx)
  }

  useEffect(() => {
    if (wx) {
      setReady(true)
    }
  }, [wx])

  const updateShareData = useCallback(
    async (data: WechatShareData, url: string) => {
      if (!wx || !isReady) {
        return false
      }
      return await wechatShare.updateData(wx, data, url)
    },
    [wx, isReady]
  )

  return {
    isReady,
    canUse: isReady && wx !== null,
    setWx,
    updateShareData,
  }
}

// Public hook
export const useWechatShareInit = (
  getShareData: () => ShareData | null,
  dependencies: DependencyList = []
) => {
  const [doSignature] = useWechatSignatureMutation()
  const { setWx, canUse, updateShareData } = useWechatShare()
  const isInitialized = useRef(false)

  const wechatSignatureURL =
    Platform.OS === 'web' && isWeChatBrowser()
      ? location.href.split('#')[0]
      : null

  useEffect(() => {
    if (!wechatSignatureURL) return

    const initAndUpdateShare = async () => {
      // Initialize WeChat SDK if not already done
      if (!isInitialized.current) {
        const wxInstance = await wechatShare.init({
          sign: doSignature,
          url: wechatSignatureURL,
        })
        if (wxInstance) {
          setWx(wxInstance)
          isInitialized.current = true
        }
      }

      if (canUse) {
        const shareData = getShareData()
        if (shareData) {
          await updateShareData(shareData, wechatSignatureURL)
        }
      }
    }

    initAndUpdateShare()
  }, [canUse, wechatSignatureURL, ...dependencies])
}
