import * as React from 'react'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Surface, Text } from 'react-native-paper'
import { Contribution } from '../types'
import { ContributionType } from '../../../generated/graphql'
import { useTypeStrings } from '../../../hooks/use-type-strings'
import { IconButton } from 'react-native-paper'
import {
  Icon3dChallenge,
  Icon3dEdit,
  Icon3dInquiry,
  IconClose,
  IconNext,
  IconPrevious,
} from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../components/Icon'

interface ContributionDetailsProps {
  contribution: Contribution
  onNext: () => void
  onPrevious: () => void
  onClose: () => void
}

const icons = [
  { icon: Icon3dInquiry, type: ContributionType.Inquiry, label: 'Inquiry' },
  {
    icon: Icon3dChallenge,
    type: ContributionType.Challenge,
    label: 'Challenge',
  },
  { icon: Icon3dEdit, type: ContributionType.Edit, label: 'Edit' },
]

export function ContributionDetails(props: ContributionDetailsProps) {
  const { contribution: c, onClose, onNext, onPrevious } = props
  const { labelInput, labelMotivation } = useTypeStrings()
  const { t } = useTranslation('contributions')
  const theme = useTheme()
  const bkgColor = useMemo(() => {
    switch (c.contributionType) {
      case ContributionType.Edit:
        return theme.colors.editBkg
      case ContributionType.Challenge:
        return theme.colors.challengeBkg
      case ContributionType.Inquiry:
        return theme.colors.inquiryBkg
      default:
        return theme.colors.surfaceVariant
    }
  }, [theme, c.contributionType])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: bkgColor,
          paddingVertical: theme.spacing(5),
          marginTop: -theme.spacing(5),
          paddingHorizontal: theme.spacing(4),
          borderTopLeftRadius: theme.spacing(5),
          borderTopRightRadius: theme.spacing(5),

          flexDirection: 'column',
          // alignItems: 'stretch',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
        },
        type: {
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          columnGap: 10,
        },
        info: {
          rowGap: 10,
          paddingVertical: 10,
        },
        button: {
          // height: 32,
          // fontSize: 10,
          // flexGrow: 1,
          borderWidth: 1,
          borderColor: theme.colors.border,
        },
        buttons: {
          paddingVertical: 5,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        },
      }),
    [theme, bkgColor]
  )

  const showMotivation = useMemo(
    () =>
      c.contributionType == ContributionType.Edit ||
      c.contributionType === ContributionType.Challenge,
    [c]
  )
  const showInput = useMemo(
    () => c.contributionType !== ContributionType.Reserved,
    [c]
  )
  const type = useMemo(() => {
    switch (c.contributionType) {
      case ContributionType.Inquiry:
        return (
          <View style={styles.type}>
            <Icon source={Icon3dInquiry} size={24} />
            <Text variant={'titleMedium'}>{t('Inquiry')}</Text>
          </View>
        )
      case ContributionType.Challenge:
        return (
          <View style={styles.type}>
            <Icon source={Icon3dChallenge} size={24} />
            <Text variant={'titleMedium'}>{t('Challenge')}</Text>
          </View>
        )
      case ContributionType.Edit:
        return (
          <View style={styles.type}>
            <Icon source={Icon3dEdit} size={24} />
            <Text variant={'titleMedium'}>{t('Edit')}</Text>
          </View>
        )
      default:
        return (
          <View style={styles.type}>
            <Text>{t('Reserved')}</Text>
          </View>
        )
    }
  }, [c])
  return (
    <Surface style={styles.container} elevation={3}>
      {c && (
        <>
          <View style={styles.info}>{type}</View>
          {showInput && (
            <View style={styles.info}>
              <Text variant={'labelMedium'}>
                {labelInput[c.contributionType]}:{' '}
              </Text>
              <Text variant={'bodyMedium'}>"{c.input}"</Text>
            </View>
          )}
          {showMotivation && c.motivation && (
            <View style={styles.info}>
              <Text variant={'labelMedium'}>
                {labelMotivation[c.contributionType]}:{' '}
              </Text>
              <Text variant={'bodyMedium'}>"{c.motivation}"</Text>
            </View>
          )}
        </>
      )}
      <View style={styles.buttons}>
        <IconButton
          onPress={onPrevious}
          style={styles.button}
          icon={IconPrevious}
        />
        <IconButton onPress={onClose} style={styles.button} icon={IconClose} />
        <IconButton onPress={onNext} style={styles.button} icon={IconNext} />
      </View>
    </Surface>
  )
}
