import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import {
  Surface,
  TouchableRipple,
  Text,
  ActivityIndicator,
} from 'react-native-paper'
import {
  PNG3dChallenge,
  PNG3dEdit,
  PNG3dInquiry,
} from '../../../utils/meldd-icons'
import { ContributionType } from '../../../generated/graphql'
import { Icon } from '../../../components/Icon'
import { useTheme } from '../../../hooks/use-theme'
import { useAuthContext } from '../../../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'

interface ButtonsBarProps {
  onClick: (type: ContributionType) => void
}

const icons = [
  { icon: PNG3dInquiry, type: ContributionType.Inquiry, label: 'Inquiry' },
  {
    icon: PNG3dChallenge,
    type: ContributionType.Challenge,
    label: 'Challenge',
  },
  { icon: PNG3dEdit, type: ContributionType.Edit, label: 'Edit' },
]
export function ButtonsBar(props: ButtonsBarProps) {
  const { t } = useTranslation('contributions')
  const [loading, setLoading] = React.useState('') // Instead of boolean we keep the string of the clicked type
  const theme = useTheme()
  const navigator = useNavigation<AppNavigation>()
  const { authAction } = useAuthContext()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: 74,
          backgroundColor: theme.colors.surfaceVariant,
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: theme.spacing(2),
        },
        button: {
          display: 'flex',
          width: 100,
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: theme.spacing(2),
        },
        touchable: {
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.spacing(2),
          borderRadius: theme.spacing(2),
          overflow: 'hidden',
          flexDirection: 'column',
          rowGap: theme.spacing(1),
        },
      }),
    [theme]
  )
  const onClick = React.useCallback(
    async (type: ContributionType) => {
      setLoading(type)
      await props.onClick(type)
      setLoading('')
    },
    [props.onClick, setLoading]
  )

  return (
    <View style={styles.container}>
      {icons.map((icon, i) => (
        <Surface style={styles.button} key={i} elevation={1}>
          <TouchableRipple
            onPress={() => authAction(navigator, () => onClick(icon.type))}
            style={styles.touchable}
          >
            {loading === icon.type ? (
              <ActivityIndicator size="small" color={theme.colors.primary} />
            ) : (
              <>
                <Icon source={icon.icon} size={16} />
                <Text variant={'labelMedium'}>{t(icon.label)}</Text>
              </>
            )}
          </TouchableRipple>
        </Surface>
      ))}
    </View>
  )
}
