import {
  IconCompleted,
  IconPending,
  IconAssignTask,
  IconArchived,
  IconOpenContribution,
} from '../../../utils/meldd-icons'
import { MelddTasks } from '../task.types'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../components/Icon'

export function TaskStatus({
  task,
  size = 24,
}: {
  task: MelddTasks
  size?: number
}) {
  const { t } = useTranslation('tasks')
  if (task.isCompleted) {
    return (
      <TouchableIconWithToolTip
        icon={<Icon source={IconCompleted} size={size} />}
        tooltip={t('completed')}
      />
    )
  }
  // If a single contributor is assigned, we wait him to complete the task
  if (task.assignedId) {
    return (
      <TouchableIconWithToolTip
        icon={<Icon source={IconPending} size={size} />}
        tooltip={t('pending')}
      />
    )
  }
  if (task.isArchived) {
    return (
      <TouchableIconWithToolTip
        icon={<Icon source={IconArchived} size={size} />}
        tooltip={t('archived')}
      />
    )
  }
  // If not, it means any one can contribute and complete this task
  return (
    <TouchableIconWithToolTip
      icon={<Icon source={IconOpenContribution} size={size} />}
      tooltip={t('open')}
    />
  )
}
