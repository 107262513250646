import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { StyleSheet, View } from 'react-native'
import { Icon, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import {
  getTaskUrl,
  MelddTasks,
  MelddTaskTargetType,
  TargetTypeTasks,
} from '../../screens/tasks/task.types'
import { useModalDialog } from '../ModalDialogProvider'
import { MelddTextInput } from '../form/MelddInput'
import { useForm } from 'react-hook-form'
import { MelddPicker } from '../form/MelddPicker'
import {
  GetTasksDocument,
  useCreateTaskMutation,
} from '../../generated/graphql'
import { useApolloClient } from '@apollo/client'
import Toast from 'react-native-root-toast'
import { TouchableIconWithToolTip } from '../../utils/meldd-tooltip'
import * as Clipboard from 'expo-clipboard'
import { useNavigation } from '@react-navigation/native'

const CreateTaskDialog = forwardRef(
  (
    {
      targetId,
      targetType,
    }: {
      targetId: string
      targetType: MelddTaskTargetType
    },
    ref
  ) => {
    const { t } = useTranslation(['tasks', 'common'])
    const styles = useStyle()
    const form = useForm({
      defaultValues: {
        title: TargetTypeTasks[targetType]?.[0]
          ? t(`${TargetTypeTasks[targetType]?.[0]}-default-message`)
          : '',
        type: TargetTypeTasks[targetType]?.[0] || '',
        targetId: targetId,
        targetType: targetType,
        assignee: '',
      },
      mode: 'onTouched',
    })

    const submitForm = useCallback(
      (
        doCreateTask: ReturnType<typeof useCreateTaskMutation>[0]
      ): Promise<MelddTasks | null> => {
        return new Promise((resolve) => {
          const submitHandler = form.handleSubmit(async (data) => {
            // Not sure why `formState.isValid` is never valid in the first click
            if (Object.keys(form.formState.errors).length > 0) {
              form.trigger()
              resolve(null)
              return
            }
            try {
              const result = await doCreateTask({
                variables: {
                  input: {
                    title: data.title,
                    type: data.type,
                    targetId,
                    targetType,
                  },
                },
              })

              Toast.show(t('task-created'))
              resolve(result.data?.createTask)
            } catch (e) {
              Toast.show((e as Error).message)
              resolve(null)
            }
          })

          submitHandler()
        })
      },
      [form, targetId, targetType, t]
    )

    useImperativeHandle(
      ref,
      () => {
        return {
          // I need to pass the mutation for the parent as it is not in the scope of this component
          submit: (doCreateTask: ReturnType<typeof useCreateTaskMutation>[0]) =>
            submitForm(doCreateTask),
        }
      },
      [submitForm]
    )

    const type = form.watch('type')
    useEffect(() => {
      if (form.formState.dirtyFields.title) {
        return
      }
      form.setValue('title', t(`${type}-default-message`))
    }, [type])

    return (
      <View style={styles.container}>
        <MelddPicker
          form={form}
          label={t('task-type')}
          name={'type'}
          rules={{ required: true }}
          options={(TargetTypeTasks[targetType] || []).map((taskType) => ({
            label: t(taskType),
            value: taskType,
          }))}
        />

        <MelddTextInput
          form={form}
          name="title"
          label={t('task-title')}
          rules={{ required: true }}
        />
      </View>
    )
  }
)

const TaskCreatedModal = ({ taskCreated }: { taskCreated: MelddTasks }) => {
  const url = getTaskUrl(taskCreated)
  const { t } = useTranslation('tasks')
  const styles = useStyle()
  const form = useForm({
    defaultValues: {
      url,
    },
  })
  if (!taskCreated) {
    return null
  }
  const copy = () => {
    Clipboard.setString(url || '')
    Toast.show(t('copied'))
  }
  return (
    <View style={styles.createdContainer}>
      <View style={{ flex: 1 }}>
        <MelddTextInput
          form={form}
          name="url"
          label={t('task-url')}
          readOnly={true}
        />
      </View>
      <TouchableIconWithToolTip
        icon={<Icon source="content-copy" size={30} />}
        tooltip={t('copy')}
        onPress={copy}
      />
    </View>
  )
}

export type CreateTaskModalProps = {
  open: (
    targetId: string,
    targetType: MelddTaskTargetType,
    preview: string
  ) => void
  close: () => void
}
export const CreateTaskModal = forwardRef<CreateTaskModalProps>((_, ref) => {
  const { setDialog } = useModalDialog()
  const { t } = useTranslation(['tasks', 'common'])
  const navigation = useNavigation()
  const formRef = useRef<{
    submit: (
      doCreateTask: ReturnType<typeof useCreateTaskMutation>[0]
    ) => Promise<MelddTasks | null>
  }>(null)
  const [doCreateTask, { client }] = useCreateTaskMutation()
  useImperativeHandle(ref, () => {
    return {
      open: (
        targetId: string,
        targetType: MelddTaskTargetType,
        preview: string
      ) => {
        setDialog({
          title: t('create-task-for') + t(targetType),
          subtitle: preview,
          body: (
            <CreateTaskDialog
              ref={formRef}
              targetId={targetId}
              targetType={targetType}
            />
          ),
          closeAction: () => {
            setDialog(null)
          },

          primaryLabel: t('create'),
          primaryAction: async () => {
            const result =
              (await formRef.current?.submit(doCreateTask)) || false

            if (!result) {
              return
            }
            await client.refetchQueries({
              include: [GetTasksDocument],
            })
            setDialog({
              title: t('task-created'),
              body: <TaskCreatedModal taskCreated={result} />,
              primaryLabel: t('common:open'),
              secondaryLabel: t('common:close'),
              primaryAction: () => {
                navigation.navigate('Task', {
                  taskId: result.id,
                })
                setDialog(null)
              },
              secondaryAction: () => {
                setDialog(null)
              },
            })
          },
        })
      },
    }
  })

  return null
})

const useStyle = () => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: '100%',
          gap: 8,
        },
        createdContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
        },
      }),
    [theme]
  )
}
