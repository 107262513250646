import React, { forwardRef } from 'react'
import {
  Controller,
  FieldValues,
  UseControllerProps,
  UseFormReturn,
} from 'react-hook-form'
import { HelperText, TextInput } from 'react-native-paper'
import { TextInput as TextInputNative, View } from 'react-native'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'

type MelddTextInput<T extends FieldValues> = React.ComponentProps<
  typeof TextInput
> & {
  form: UseFormReturn<T, unknown, T | undefined>
  name: string
  label: string
  rules?: UseControllerProps['rules']
  helperText?: string
}
/**
 * Simplify Version of  ControlledTextInput with less
 * logic restrictions and more style reutilization
 */

export const MelddTextInput = forwardRef<TextInputNative, MelddTextInput<any>>(
  ({ form, name, rules, helperText, ...textInputProps }, ref) => {
    const error = form.formState.errors[name]
    return (
      <Controller
        control={form.control}
        name={name.toString()}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <View
            style={{
              gap: 8,
              width: '100%',
            }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextInput
                ref={ref}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                autoCapitalize="none"
                error={!!error}
                style={{ width: '100%' }}
                {...textInputProps}
              />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <HelperText type={'info'} visible={!!helperText}>
                {helperText}
              </HelperText>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {error && (
                <HelperText type={'error'}>
                  {error.message?.toString() || ''}
                </HelperText>
              )}
            </View>
          </View>
        )}
      />
    )
  }
)
