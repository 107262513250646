import { useTranslation } from 'react-i18next'
import MelddChipPicker from '../../../components/form/MelddChipPicker'
import Row from '../../../components/containers/Row'
import { useState } from 'react'
import { MelddTaskTargetType } from '../task.types'
import { Text } from 'react-native-paper'
import { deleteUndefined } from '../../../utils/pagination'
import { useAuthContext } from '../../../auth/auth-context'

export type TaskStatusFilter =
  | 'all'
  | 'open'
  | 'archived'
  | 'completed'
  | 'assigned'

export type TaskTypeFilter = `${MelddTaskTargetType}` | 'all'

export type TaskOwnerFilter = 'all' | 'owner' | 'assigned'

const StatusFilterMap: Record<TaskStatusFilter, Record<string, unknown>> = {
  all: { archived: false },
  completed: { completed: true },
  open: { assignedId: null, completed: false, archived: false },
  assigned: { assigned: true, completed: false, archived: false },
  archived: { archived: true },
}

const TaskTypeFilterMap: Record<TaskTypeFilter, Record<string, unknown>> = {
  all: {},
  contribution: { targetType: MelddTaskTargetType.CONTRIBUTION_TASK },
  node: { targetType: MelddTaskTargetType.NODE_TASK },
  article: { targetType: MelddTaskTargetType.ARTICLE_TASK },
  cluster: { targetType: MelddTaskTargetType.CLUSTER_TASK },
}

const TaskOwnerFilterMap = (
  userId: string
): Record<TaskOwnerFilter, Record<string, unknown>> => ({
  all: {},
  owner: { ownerId: userId },
  assigned: { assignedId: userId },
})

type TaskFilterDefaultValues = {
  userId?: string
  status?: TaskStatusFilter
  type?: TaskTypeFilter
  owner?: TaskOwnerFilter
}
export type TaskFilterProps = {
  defaultValues: TaskFilterDefaultValues
  onFilter: (value: Record<string, unknown>) => void
}

export const getDefaultFilters = (defaultValues: TaskFilterDefaultValues) => {
  const filters = {
    status: defaultValues?.status
      ? StatusFilterMap[defaultValues.status] || StatusFilterMap['all']
      : undefined,
    type: defaultValues?.type
      ? TaskTypeFilterMap[defaultValues.type] || TaskTypeFilterMap['all']
      : undefined,
    owner:
      defaultValues?.userId && defaultValues?.owner
        ? TaskOwnerFilterMap(defaultValues.userId)[defaultValues.owner] ||
          TaskOwnerFilterMap(defaultValues.userId)['all']
        : undefined,
  }
  deleteUndefined(filters)
  return Object.values(filters).reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {}
  ) as Record<string, unknown>
}

const StatusFilterComponent = ({
  enabled,
  onFilter,
  defaultValue,
}: {
  enabled: boolean
  defaultValue: TaskStatusFilter
  onFilter: (value: Record<string, unknown>) => void
}) => {
  const { t } = useTranslation('tasks')
  const [selected, setSelected] = useState<TaskStatusFilter>(defaultValue)
  const onSelect = (value: TaskStatusFilter) => {
    setSelected(value)
    onFilter(StatusFilterMap[value])
  }

  if (!enabled) return null

  return (
    <Row columnGap={2}>
      <Text>{t('status')}</Text>
      <MelddChipPicker<TaskStatusFilter>
        selected={selected}
        options={[
          { label: t('status.all'), value: 'all' },
          { label: t('status.open'), value: 'open' },
          { label: t('status.completed'), value: 'completed' },
          { label: t('status.assigned'), value: 'assigned' },
          { label: t('status.archived'), value: 'archived' },
        ]}
        onSelect={onSelect}
      />
    </Row>
  )
}

export const TaskTypeFilterComponent = ({
  enabled,
  onFilter,
  defaultValue,
}: {
  enabled: boolean
  defaultValue: TaskTypeFilter
  onFilter: (value: Record<string, unknown>) => void
}) => {
  const { t } = useTranslation('tasks')
  const [selected, setSelected] = useState<TaskTypeFilter>(defaultValue)
  const onSelect = (value: TaskTypeFilter) => {
    setSelected(value)
    onFilter(TaskTypeFilterMap[value])
  }

  if (!enabled) return null

  return (
    <Row columnGap={2}>
      <Text>{t('task-type')}</Text>
      <MelddChipPicker<TaskTypeFilter>
        selected={selected}
        options={[
          { label: t('status.all'), value: 'all' },
          {
            label: t('contribution'),
            value: MelddTaskTargetType.CONTRIBUTION_TASK,
          },
          { label: t('node'), value: MelddTaskTargetType.NODE_TASK },
          { label: t('article'), value: MelddTaskTargetType.ARTICLE_TASK },
          { label: t('cluster'), value: MelddTaskTargetType.CLUSTER_TASK },
        ]}
        onSelect={onSelect}
      />
    </Row>
  )
}

const OwnershipFilterComponent = ({
  enabled,
  onFilter,
  defaultValue,
}: {
  enabled: boolean
  defaultValue: TaskOwnerFilter
  onFilter: (value: Record<string, unknown>) => void
}) => {
  const { userId } = useAuthContext()
  const { t } = useTranslation('tasks')
  const [selected, setSelected] = useState<TaskOwnerFilter>(defaultValue)
  const onSelect = (value: TaskOwnerFilter) => {
    setSelected(value)
    onFilter(TaskOwnerFilterMap(userId)[value])
  }

  if (!userId) return null
  if (!enabled) return null

  return (
    <Row columnGap={2}>
      <Text>{t('by-filter')}</Text>
      <MelddChipPicker<TaskOwnerFilter>
        selected={selected}
        options={[
          { label: t('by.all'), value: 'all' },
          {
            label: t('by.my-tasks'),
            value: 'owner',
          },
          {
            label: t('by.my-assigned'),
            value: 'assigned',
          },
        ]}
        onSelect={onSelect}
      />
    </Row>
  )
}

export const TaskFilter = ({ defaultValues, onFilter }: TaskFilterProps) => {
  const [currentFilters, setCurrentFilters] = useState({ status: {}, type: {} })

  const _onFilter = (key: string, filter: Record<string, unknown>) => {
    const updatedFilters = { ...currentFilters, [key]: filter }
    setCurrentFilters(updatedFilters)
    onFilter(
      Object.values(updatedFilters).reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      )
    )
  }

  return (
    <Row columnGap={10} alignItems={'center'}>
      <StatusFilterComponent
        enabled={typeof defaultValues?.status === 'string'}
        onFilter={(filter) => _onFilter('status', filter)}
        defaultValue={defaultValues?.status || 'all'}
      />
      <TaskTypeFilterComponent
        enabled={typeof defaultValues?.type === 'string'}
        onFilter={(filter) => _onFilter('type', filter)}
        defaultValue={defaultValues?.type || 'all'}
      />

      <OwnershipFilterComponent
        enabled={typeof defaultValues?.owner === 'string'}
        onFilter={(filter) => _onFilter('owner', filter)}
        defaultValue={defaultValues?.owner || 'all'}
      />
    </Row>
  )
}
