import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Portal, Text } from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { StyleSheet } from 'react-native'

type ConfirmHandler = { handler: (confirm: boolean) => void }
type ConfirmCensureProviderContextShape = (
  setHandler: ConfirmHandler | null
) => void

export const ConfirmCensureProviderContext =
  createContext<ConfirmCensureProviderContextShape>(() => {})

type ConfirmCensureProviderProps = {
  children: ReactNode
}
export const ConfirmCensureProvider = ({
  children,
}: ConfirmCensureProviderProps) => {
  const [handler, setHandler] = React.useState<ConfirmHandler | null>(null)
  const { t } = useTranslation('contributions')

  const theme = useTheme()
  const value = React.useCallback((newHandler: ConfirmHandler | null) => {
    setHandler(newHandler)
  }, [])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.danger.surface,
        },
        title: {},
        content: {},
      }),
    [theme]
  )
  const handlePress = (confirm: boolean) => {
    handler?.handler(confirm)
    setHandler(null)
  }
  return (
    <ConfirmCensureProviderContext.Provider value={value}>
      {children}
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={!!handler}
          onDismiss={() => handlePress(false)}
        >
          <Dialog.Title style={styles.title}>{t('Are you sure?')}</Dialog.Title>
          <Dialog.Content style={styles.content}>
            <Text>{t('Confirm Censure')}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              textColor={theme.colors.danger.main}
              onPress={() => handlePress(false)}
            >
              {t('Cancel')}
            </Button>
            <Button
              buttonColor={theme.colors.danger.main}
              textColor="white"
              onPress={() => handlePress(true)}
            >
              {t('Censure')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </ConfirmCensureProviderContext.Provider>
  )
}

export const useConfirmCensureDialog = () => {
  return useContext(ConfirmCensureProviderContext)
}
