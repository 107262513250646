import { DataTable, TouchableRipple } from 'react-native-paper'

import { ApolloError } from '@apollo/client'
import { NodeStatsQuery } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { MultiLineCell } from '../../../components/TitledTable/MultiLineCell'
import { AppNavigation } from '../../../navigation/types'
import SingleCell from '../../../components/TitledTable/SingleCell'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'

export const NodeStatsPerspectives = ({
  loading,
  error,
  perspectives,
}: {
  loading: boolean
  error: ApolloError | undefined
  perspectives: NodeStatsQuery['nodeStats']['perspectives']
}) => {
  const { t } = useTranslation('nodeStats')
  const navigation = useNavigation<AppNavigation>()

  if (loading) return <SingleCell message={''} />

  if (error)
    return <SingleCell key="0" message={t('Failed to load perspectives')} />

  if (!perspectives.length)
    return <SingleCell key="0" message={t('No Perspectives')} />

  return (perspectives || []).map((perspective) => {
    const onPress = () =>
      navigation.push('Perspective', {
        articleId: perspective.id,
      })
    return (
      <Animated.View key={perspective.id} entering={FadeIn} exiting={FadeOut}>
        <TouchableRipple onPress={onPress}>
          <DataTable.Row>
            <MultiLineCell
              title={perspective.title}
              description={perspective.cluster.topic}
            />
            <DataTable.Cell numeric>{perspective.voteCount}</DataTable.Cell>
          </DataTable.Row>
        </TouchableRipple>
      </Animated.View>
    )
  })
}
