import { Icon, Text } from 'react-native-paper'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'
import {
  getTargetDetail,
  MelddTasks,
  MelddTaskTargetType,
  navigateToTaskTarget,
  useGetTarget,
} from '../task.types'
import {
  IconCluster,
  IconContributions,
  IconNode,
  IconPerspective,
} from '../../../utils/meldd-icons'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { Row } from '../../../components/containers/Row'
import { useMemo } from 'react'
import { AvatarLineSkeleton } from '../../../components/skeletons/avatar-line.skeleton'

const TaskIcon = {
  [MelddTaskTargetType.ARTICLE_TASK]: IconPerspective,
  [MelddTaskTargetType.CONTRIBUTION_TASK]: IconContributions,
  [MelddTaskTargetType.NODE_TASK]: IconNode,
  [MelddTaskTargetType.CLUSTER_TASK]: IconCluster,
}

export const TaskTypeButton = ({
  task,
}: {
  task: { targetType: MelddTaskTargetType; targetId: string }
}) => {
  const { t } = useTranslation('tasks')
  const navigation = useNavigation<AppNavigation>()
  const { data: target, loading } = useGetTarget({
    targetType: task.targetType,
    targetId: task.targetId,
  })

  const targetInfo = useMemo(
    () =>
      target ? getTargetDetail({ target, targetType: task.targetType }) : null,
    [target, task.targetType]
  )

  if (loading) {
    return (
      <Row>
        <AvatarLineSkeleton width={100} />
      </Row>
    )
  }
  if (!targetInfo) {
    return null
  }
  return (
    <TouchableIconWithToolTip
      mode="text"
      icon={
        <Icon
          source={TaskIcon[task.targetType as keyof typeof TaskIcon]}
          size={14}
        />
      }
      label={targetInfo.title || ''}
      tooltip={t(task.targetType)}
      onPress={() => {
        navigateToTaskTarget({ task: task, navigation })
      }}
    />
  )
}

export const TaskTypeLabel = ({
  task,
}: {
  task: { targetType: MelddTaskTargetType; targetId: string }
}) => {
  const { t } = useTranslation('tasks')
  return (
    <Row alignItems="center" columnGap={2}>
      <TouchableIconWithToolTip
        mode="text"
        icon={
          <Icon
            source={TaskIcon[task.targetType as keyof typeof TaskIcon]}
            size={14}
          />
        }
        tooltip={t(task.targetType)}
      />
      <Text>{t(task.targetType)}</Text>
    </Row>
  )
}
