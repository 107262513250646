import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Dialog, Portal, Text } from 'react-native-paper'
import { useTheme } from '../hooks/use-theme'

type ModalDialogParams = {
  title: string
  body: ReactNode
  primaryAction: () => void
  primaryLabel: string
  secondaryAction?: () => void
  secondaryLabel?: string
  closeAction?: () => void
  bkgColor?: string
  accentColor?: string
  onAccentColor?: string
  subtitle?: string
}
type ModalDialogProviderContextShape = {
  setDialog: (options: ModalDialogParams | null) => void
}

const INITIAL_VALUE: ModalDialogProviderContextShape = {
  setDialog: () => {},
}
export const ModalDialogProviderContext =
  createContext<ModalDialogProviderContextShape>(INITIAL_VALUE)

type ModalDialogProviderProps = {
  children: ReactNode
}

export const ModalDialogProvider = ({ children }: ModalDialogProviderProps) => {
  const [dialogParams, setDialogParams] =
    React.useState<ModalDialogParams | null>(null)
  const theme = useTheme()
  const value = React.useMemo(() => {
    return {
      setDialog: setDialogParams,
    }
  }, [setDialogParams])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.secondaryContainer,
        },
        subtitle: {
          color: theme.colors.onSurfaceVariant,
          fontStyle: 'italic',
          fontSize: 14,
          marginTop: 4,
        },
        title: {
          display: 'flex',
          flexDirection: 'column',
        },
        content: {},
        button: {
          paddingHorizontal: theme.spacing(4),
        },
        actions: {
          paddingHorizontal: theme.spacing(4),
          zIndex: -1,
        },
      }),
    [theme]
  )
  const [primaryActionLoading, setPrimaryActionLoading] = useState(false)

  const pressPrimaryAction = async () => {
    setPrimaryActionLoading(true)
    await dialogParams?.primaryAction()
    setPrimaryActionLoading(false)
  }
  return (
    <ModalDialogProviderContext.Provider value={value}>
      <>
        {children}
        <Portal>
          <Dialog
            style={[
              styles.dialog,
              dialogParams?.bkgColor
                ? { backgroundColor: dialogParams?.bkgColor }
                : {},
            ]}
            visible={!!dialogParams}
            onDismiss={() => {
              if (dialogParams?.closeAction) {
                dialogParams.closeAction()
                return
              }
              setDialogParams(null)
            }}>
            <Dialog.Title style={styles.title}>
              {dialogParams?.title}

              {dialogParams?.subtitle && (
                <View style={{ marginTop: theme.spacing(1) }}>
                  <Text style={styles.subtitle}>{dialogParams?.subtitle}</Text>
                </View>
              )}
            </Dialog.Title>
            <Dialog.Content style={styles.content}>
              {dialogParams?.body}
            </Dialog.Content>
            <Dialog.Actions style={styles.actions}>
              {dialogParams?.secondaryAction && (
                <Button
                  style={styles.button}
                  textColor={dialogParams?.accentColor || theme.colors.primary}
                  onPress={dialogParams?.secondaryAction}>
                  {dialogParams?.secondaryLabel}
                </Button>
              )}
              <Button
                buttonColor={dialogParams?.accentColor || theme.colors.primary}
                textColor={dialogParams?.onAccentColor || 'white'}
                style={styles.button}
                loading={primaryActionLoading}
                onPress={pressPrimaryAction}>
                {dialogParams?.primaryLabel}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </>
    </ModalDialogProviderContext.Provider>
  )
}

export const useModalDialog = () => {
  return useContext(ModalDialogProviderContext)
}
