import * as React from 'react'
import { DataTable } from 'react-native-paper'
import { MultiLineCell } from './MultiLineCell'
import { View, Text, StyleSheet } from 'react-native'

interface SingleCellProps {
  message: string
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default function SingleCell(props: SingleCellProps) {
  return (
    <DataTable.Row>
      <View style={styles.container}>
        <Text>{props.message}</Text>
      </View>
    </DataTable.Row>
  )
}
