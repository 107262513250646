import * as React from 'react'
import { useCallback, useEffect } from 'react'
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { MdNode } from './types'
import { NodeListItem } from './components/NodeListItem'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { useTranslation } from 'react-i18next'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Searchbar } from 'react-native-paper'
import Row from '../../components/containers/Row'
import { useAvailableNodesQuery } from '../../generated/graphql'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  welcome: {
    padding: 20,
    fontSize: 20,
    textAlign: 'center',
  },
})

export function ListNodesScreen({
  navigation,
}: StackScreenProps<AppNavigatorParams, 'ListNodes'>) {
  const isFocused = useIsFocused()
  const { t } = useTranslation('nodes')
  const [searchQuery, setSearchQuery] = React.useState('')
  const globalStyles = useGlobalStyles()
  const { data, refetch, loading } = useAvailableNodesQuery({
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])
  const navigate = useCallback(
    (node: MdNode) => {
      navigation.navigate('ClusterList', { nodeId: node.id })
    },
    [navigation]
  )

  const renderNodeListItem = useCallback((info: ListRenderItemInfo<MdNode>) => {
    return <NodeListItem node={info.item} onNavigate={navigate} />
  }, [])
  if (loading) {
    return (
      <View style={styles.container}>
        <Text>{t('Loading')}</Text>
      </View>
    )
  }

  const filteredNodes =
    data?.availableNodes.filter((node) =>
      node.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []

  return (
    <View style={[globalStyles.pageContainer]}>
      <Row
        justifyContent="space-between"
        wrap={true}
        style={styles.welcome}
        columnGap={4}
      >
        <Text>{t('welcome')}</Text>
        <View style={{ flex: 1, minWidth: 200 }}>
          <Searchbar
            placeholder={t('search-nodes')}
            onChangeText={setSearchQuery}
            value={searchQuery}
          />
        </View>
      </Row>
      {filteredNodes.length ? (
        <ScreenWrapper
          contentContainerStyle={{ height: '100%' }}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={refetch} />
          }
        >
          <FlatList data={filteredNodes} renderItem={renderNodeListItem} />
        </ScreenWrapper>
      ) : (
        <View style={styles.container}>
          <Text>{t('No Nodes')}</Text>
        </View>
      )}
    </View>
  )
}
export default ListNodesScreen
