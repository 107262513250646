import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { AuthContext } from '../auth/auth-context'
import MelddButton from './MelddButton'
import { useLogoutMutation } from '../generated/graphql'
import { AppNavigation } from '../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { usePushToken } from '../contexts/PushTokenProvider'

const Logout = () => {
  const [LogoutUser, { client }] = useLogoutMutation()
  const pushToken = usePushToken()
  const navigation = useNavigation<AppNavigation>()
  const authCtx = useContext(AuthContext)
  async function logout() {
    authCtx.clearCurrentUser()
    try {
      await LogoutUser(
        pushToken ? { variables: { input: { token: pushToken } } } : undefined
      )
    } catch (error) {
      console.log(error)
    }
  }
  const styles = StyleSheet.create({})
  return (
    <MelddButton
      onPress={() => logout()}
      onLongPress={() => navigation.navigate('FeatureToggles')}
      color="#F1F1F1"
      title=""
      textColor="white"
      fontSize={10}
    >
      <AntDesign name="logout" size={20} style={{ marginTop: 9 }} />
    </MelddButton>
  )
}

export default Logout
