import * as React from 'react'
import { useMemo } from 'react'
import { ContributionType } from '../../../generated/graphql'
import { useTheme } from '../../../hooks/use-theme'
import { SegmentedButtons } from 'react-native-paper'
import {
  Icon3dChallenge,
  Icon3dEdit,
  Icon3dInquiry,
} from '../../../utils/meldd-icons'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { Icon } from '../../../components/Icon'

interface ContributionCountProps {
  perspectiveId: string
  contributions: { contributionType: ContributionType }[]
}

export function ContributionCount(props: ContributionCountProps) {
  const theme = useTheme()
  const navigation = useNavigation<AppNavigation>()

  const inquiryCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Inquiry
      ).length,
    [props.contributions]
  )
  const challengeCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Challenge
      ).length,
    [props.contributions]
  )
  const editCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Edit
      ).length,
    [props.contributions]
  )
  const handleClick = (value: string) => {
    navigation.navigate('PerspectiveContributions', {
      contributionTypeFilter: value as ContributionType,
      articleId: props.perspectiveId,
    })
  }
  return (
    <SegmentedButtons
      onValueChange={handleClick}
      value={''}
      density={'small'}
      theme={{
        fonts: {
          labelLarge: {
            fontSize: 13,
            textDecorationLine: 'underline',
          },
        },
      }}
      buttons={[
        {
          value: ContributionType.Inquiry,
          label: '' + inquiryCount,
          icon: () => (
            <Icon3dInquiry
              size={16}
              mode="text"
              onPress={() => handleClick(ContributionType.Inquiry)}
            />
          ),
          uncheckedColor: theme.colors.primary,
        },
        {
          value: ContributionType.Challenge,
          label: '' + challengeCount,
          icon: () => (
            <Icon3dChallenge
              size={16}
              mode="text"
              onPress={() => handleClick(ContributionType.Challenge)}
            />
          ),
          uncheckedColor: theme.colors.primary,
        },
        {
          value: ContributionType.Edit,
          label: '' + editCount,
          icon: () => (
            <Icon3dEdit
              size={16}
              mode="text"
              onPress={() => handleClick(ContributionType.Edit)}
            />
          ),
          uncheckedColor: theme.colors.primary,
        },
      ]}
    />
  )
}
