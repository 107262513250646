import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import { StyleSheet, View, Animated } from 'react-native'
import { Text } from 'react-native-paper'
import { Circle, Svg } from 'react-native-svg'
import { formatTime } from '../format'
import { useTheme } from '../../../hooks/use-theme'
import { IconAlert } from '../../../utils/meldd-icons'
import { Icon } from '../../../components/Icon'

interface RemainingTimeIndicatorProps {
  maxDuration: number
  validUntil: number
}

const size = 48
const styles = StyleSheet.create({
  container: {
    width: size,
    height: size,
  },
  text: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
const circumference = (size - 4) * Math.PI

export function RemainingTimeIndicator(props: RemainingTimeIndicatorProps) {
  const { maxDuration, validUntil } = props
  const [initialDuration] = useState(
    Math.min(maxDuration, validUntil - Date.now())
  )
  const theme = useTheme()
  const [remainingTime, setRemainingTime] = useState(
    Math.max(maxDuration, validUntil - Date.now())
  )
  const [progress, setProgress] = useState(
    1 - Math.min(maxDuration, validUntil - Date.now()) / maxDuration
  )
  const remainingTimeStr = useMemo(
    () => formatTime(Math.max(0, remainingTime)),
    [remainingTime]
  )

  React.useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = validUntil - Date.now()
      setRemainingTime(remainingTime)
      setProgress(1 - Math.min(maxDuration, remainingTime) / maxDuration)
    }, 1000)
    const remaining = validUntil - Date.now()
    setRemainingTime(remaining)
    return () => clearInterval(interval)
  }, [initialDuration, maxDuration, validUntil])

  return (
    <View style={styles.container}>
      <Svg width={size} height={size}>
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 2}
          stroke={theme.colors.surfaceVariant}
          fill={'none'}
          strokeWidth={4}
        />
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 2}
          strokeDasharray={[circumference * progress, circumference]}
          rotation={-90}
          originX={size / 2}
          originY={size / 2}
          stroke={theme.colors.primary}
          fill={'none'}
          strokeWidth={4}
        />
      </Svg>
      <View style={styles.text}>
        {remainingTime < 0 ? (
          <Icon
            source={IconAlert}
            size={22}
            color={theme.palettes.primary[70]!}
          />
        ) : (
          <Text variant={'labelSmall'} style={{}}>
            {remainingTimeStr}
          </Text>
        )}
      </View>
    </View>
  )
}
