import * as React from 'react'
import appJson from '../../package.json'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import colors from '../constants/colors'

interface CopyrightProps {}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    width: '100%',
  },
  copyright: {
    textAlign: 'center',
    color: colors.grey600,
    fontSize: 11,
  },
})

export function Copyright(props: CopyrightProps) {
  const { t } = useTranslation('footer')
  return (
    <View style={styles.container}>
      <Text style={styles.copyright}>
        {t('copyright')} : {appJson.version}{' '}
      </Text>
    </View>
  )
}
