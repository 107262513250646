import { View } from 'react-native'
import WechatLogin from '../Login/WechatLogin'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigation, AppNavigatorParams } from '../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../../auth/auth-context'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useEffect } from 'react'

/**
 * When we link wechat from the profile screen, we send the redirection to this page.
 * As the profile screen is nested and can not be accessed directly (and also requires other URI parameters)
 */

function WechatRedirect(
    props: StackScreenProps<AppNavigatorParams, 'WechatRedirect'>
) {
    const { userId } = useAuthContext()
    const navigator = useNavigation<AppNavigation>()
    const goToProfile = () => {
        navigator.navigate('Profile', { userId })
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code') || ''
        const state = urlParams.get('state') || ''
        if (!code || !state) {
            navigator.navigate('ClusterList')
        }
    }, [])
    return (
        <ScreenWrapper>
            <WechatLogin callbackOnly={true} onSuccess={() => goToProfile()} onLinkingError={() => goToProfile()} />
        </ScreenWrapper>
    )
}

export default WechatRedirect
