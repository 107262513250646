import { StyleSheet, Text, View } from 'react-native'
import ModeMemberList from './components/ModeMemberList'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'
import { useNode } from '../../contexts/CurrentNodeProvider'
import { useNodeByIdQuery } from '../../generated/graphql'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { useEffect } from 'react'
import Toast from 'react-native-root-toast'

function NodeSettingsScreen({
  route,
  navigation,
}: StackScreenProps<AppNavigatorParams, 'NodeSettings'>) {
  const { nodeId } = route.params
  const { t } = useTranslation('nodes')
  const { data, loading } = useNodeByIdQuery({
    variables: { id: nodeId },
    skip: !nodeId,
  })
  const node = data?.nodeById
  const styles = useStyle()

  useEffect(() => {
    if (loading) return

    if (!node || !nodeId) {
      navigation.goBack()
      Toast.show(t('no-node-selected'), {
        duration: Toast.durations.LONG,
        position: Toast.positions.CENTER,
      })
    }

    if (!node?.isAdmin) {
      navigation.goBack()
      Toast.show(t('node-settings-not-authorized'), {
        duration: Toast.durations.LONG,
        position: Toast.positions.CENTER,
      })
    }
  }, [node, loading, nodeId])

  if (loading)
    return (
      <View style={styles.fullPage}>
        <Text>{t('Loading')}</Text>
      </View>
    )

  if (!node) return null

  return (
    <View style={styles.pageContainer}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
          marginBottom: 15,
        }}
      >
        <Text style={{ marginRight: 5 }}>{t('Node Settings for')}:</Text>
        <Text style={{ fontWeight: 'bold' }}>{node.name}</Text>
      </View>
      {node.isPrivate && <ModeMemberList nodeId={node.id} />}
    </View>
  )
}

export default NodeSettingsScreen

const useStyle = createStyle(({}) =>
  StyleSheet.create({
    fullPage: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)
