import { useTranslation } from 'react-i18next'
import { ContributionType } from '../generated/graphql'
import i18next from '../i18n'
import { useMemo } from 'react'

interface TypeStrings {
  typeStr: Record<ContributionType, string>
  labelInput: Record<ContributionType, string>
  placeholderInput: Record<ContributionType, string>
  labelMotivation: Record<ContributionType, string>
  placeholderMotivation: Record<ContributionType, string>
}

export const useTypeStrings = () => {
  const { t } = useTranslation('create_contribution')
  const typeStrings: TypeStrings = useMemo(
    () => ({
      typeStr: {
        [ContributionType.Challenge]: t('typeStrChallenge'),
        [ContributionType.Edit]: t('typeStrEdit'),
        [ContributionType.Inquiry]: t('typeStrInquiry'),
        [ContributionType.Reserved]: t('typeStrReserved'),
      },
      labelInput: {
        [ContributionType.Challenge]: t('labelInputChallenge'),
        [ContributionType.Edit]: t('labelInputEdit'),
        [ContributionType.Inquiry]: t('labelInputInquiry'),
        [ContributionType.Reserved]: '',
      },
      placeholderInput: {
        [ContributionType.Challenge]: t('placeholderInputChallenge'),
        [ContributionType.Edit]: t('placeholderInputEdit'),
        [ContributionType.Inquiry]: t('placeholderInputInquiry'),
        [ContributionType.Reserved]: '',
      },
      labelMotivation: {
        [ContributionType.Challenge]: t('labelMotivationChallenge'),
        [ContributionType.Edit]: t('labelMotivationEdit'),
        [ContributionType.Inquiry]: '',
        [ContributionType.Reserved]: '',
      },
      placeholderMotivation: {
        [ContributionType.Challenge]: t('placeholderMotivationChallenge'),
        [ContributionType.Edit]: t('placeholderMotivationEdit'),
        [ContributionType.Inquiry]: '',
        [ContributionType.Reserved]: '',
      },
    }),
    [t]
  )
  return typeStrings
}
