import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  Portal,
  Button,
  Text,
  List,
  IconButton,
} from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { FlatList, StyleSheet, View } from 'react-native'
import { Unpacked } from '../../../utils/types'
import { BumpsForContributionQuery } from '../../../generated/graphql'
import { Icon } from '../../../components/Icon'
import {
  Icon3dBumpDown,
  Icon3dBumpUp,
  IconClose,
} from '../../../utils/meldd-icons'
import { AppNavigation, AppNavigatorParams } from '../../../navigation/types'
import { NavigationProp, useNavigation } from '@react-navigation/native'

type BumpDetailsProviderContextShape = (
  toShow: ContributionBump[] | null
) => void

export const BumpDetailsProviderContext =
  createContext<BumpDetailsProviderContextShape>(() => { })
type ContributionBump = Unpacked<
  BumpsForContributionQuery['contributionBumpsByContribution']
>

type BumpDetailsProviderProps = {
  children: ReactNode
}
export const BumpDetailsProvider = ({ children }: BumpDetailsProviderProps) => {
  const [bumps, setBumps] = React.useState<ContributionBump[] | null>(null)
  const { t } = useTranslation('bumps')
  const navigation = useNavigation<AppNavigation>()

  const theme = useTheme()
  const value = React.useCallback((toShow: ContributionBump[] | null) => {
    setBumps(toShow)
  }, [])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
          borderRadius: 4,
          padding: theme.spacing(0),
        },
        title: {},
        header: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(0),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: theme.spacing(2),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        content: {
          paddingHorizontal: 0,
          paddingBottom: 0,
        },
        heading: {
          flex: 1,
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          paddingHorizontal: theme.spacing(2),
        },
        user: {
          color: theme.colors.primary,
        },
        listItem: {
          padding: 0,
          backgroundColor: theme.colors.background,
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.surfaceVariant,
          paddingRight: theme.spacing(5),
        },
        footer: {
          flexDirection: 'row',
          // margin: theme.spacing(2),
          padding: theme.spacing(4),
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        summary: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(1),
          columnGap: theme.spacing(2),
        },
      }),
    [theme]
  )
  const upCount = useMemo(
    () =>
      bumps?.reduce((acc, cur) => acc + (cur.value > 0 ? cur.value : 0), 0) ||
      0,
    [bumps]
  )
  const downCount = useMemo(
    () =>
      bumps?.reduce((acc, cur) => acc - (cur.value < 0 ? cur.value : 0), 0) ||
      0,
    [bumps]
  )
  const renderItem = ({ item }: { item: ContributionBump }) => {
    return (
      <List.Item
        onPress={() => {
          setBumps(null)
          navigation.navigate('Profile', { userId: item.userId })
        }}
        style={styles.listItem}
        title={item.user.username}
        titleStyle={styles.user}
        description={item.reason}
        right={() =>
          item.value > 0 ? (
            <Icon3dBumpUp
              size={22}
              color={theme.colors.success.main}
            />
          ) : (
            <Icon3dBumpDown
              size={22}
              color={theme.colors.danger.main}
            />
          )
        }
      />
    )
  }
  return (
    <BumpDetailsProviderContext.Provider value={value}>
      {children}
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={!!bumps}
          onDismiss={() => setBumps(null)}
        >
          <View style={styles.header}>
            <Text variant={'titleMedium'} style={styles.heading}>
              {t('Bump stats:')}
            </Text>
            <IconButton icon={IconClose} onPress={() => setBumps(null)} />
          </View>
          <Dialog.Content style={styles.content}>
            <FlatList
              data={bumps}
              renderItem={renderItem}
              keyExtractor={(item) => item.userId}
            />
          </Dialog.Content>
          <View style={styles.footer}>
            <Text variant={'titleMedium'} style={styles.heading}>
              {t('Overall')}: {bumps?.length}
            </Text>
            <View style={styles.summary}>
              <Text
                variant={'titleMedium'}
                style={{ color: theme.colors.danger.main }}
              >
                {downCount}
              </Text>
              <Icon3dBumpUp

                size={22}
                color={theme.colors.danger.main}
              />
            </View>
            <View style={styles.summary}>
              <Text
                variant={'titleMedium'}
                style={{ color: theme.colors.success.main }}
              >
                {upCount}
              </Text>
              <Icon3dBumpUp
                size={22}
                color={theme.colors.success.main}
              />
            </View>
          </View>
        </Dialog>
      </Portal>
    </BumpDetailsProviderContext.Provider>
  )
}

export const useBumpDetailsProvider = () => {
  return useContext(BumpDetailsProviderContext)
}
