export const en = `<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:DengXian;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"\@DengXian";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif'>Meldd Consensus
Privacy Policy</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company is Meldd Consensus Pte Ltd
(Singapore UEN 202141962K). This privacy policy will explain how our
organization uses the personal data we collect from you when you use our app.</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Topics:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>What data do we collect?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How do we collect your data?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How will we use your data?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How do we store your data?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Marketing</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>What are your data protection rights?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>What are cookies?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How do we use cookies?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>What types of cookies do we use?</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How to manage your cookies</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Privacy policies of other websites</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Changes to our privacy policy</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How to contact us</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>How to contact the appropriate
     authorities</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>What data do we collect?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company collects the following data:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>name</span></li>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>phone number</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How do we collect your data?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>You directly provide Our Company with all
the data we collect. We collect data and process data when you:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Register online.</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How will we use your data?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company collects your data so that we
can:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Enable access and manage your
     account.</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company will not share your data with other
companies.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How do we store your data?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company securely stores your data on
the Alibaba Cloud by default, or any other server operated by your organization
or by the cloud vendor designated by your organization.</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company will not keep your personal
data.  Any content in a node not owned by a client organization will be used
for test purposes and may be deleted at any time.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>Marketing</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company will not send you any
marketing information.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>What are your data protection rights?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company would like to make sure you
are fully aware of all of your data protection rights. Every user is entitled
to the following:</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to access</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to request Our Company for copies of your personal data. We may charge
you a small fee for this service.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to rectification</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to request that Our Company correct any information you believe is
inaccurate. You also have the right to request Our Company to complete the
information you believe is incomplete.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to erasure</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to request that Our Company erase your personal data, under certain
conditions.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to restrict processing</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to request that Our Company restrict the processing of your personal
data, under certain conditions.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to object to processing</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to object to Our Company’s processing of your personal data, under
certain conditions.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The right to data portability</span></b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> – You have the
right to request that Our Company transfer the data that we have collected to
another organization, or directly to you, under certain conditions.</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>If you make a request, we have one month
to respond to you. If you would like to exercise any of these rights, please
contact us at our email: contact@theprolog.com</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>Cookies</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Cookies are text files placed on your
computer to collect standard Internet log information and visitor behavior
information. When you visit our websites, we may collect information from you
automatically through cookies or similar technology</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>For further information, visit
allaboutcookies.org.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How do we use cookies?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company uses cookies in a range of
ways to improve your experience on our website, including:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Keeping you signed in</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>What types of cookies do we use?</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>There are a number of different types of
cookies, however, our website uses:</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
     font-family:"Times New Roman",serif'>Functionality – Our Company uses
     these cookies so that we recognize you on our website and remember your
     previously selected preferences. These could include what language you
     prefer and location you are in. A mix of first-party and third-party
     cookies are used.</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How to manage cookies</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>You can set your browser not to accept
cookies, and the above website tells you how to remove cookies from your
browser. However, in a few cases, some of our website features may not function
as a result.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>Privacy policies of other websites</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The Our Company website contains links to
other websites. Our privacy policy applies only to our website, so if you click
on a link to another website, you should read their privacy policy.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>Changes to our privacy policy</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Our Company keeps its privacy policy under
regular review and places any updates on this web page. This privacy policy was
last updated on 18 July 2023.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How to contact us</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>If you have any questions about Our
Company’s privacy policy, the data we hold on you, or you would like to
exercise one of your data protection rights, please do not hesitate to contact
us.</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Email us at: contact@theprolog.com</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>How to contact the appropriate authority</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Should you wish to report a complaint or
if you feel that Our Company has not addressed your concern in a satisfactory
manner, you may contact the Information Commissioner’s Office.</span></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Email:</span> <a
href="mailto:qsm@imda.gov.sg">qsm@imda.gov.sg</a></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Address: </span>10 Pasir Panjang Road,
#03-01 Mapletree Business City Singapore 117438</p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>
`

export const ch = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
lang=ZH-CN style='font-size:13.5pt;font-family:SimSun'>袂得共识数据隐私政策</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们的公司是</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> Meldd Consensus
Pte Ltd</span><span lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>（新加坡</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> UEN 202141962K</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>）。本隐私政策将解释我们的组织如何使用我们在您使用我们的应用程序过程中向您收集的个人数据。</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>主题：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们收集哪些数据？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们如何收集您的数据？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们将如何使用您的数据？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们如何存储您的数据？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>营销</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>您有哪些数据保护权利？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>什么是</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'>cookie</span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们如何使用</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'>cookies</span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们使用什么类型的</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie</span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>？</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>如何管理您的</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>其他网站的隐私政策</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>我们的隐私政策的变更</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>如何联系我们</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>如何联系有关当局</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们收集哪些数据？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司收集以下数据：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>名字</span></li>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>手机号码</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们如何收集您的数据？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>您直接向我们公司提供我们收集的所有数据。当您执行以下操作时，我们会收集数据并处理数据：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>在线注册。</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们将如何使用您的数据？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司收集您的数据，以便我们能够：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>启用访问并管理您的帐户。</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司不会与其他公司共享您的数据。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们如何存储您的数据？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司默认将您的数据安全地存储在阿里云、或者您所在机构或您所在机构指定的云供应商运营的任何其他服务器上。</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>本公司不会保留您的个人资料。</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>  </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>节点中不属于客户组织的任何内容将用于测试目的，并可能随时删除。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>营销</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司不会向您发送任何营销信息。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>您有哪些数据保护权利？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司希望确保您充分了解您的所有数据保护权利。每个用户都有权享有以下权利：</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>访问权</span></b><span lang=ZH-CN
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>您有权要求我们公司提供您的个人数据副本。我们可能会就此项服务向您收取少量费用。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>更正权</span></b><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span></b><b><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span></b><b><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span></b><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>您有权要求我们公司更正您认为不准确的任何信息。您还有权要求本公司补充您认为不完整的信息。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>删除权</span></b><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span></b><b><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span></b><b><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span></b><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>在某些条件下，您有权要求我们公司删除您的个人数据。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>限制处理的权利</span></b><span lang=ZH-CN
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>在某些条件下，您有权要求我们公司限制您的个人数据的处理。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>反对处理的权利</span></b><span lang=ZH-CN
style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>在某些条件下，您有权反对我们公司处理您的个人数据。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>数据可移植性的权利</span></b><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>在某些条件下，您有权要求我们公司将我们收集的数据传输给其他组织或直接传输给您。</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>如果您提出请求，我们有一个月的时间回复您。如果您想行使任何这些权利，请通过电子邮件联系我们：</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>contact@theprolog.com</span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif'>Cookies</span></b></p>

<p class=MsoNormal style='line-height:normal'><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Cookie </span><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>是放置在您计算机上的文本文件，用于收集标准互联网日志信息和访问者行为信息。当您访问我们的网站时，我们可能会通过</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>或类似技术自动收集您的信息</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>欲了解更多信息，请访问</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>
allaboutcookies.org</span><span lang=ZH-CN style='font-size:12.0pt;font-family:
SimSun'>。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们如何使用</span></b><b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif'>cookies</span></b><b><span
lang=ZH-CN style='font-size:13.5pt;font-family:SimSun'>？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司以多种方式使用</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>来改善您在我们网站上的体验，包括：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>让您保持登录状态</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们使用什么类型的</span></b><b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif'> cookie</span></b><b><span
lang=ZH-CN style='font-size:13.5pt;font-family:SimSun'>？</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>有许多不同类型的</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>，但是我们的网站使用：</span></p>

<ul type=disc>
 <li class=MsoNormal style='line-height:normal'><span lang=ZH-CN
     style='font-size:12.0pt;font-family:SimSun'>功能</span><span lang=ZH-CN
     style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>–</span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:"Times New Roman",serif'> </span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>我们公司使用这些</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie </span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>以便我们在我们的网站上识别您并记住您之前选择的偏好。这些可能包括您语言选项和您所在的位置。混合使用第一方和第三方</span><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif'> cookie</span><span
     lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>。</span></li>
</ul>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>如何管理您的</span></b><b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif'> cookie</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>您可以将浏览器设置为不接受</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>cookies</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>，上述网站告诉您如何从浏览器中删除</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>cookies</span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>。然而，在某些情况下，我们网站的某些功能可能会因此无法运行。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>其他网站的隐私政策</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司网站包含其他网站的链接。我们的隐私政策仅适用于我们的网站，因此如果您点击其他网站的链接，您应该阅读他们的隐私政策。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>我们的隐私政策的变更</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>我们公司定期审查其隐私政策，并在此网页上发布任何更新。本隐私政策的最新更新日期为</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> 2023 </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>年</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> 7 </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>月</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> 18 </span><span
lang=ZH-CN style='font-size:12.0pt;font-family:SimSun'>日。</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>如何联系我们</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>如果您对我们公司的隐私政策、我们持有的您的数据有任何疑问，或者您想行使您的一项数据保护权利，请随时与我们联系。</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>给我们发电子邮件：</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>contact@theprolog.com</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=ZH-CN
style='font-size:13.5pt;font-family:SimSun'>如何联系有关当局</span></b></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>如果您希望举报投诉，或者您认为我们公司没有以令人满意的方式解决您的问题，您可以联系信息专员办公室。</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>电子邮箱：</span><a
href="mailto:qsm@imda.gov.sg">qsm@imda.gov.sg</a></p>

<p class=MsoNormal style='line-height:normal'><span lang=ZH-CN
style='font-size:12.0pt;font-family:SimSun'>地址：</span>10 Pasir Panjang Road,
#03-01 Mapletree Business City Singapore 117438</p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>
`
