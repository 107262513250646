import * as React from 'react'
import { useMemo } from 'react'
import { Appbar, Button, IconButton } from 'react-native-paper'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../../navigation/types'
import { StyleSheet, Text, View } from 'react-native'
import { useTheme } from '../../../hooks/use-theme'
import { IconClose } from '../../../utils/meldd-icons'
import { useHeaderButton } from '../../../contexts/HeaderButtonProvider'
import { RemainingTimeIndicator } from './RemainingTimeIndicator'
import { Contribution } from '../types'
import { DecisionsButton } from '../../Contributions/components/DecisionsButton'
import { Env } from '../../../env'
import { useTranslation } from 'react-i18next'

export interface PerspectiveNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function PerspectiveNavigationBar({
  navigation,
  route,
  options,
  back,
}: PerspectiveNavigationBarProps) {
  const theme = useTheme()
  const { meta: reservation } =
    useHeaderButton<Contribution>('saveContribution')
  const { handler: cancelHandler, disabled: cancelDisabled } =
    useHeaderButton('cancelContribution')
  const { backButton } = useHeaderButton('navigation')
  const isEditing = !!reservation
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        backgroundColor: isEditing ? 'white' : theme.palettes.primary[96]!,
        justifyContent: 'space-between',
        paddingHorizontal: 20,
      },
    })
  }, [theme, isEditing])

  const goBack = () => {
    backButton ? backButton() : navigation.goBack()
  }
  return (
    <Appbar.Header style={styles.container}>
      {isEditing ? (
        <>
          {cancelHandler && (
            <IconButton
              disabled={cancelDisabled}
              mode={'outlined'}
              icon={IconClose}
              style={{ margin: 0 }}
              onPress={cancelHandler}
            />
          )}
        </>
      ) : back ? (
        <Appbar.BackAction
          onPress={() => goBack()}
          style={{ marginLeft: -10 }}
        />
      ) : null}
      {route?.params && (
        <Text
          numberOfLines={3}
          style={{
            fontSize: 15,
            textAlign: 'center',
            fontWeight: '400',
            flex: 1,
          }}>
          {route.params?.title}
        </Text>
      )}
      {isEditing && (
        <RemainingTimeIndicator
          maxDuration={Env.MAX_RESERVATION_DURATION}
          validUntil={new Date(reservation.reservedUntil).getTime()}
        />
      )}
      {!isEditing && <DecisionsButton />}
    </Appbar.Header>
  )
}
