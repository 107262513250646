import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client'
import { useEffect } from 'react'

/**
 *
 * Simplifies the Pagination for the APIs that use size and offset.
 * Returns a LoadPaginationFunction that can be used instead of fetchMore
 * This will work if the API has a `size` and `offset` field and return the variables in the `results` field
 */
export function usePaginationLazyQuery<
  T,
  Key extends string,
  TData extends { [key in Key]: { results: T[]; count: number } },
  TVariables extends OperationVariables & { size: number; offset: number }
>(
  key: Key,
  lazyHook: (
    options?: LazyQueryHookOptions<TData, TVariables>
  ) => [
    (options?: QueryLazyOptions<TVariables>) => void,
    LazyQueryResult<TData, TVariables>
  ],
  options?: LazyQueryHookOptions<
    TData,
    Pick<TVariables, Exclude<keyof TVariables, 'size' | 'offset'>>
  > & {
    pageSize?: number
  }
) {
  const pageSize = options?.pageSize || 20

  const [doLoad, queryResult] = lazyHook({
    variables: {
      ...options?.variables,
      size: pageSize,
      offset: 0,
    } as TVariables,
  })
  const loadPaginated = async () => {
    if (queryResult.fetchMore) {
      const currentLength = queryResult.data?.[key].results.length || 0

      await queryResult.fetchMore({
        variables: {
          offset: currentLength,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          // Abel: There is an issue, and the first load gets charged twice
          // This is really ugly solution please fix it
          const uniqueResults = new Set(
            [
              ...(prev[key].results || []),
              ...(fetchMoreResult[key].results || []),
            ].map((item) => JSON.stringify(item))
          )

          return {
            ...prev,
            [key]: {
              ...prev[key],
              ...fetchMoreResult[key],
              results: Array.from(uniqueResults).map((item) =>
                JSON.parse(item)
              ),
            },
          }
        },
      })
    }
  }

  useEffect(() => {
    doLoad({
      variables: {
        ...options?.variables,
        size: pageSize,
        offset: 0,
      } as TVariables,
    })
  }, [options?.variables])
  return {
    ...queryResult,
    loadPaginated,
  } as const
}

export const deleteUndefined = (obj: Record<string, unknown>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  })
}
