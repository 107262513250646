import WebView from 'react-native-webview'
import { en, ch } from '../webviews/privacyPolicy'
import { useTranslation } from 'react-i18next'

export function PrivacyPolicyScreen() {
  const { i18n } = useTranslation('common')
  const html = i18n.language === 'en' ? en : ch

  return (
    <WebView
      source={{ html }}
      scalesPageToFit={true}
      scrollEnabled={true}
      showsVerticalScrollIndicator={true}
    />
  )
}
