import { useNavigation } from '@react-navigation/native'
import { Pressable, StyleProp, StyleSheet, TextStyle } from 'react-native'
import { Text } from 'react-native-paper'
import { AppNavigation } from '../../../navigation/types'

type ClickableClusterMemberProps = {
  style?: StyleProp<TextStyle>
  user: { username: string; id: string }
  onPress?: (user: { username: string; id: string }) => void
}

const styles = StyleSheet.create({
  member: {
    alignSelf: 'center',
    textDecorationLine: 'underline',
  },
})

export function UserLink(props: ClickableClusterMemberProps) {
  const navigation = useNavigation<AppNavigation>()
  const { user, onPress } = props
  return (
    <Pressable
      style={{ flexShrink: 1 }}
      onPress={() =>
        onPress
          ? onPress(user)
          : navigation.navigate('Profile', { userId: user.id as string })
      }
    >
      <Text
        variant={'bodySmall'}
        numberOfLines={1}
        ellipsizeMode={'tail'}
        style={[styles.member, props.style]}
      >
        {user.username}
      </Text>
    </Pressable>
  )
}
