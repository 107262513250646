import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Chip, Menu } from 'react-native-paper'
import { Text } from 'react-native'

interface NodeSelectorProps<T extends string> {
  selected?: T
  onSelect: (value: T) => void
  options: Array<{ label: string; value: T }>
  anchorPosition?: 'top' | 'bottom'
}

export default function MelddChipPicker<T extends string = string>(
  props: NodeSelectorProps<T>
) {
  const [visible, setVisible] = React.useState(false)
  const onSelection = useCallback(
    (value?: string) => {
      setVisible(false)
      props.onSelect(value as T)
    },
    [props.onSelect]
  )
  const menuItems = useMemo(
    () =>
      props.options.map((item) => (
        <Menu.Item
          key={item.value}
          onPress={() => onSelection(item.value)}
          title={item.label}
        />
      )),
    [props.options]
  )
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={props.anchorPosition || 'bottom'}
      anchor={
        <Chip
          mode="outlined"
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => setVisible((prev) => !prev)}
          onClose={() => setVisible((prev) => !prev)}>
          <Text>
            {props.options.find((item) => item.value === props.selected)?.label}
          </Text>
        </Chip>
      }>
      {menuItems}
    </Menu>
  )
}
