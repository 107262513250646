import * as React from 'react'
import { Dialog, Portal, Text } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { MelddTextInput } from '../form/MelddInput'
import { useForm } from 'react-hook-form'
import MelddButton from '../MelddButton'
import { createStyle } from '../../contexts/GlobalStylesProvider'

type AddLinkProps = {
  onSubmit: (url: string) => void
}

export type ModalRef = {
  open: () => void
  close: () => void
}

export const AddLinkModal = React.forwardRef<ModalRef, AddLinkProps>(
  ({ onSubmit }, ref) => {
    const { t } = useTranslation('perspective')
    const [visible, setVisible] = React.useState(false)
    const styles = useStyle()
    const form = useForm({
      defaultValues: {
        url: '',
      },
      mode: 'onChange',
    })

    React.useImperativeHandle(ref, () => ({
      open: () => setVisible(true),
      close: () => setVisible(false),
    }))

    const onFormSubmit = React.useCallback(() => {
      form.handleSubmit((data) => {
        console.log({ data })
        onSubmit(data.url)
        form.reset()
        setVisible(false)
      })()
    }, [form, onSubmit, setVisible])

    return (
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={!!visible}
          onDismiss={() => setVisible(false)}
        >
          <Dialog.Content>
            <Text style={{ marginBottom: 16 }} variant="titleSmall">
              {t('add-link')}
            </Text>
            <MelddTextInput
              form={form}
              name="url"
              label={t('url')}
              rules={{
                required: {
                  value: true,
                  message: t('url-required'),
                },
                validate: (value) => {
                  const match = value.match(/^https?:\/\/.*\.\w{2,}/)
                  if (!match) {
                    return t('invalid-url')
                  }
                  return true
                },
              }}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <MelddButton
              color="#aaf"
              textColor="white"
              title={t('add')}
              onPress={() => onFormSubmit()}
            />
          </Dialog.Actions>
        </Dialog>
      </Portal>
    )
  }
)

const useStyle = createStyle(({ theme }) => ({
  dialog: {
    backgroundColor: theme.colors.surfaceVariant,
  },
}))
