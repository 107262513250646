import { useNavigation } from '@react-navigation/native'
import {
  useGetTargetContributionsLazyQuery,
  useGetTaskContributionsLazyQuery,
  useGetMyContributionsLazyQuery,
  useGetMyContributionByTaskIdQuery,
  TasksContributor,
} from '../../../generated/graphql'
import { MelddListItem } from '../../../components/containers/MelddListItem'
import { useMemo, useState } from 'react'
import { Icon } from '../../../components/Icon'
import { useTheme } from '../../../hooks/use-theme'
import { View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import {
  deleteUndefined,
  usePaginationLazyQuery,
} from '../../../utils/pagination'
import { EmptyList } from '../../../components/containers/EmptyList'
import { useTranslation } from 'react-i18next'
import { IconCompleted, IconPending } from '../../../utils/meldd-icons'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import {
  TaskContributionFilter,
  TaskContributionFilterDefaultValues,
} from './task-contribution.filter'
import LoadingFullScreen from '../../../components/containers/LoadingFullScreen'
import { MelddTaskContribution, MelddTasks } from '../task.types'
import { SocketEvents, useSocketRoom } from '../../../socket/socket.hooks'
import { useAuthContext } from '../../../auth/auth-context'
import { Text } from 'react-native-paper'
import Row from '../../../components/containers/Row'
import { TaskTypeButton } from './task-type.button'

function TaskContributionCard({
  item,
  groupBy,
}: {
  item: MelddTaskContribution
  groupBy: 'user' | 'task'
}) {
  const { t } = useTranslation('tasks')
  const navigation = useNavigation()
  return (
    <MelddListItem
      onPress={() => {
        groupBy === 'user'
          ? navigation.navigate('Profile', { userId: item.userId })
          : navigation.navigate('Task', { taskId: item.taskId })
      }}
      title={groupBy === 'task' ? item.task.title : item.user.username}
      description={
        <Row alignItems="center" style={{ marginTop: 8 }}>
          <TaskTypeButton task={item.task as MelddTasks} />

          <Text> • </Text>
          <Text>{t(`type.${item.task.type}`)}</Text>
        </Row>
      }
      right={() =>
        item.isCompleted ? (
          <View>
            <Row justifyContent="flex-end">
              <TouchableIconWithToolTip
                icon={<Icon source={IconCompleted} size={22} />}
                tooltip={
                  t('completed') +
                  ' ' +
                  new Date(item.completedAt).toLocaleDateString()
                }
              />
            </Row>
          </View>
        ) : (
          <TouchableIconWithToolTip
            icon={<IconPending size={20} />}
            tooltip={t('pending')}
          />
        )
      }
    />
  )
}

function TaskContributors({
  data,
  loading,
  loadPaginated,
  setUserFilters,
  groupBy,
  defaultValues,
  fixedFilters,
}: {
  data: MelddTaskContribution[]
  loading: boolean
  defaultValues: TaskContributionFilterDefaultValues
  loadPaginated: () => void
  userFilters: Record<string, unknown>
  setUserFilters: (filters: Record<string, unknown>) => void
  groupBy: 'user' | 'task'
  fixedFilters: Record<string, unknown>
}) {
  const theme = useTheme()
  const { t } = useTranslation('tasks')

  return (
    <View style={{ flex: 1 }}>
      <View style={{ marginHorizontal: theme.spacing(3) }}>
        <TaskContributionFilter
          defaultValues={defaultValues}
          fixedFilters={fixedFilters}
          onFilter={(userFilters) => setUserFilters(userFilters)}
        />
      </View>
      {loading && <LoadingFullScreen />}
      <FlatList
        style={{ paddingHorizontal: theme.spacing(0) }}
        data={data}
        renderItem={({ item }) => (
          <TaskContributionCard item={item} groupBy={groupBy} />
        )}
        keyExtractor={(_, index) => index.toString()}
        ListEmptyComponent={
          <EmptyList message={t('no-contributors')} loading={loading} />
        }
        onEndReached={loadPaginated}
        ListFooterComponent={<View style={{ height: 200 }}></View>}
      />
    </View>
  )
}

export const TaskContributorsByTask = ({
  fixedFilters,
}: {
  fixedFilters: { taskId: string }
}) => {
  const [userFilters, setUserFilters] = useState<Record<string, unknown>>({})
  const variables = useMemo(
    () => ({
      variables: {
        input: {
          ...fixedFilters,
          ...userFilters,
        },
      },
      pageSize: 15,
    }),
    [fixedFilters, userFilters]
  )

  const { data, loadPaginated, loading, refetch } = usePaginationLazyQuery(
    'getTaskContributions',
    useGetTaskContributionsLazyQuery,
    variables
  )
  useSocketRoom(fixedFilters.taskId).event(SocketEvents.Refresh, refetch)

  return (
    <TaskContributors
      data={data?.getTaskContributions.results || []}
      loading={loading}
      loadPaginated={loadPaginated}
      userFilters={userFilters}
      setUserFilters={setUserFilters}
      groupBy="user"
      defaultValues={{ status: 'all' }}
    />
  )
}

export const TaskContributorsByTarget = ({
  fixedFilters,
}: {
  fixedFilters: {
    targetId: string
    targetType: string
    completed?: boolean
    userId?: string
  }
}) => {
  const [userFilters, setUserFilters] = useState<Record<string, unknown>>({})

  const variables = useMemo(
    () => ({
      variables: {
        input: {
          ...fixedFilters,
          ...userFilters,
        },
      },
      pageSize: 15,
    }),
    [fixedFilters, userFilters]
  )
  const { data, loadPaginated, loading, refetch } = usePaginationLazyQuery(
    'getTargetContributions',
    useGetTargetContributionsLazyQuery,
    variables
  )
  useSocketRoom(fixedFilters.targetId).event(SocketEvents.Refresh, refetch)

  return (
    <TaskContributors
      data={data?.getTargetContributions.results || []}
      loading={loading}
      loadPaginated={loadPaginated}
      userFilters={userFilters}
      setUserFilters={setUserFilters}
      groupBy="task"
      defaultValues={{
        status: fixedFilters.completed !== undefined ? undefined : 'all',
      }}
    />
  )
}

/**
 * If all the filter will be displayed
 * Status is `all` by default
 */
export const MyContributions = ({
  status,
}: {
  status?: 'pending' | 'completed' | 'all'
}) => {
  const { userId } = useAuthContext()
  const [userFilters, setUserFilters] = useState<Record<string, unknown>>({})
  const statusFilter = status || 'all'

  const fixedFilters = useMemo(() => {
    if (statusFilter === 'all') return {}
    return { completed: statusFilter === 'completed' }
  }, [statusFilter])

  const variables = useMemo(
    () => ({
      variables: {
        input: {
          ...fixedFilters,
          ...userFilters,
        },
      },
      pageSize: 15,
    }),
    [userFilters, fixedFilters]
  )
  const { data, loadPaginated, loading, refetch } = usePaginationLazyQuery(
    'getMyContributions',
    useGetMyContributionsLazyQuery,
    variables
  )
  useSocketRoom(userId).event(SocketEvents.Refresh, refetch)

  const defaultFilterValues = {
    status: statusFilter !== 'all' ? undefined : ('all' as const), // If is fixed, whe don't display the filter
    type: 'all' as const,
  }
  deleteUndefined(defaultFilterValues)
  return (
    <TaskContributors
      data={data?.getMyContributions.results || []}
      loading={loading}
      loadPaginated={loadPaginated}
      userFilters={userFilters}
      setUserFilters={setUserFilters}
      groupBy="task"
      fixedFilters={fixedFilters}
      defaultValues={defaultFilterValues}
    />
  )
}

export const MyTaskContribution = ({ taskId }: { taskId: string }) => {
  const { data, loading, refetch } = useGetMyContributionByTaskIdQuery({
    variables: {
      taskId,
    },
  })
  useSocketRoom(taskId).event(SocketEvents.Refresh, refetch)

  if (loading) return <LoadingFullScreen />

  if (!data?.getMyContributionByTaskId) return null
  return (
    <TaskContributionCard
      item={data?.getMyContributionByTaskId as TasksContributor}
      groupBy="task"
    />
  )
}
