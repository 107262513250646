import * as React from 'react'
import { Button, Modal, Portal, Text } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

type RegisterSSModalProps = {
  mergeAccount: () => void
  ssoName: string
  onNewAccount?: () => void
}

export type RegisterSSOModalRef = {
  open: () => void
  close: () => void
}
/**
 * A little modal that appears when we login with SSO and the account doesn't exists.
 * Will allow us to register a new account based in this SSO, or login with our exisisting account
 * and linked to the SSO.
 */
export const RegisterSSOModal = React.forwardRef<
  RegisterSSOModalRef,
  RegisterSSModalProps
>(({ onNewAccount, mergeAccount, ssoName }, ref) => {
  const { t } = useTranslation('login_register_reset')
  const [visible, setVisible] = React.useState(false)

  const hideModal = () => setVisible(false)
  React.useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }))

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={styles.container}>
          <Text style={styles.title}>{t('sso-new-account', { ssoName })}</Text>
          <Text style={{ marginBottom: 20 }}>
            {t('sso-new-account-question')}
          </Text>
          {!!onNewAccount && (
            <Button
              style={{ width: '100%' }}
              mode="contained"

              onPress={() => onNewAccount()}>
              {t('sso-new-account-create')}
            </Button>
          )}
          <View style={styles.row}>
            <Button
              style={{ width: '100%', }}
              mode="outlined"
              onPress={() => mergeAccount()}>
              {t('sso-new-account-link')}
            </Button>

          </View>
        </Modal>
      </Portal>
    </>
  )
})

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'center',
    marginTop: 16
  },
  container: { backgroundColor: 'white', padding: 20, margin: 20 },
  title: { fontSize: 20, fontWeight: 'bold', marginBottom: 10 },
})
