import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { IconButton, Text } from 'react-native-paper'
import {
  ContributionStatus,
  useContributionsForMyArticlesQuery,
} from '../../../generated/graphql'
import { IconDecisions } from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { useTranslation } from 'react-i18next'
import { SocketEvents, useSocketRoom } from '../../../socket/socket.hooks'
import { useAuthContext } from '../../../auth/auth-context'

interface DecisionsButtonProps {}

export function DecisionsButton(props: DecisionsButtonProps) {
  const theme = useTheme()
  const { t } = useTranslation('header')
  const navigation = useNavigation<AppNavigation>()
  const { loading, data, refetch } = useContributionsForMyArticlesQuery({
    variables: {
      status: ContributionStatus.Pending,
    },
    fetchPolicy: 'cache-and-network',
  })
  const { userId } = useAuthContext()
  useSocketRoom(userId).event(SocketEvents.Refresh, refetch)

  const isFocused = useIsFocused()
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])

  const count = useMemo(
    () =>
      (data?.contributionsForMyArticles || []).filter(
        (c) => c.status == ContributionStatus.Pending
      ).length,
    [data]
  )
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          position: 'relative',
        },
        badge: {
          position: 'absolute',
          top: 5,
          right: 3,
          backgroundColor: theme.colors.danger.main,
          borderRadius: 10,
          paddingVertical: 1,
          paddingHorizontal: 5,
        },
        indicator: {
          position: 'absolute',
          top: 5,
          right: 3,
          transform: [{ scale: 0.7 }],
        },
      }),
    [theme]
  )

  const badge = useMemo(() => {
    if (loading) {
      return (
        <ActivityIndicator
          style={styles.indicator}
          size={'small'}
          color={theme.colors.danger.main}
        />
      )
    }
    if (count > 0) {
      return (
        <View style={styles.badge}>
          <Text style={{ color: 'white' }} variant={'labelSmall'}>
            {count}
          </Text>
        </View>
      )
    }
    return null
  }, [count, loading])
  return (
    <View style={styles.container}>
      <IconButton
        onPress={() => navigation.navigate('Decisions')}
        icon={IconDecisions}
        size={24}
      />
      {badge}
    </View>
  )
}
