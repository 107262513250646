import * as React from 'react'
import {
  CSSProperties,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react'
import { StyleProp, StyleSheet } from 'react-native'

interface WebViewWebProps {
  title: string
  src: string
  onLoad: () => void
  onMessage: (event: any) => void
  style?: StyleProp<CSSProperties>
  injectedJavaScript?: string
}

const styles = StyleSheet.create({
  iframe: {
    width: '100%',
    minHeight: '100%',
    borderWidth: 0,
  },
})

export interface WebViewWebRef {
  postMessage: (message: string) => void
  injectJavaScript: (script: string) => void
}

function WebViewWebImpl(props: WebViewWebProps, ref: Ref<WebViewWebRef>) {
  const iframe = React.useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    const handler = (nativeEvent: any) => {
      //      console.log('forwarding message', nativeEvent);
      props.onMessage({ nativeEvent })
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  })
  const handleInjectedJavaScript = useCallback(
    (html?: string) => {
      if (html) {
        let baseAdded = html.replace(
          '</head>',
          `<script>
const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (entry.contentBoxSize) {
      if (entry.contentBoxSize[0]) {
        parent.document.getElementById('iframe').style.height=entry.contentBoxSize[0].blockSize+'px';
      } else {
        parent.document.getElementById('iframe').style.height=entry.contentBoxSize.blockSize+'px';
      } 
    } else {
        parent.document.getElementById('iframe').style.height=entry.contentRect.height+'px';
        window.parent.frames[0].height=entry.contentRect.height;
    }
  }
});
window.ReactNativeWebView = {
  postMessage(val) {
    window.parent.postMessage(val)
  }
}
console.log('Added ReactNativeWebView.postMessage')
</script></head>`
        )
        if (props.injectedJavaScript) {
          baseAdded = baseAdded.replace(
            '</body>',
            `<script>${props.injectedJavaScript}</script></body>`
          )
        }
        baseAdded = baseAdded.replace(
          '</body>',
          `<script>resizeObserver.observe(document.querySelector('body'));</script></body>`
        )
        return baseAdded
      } else {
        return html
      }
    },
    [props.injectedJavaScript]
  )
  const { title, /*source,*/ onLoad, style } = props
  useImperativeHandle(ref, () => {
    return {
      postMessage: (message: string) => {
        iframe.current?.contentWindow?.postMessage(message, '*')
      },
      injectJavaScript: (script: string) => {
        // @ts-ignore
        iframe.current?.contentWindow.eval?.(script)
      },
    }
  })

  return (
    <iframe
      id={'iframe'}
      ref={iframe}
      src={props.src}
      // srcDoc={handleInjectedJavaScript(source)}
      // srcDoc='<!DOCTYPE html><html><body>LOADING...</body></html>'
      // seamless={true}
      style={StyleSheet.flatten([styles.iframe, style])}
      onLoad={onLoad}
      onError={(e) => {
        console.error('Error', e)
      }}
    ></iframe>
  )
}

export const WebViewWeb = React.forwardRef<WebViewWebRef, WebViewWebProps>(
  WebViewWebImpl
)
