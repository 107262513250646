import * as React from 'react'
import { ActivityIndicator } from 'react-native-paper'
import Animated, { ZoomIn, ZoomOut, Layout } from 'react-native-reanimated'
import { useTheme } from '../../hooks/use-theme'
import { StyleSheet } from 'react-native'
interface CenteredAnimatedActivityProps {
  show: boolean
}
const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    position: 'absolute',
    bottom: 1,
    right: 1,
    top: 1,
    left: 1,
    zIndex: 2,
    borderRadius: 6,
    backgroundColor: 'rgba(255,255,255,.8)',
  },
})
export function CenteredAnimatedActivity(props: CenteredAnimatedActivityProps) {
  const theme = useTheme()
  const { show } = props
  return (
    (show && (
      <Animated.View
        entering={ZoomIn}
        exiting={ZoomOut}
        layout={Layout}
        style={styles.container}
      >
        <ActivityIndicator
          style={{ alignSelf: 'center' }}
          animating={true}
          size="large"
          color={theme.colors.primary}
        />
      </Animated.View>
    )) ||
    null
  )
}
