import { useContext, useMemo } from 'react'
import { View, StyleSheet } from 'react-native'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useTranslation } from 'react-i18next'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { AuthContext } from '../../auth/auth-context'
import {
  useDeleteAccountMutation,
  User,
  useUserQuery,
} from '../../generated/graphql'
import { UserContributions } from './components/UserContributions'
import UserPerspectives from './components/UserPerspectives'
import { Text, Button } from 'react-native-paper'
import { AppTheme, useTheme } from '../../hooks/use-theme'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../utils/message-type'
import Logout from '../../components/Logout'
import { SocialAccounts } from './components/SocialAccounts'
import Profile from './components/Profile'


export function ProfileScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'Profile'>) {
  const { userId } = route.params

  const { t } = useTranslation('profile')
  const { userId: myUserId, clearCurrentUser } = useContext(AuthContext)
  const theme = useTheme()
  const styles = useStyle(theme)
  const { data } = useUserQuery({
    variables: {
      userId: userId || myUserId,
    },
  })
  const globalStyles = useGlobalStyles()


  const isProfileOwner = userId === myUserId
  const user = useMemo(() => data?.user, [data])

  const [doDeleteAccount, { loading: deleting }] = useDeleteAccountMutation()

  const handleDeleteAccount = async () => {
    try {
      await doDeleteAccount()
      Toast.show(t('User deleted'))
      clearCurrentUser()
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }

  const isExistingAccount = user?.firstName || user?.lastName
  return (
    <ScreenWrapper contentContainerStyle={globalStyles.pageContainer}>
      {isExistingAccount ?
        <Profile user={user} theme={theme} isProfileOwner={isProfileOwner} />
        :
        <Text variant={'titleLarge'}>{user?.username}</Text>
      }
      <View style={{ marginVertical: theme.spacing(2) }} />

      {
        isProfileOwner && (
          <>
            <SocialAccounts user={user as User} />
            <View style={{ marginVertical: theme.spacing(2) }} />
            <View style={styles.row}>
              {/* Space for other badges ... */}
              <View style={{ flex: 1 }}></View>

              <View style={{ flex: 1 }}></View>
            </View>
          </>
        )
      }

      <UserContributions
        style={{ marginVertical: theme.spacing(5) }}
        userId={userId}
      />
      <UserPerspectives
        style={{ marginVertical: theme.spacing(5) }}
        userId={userId}
      />

      {
        isProfileOwner && (
          <>
            <View style={styles.row}>
              <View style={{ flex: 1 }}></View>
              <View style={{ flex: 1 }}></View>
              <View style={{ flex: 1 }}>
                <Logout />
              </View>
            </View>
            <View style={{ marginVertical: theme.spacing(4) }} />

            <Button
              style={styles.deleteButton}
              textColor="white"
              mode="text"
              loading={deleting}
              onPress={handleDeleteAccount}>
              {t('Delete')}
            </Button>
            <View style={{ ...styles.row, height: 20 }}></View>
          </>
        )
      }
    </ScreenWrapper >
  )
}


const useStyle = (theme: AppTheme) => useMemo(
  () =>
    StyleSheet.create({
      row: {
        flexDirection: 'row',
        columnGap: theme.spacing(2),
        alignItems: 'center',
        marginBottom: theme.spacing(2),
      },
      deleteButton: {
        marginTop: 10,
        marginBottom: 10,
        flexGrow: 1,
        borderRadius: 0,
        backgroundColor: theme.colors.warn,
      },
    }),
  [theme]
)
export default ProfileScreen
