import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'

interface LoadingFullScreenProps {
  size?: 'small' | 'large'
}

/**
 * WARNING: Make sure the parent has full height!
 */
const LoadingFullScreen: React.FC<LoadingFullScreenProps> = ({
  size = 'large',
}) => {
  const { colors } = useTheme()

  return (
    <View style={styles.container}>
      <ActivityIndicator size={size} color={colors.primary} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: 300,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default LoadingFullScreen
