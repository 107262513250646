import AsyncStorage from '@react-native-async-storage/async-storage'
import QRCode from 'qrcode'
import { Platform } from 'react-native'
import Toast from 'react-native-root-toast'
import { v4 as uuidv4 } from 'uuid'
import { Env } from '../env'
import i18n from '../i18n'

const WECHAT_STATE_KEY = 'WECHAT_STATE_KEY'

export type WechatWrapper = {
  setup: () => Promise<Boolean>
  callback: () => Promise<string>
  auth: (redirectPath: string) => Promise<boolean>
  isInstalled: () => Promise<boolean>
}

export const isWeChatBrowser = () => {
  return /MicroMessenger/i.test(navigator.userAgent)
}

export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export const isDesktopBrowser = () => {
  return !isMobileBrowser() && !isWeChatBrowser()
}

/**
 * Native wrapper is never tested as Native is not being continued by know
 * The project seems easy to handle, but I got may issues building the native part `expo-native-wechat`
 */
const WechatNativeWrapper = async (wechatId: string) => {
  const { registerApp, sendAuthRequest, isWechatInstalled } = await import(
    'expo-native-wechat'
  )
  return {
    setup: async () => {
      return (await registerApp({ appid: wechatId })) || false
    },
    auth: async (_redirect: string) => {
      // We keep this secret on the browser to compare on the redirect
      const wechatState = uuidv4()
      AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)
      await sendAuthRequest({
        scope: 'snsapi_userinfo',
        state: wechatState,
      })
      return true
    },
    isInstalled: async () => {
      const result = (await isWechatInstalled()) as unknown as {
        success: boolean
      }
      return result.success
    },
    callback: () => Promise.resolve(''),
  }
}

const WechatWebWrapper = (wechatId: string) => {
  // Need to be capture before on creating, after the `await` the location.search will be empty
  const urlParams = new URLSearchParams(window.location.search)
  const callbackCode = urlParams.get('code') || ''
  const callbackState = urlParams.get('state') || ''
  const wechatRedirect = urlParams.get('wechatRedirect') || ''

  const loadWechatScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src =
        'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      script.onload = () => resolve(true)
      script.onerror = () => reject(false)
      document.body.appendChild(script)
    })
  }

  const desktopQRCodeAuth = (path: string): Promise<boolean> => {
    return new Promise((resolve) => {
      try {
        const redirectUri = encodeURIComponent(
          `${window.location.origin}/${path}`
        )
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        // WeChat OAuth URL (using the snsapi_login scope for getting user info)
        const options = {
          id: 'wechat-login-container',
          appid: wechatId,
          scope: 'snsapi_login',
          redirect_uri: redirectUri,
          state: wechatState,
          lang: i18n.language,
        }
        new (window as any).WxLogin(options)
        resolve(true)
      } catch (err) {
        console.error('Failed to load', err)
        Toast.show((err as Error).toString())
        resolve(false)
      }
    })
  }

  const mobileQRCodeAuth = (path: string): Promise<boolean> => {
    return new Promise((resolve) => {
      try {
        const redirectUri = encodeURIComponent(
          `${window.location.origin}/${path}`
        )
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        const selector = document.getElementById('wechat-login-container')
        if (!selector) {
          return resolve(false)
        }

        const wechatId = Env.WECHAT_PUBLIC_ACCOUNT_ID

        // WeChat OAuth URL (using the snsapi_userinfo scope for getting user info)
        const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${wechatState}#wechat_redirect`

        const img = document.createElement('img')
        selector.appendChild(img)

        QRCode.toDataURL(wechatUrl, { width: 200, margin: 6 }, (error, url) => {
          if (error) {
            selector.textContent = error.message || 'Unknown error'
          } else {
            img.src = url // Set the base64 data URL as the image source
          }
        })
        resolve(true)
      } catch (err) {
        console.error('Failed to load', err)
        Toast.show((err as Error).toString())
        resolve(false)
      }
    })
  }

  const wechatRedirectAuth = (path: string): Promise<boolean> => {
    return new Promise((resolve) => {
      try {
        const redirectUri = encodeURIComponent(
          `${window.location.origin}/${path}`
        )
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        const wechatId = Env.WECHAT_PUBLIC_ACCOUNT_ID

        // WeChat OAuth URL (using the snsapi_userinfo scope for getting user info)
        const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${wechatState}#wechat_redirect`
        window.location.href = wechatUrl
        resolve(true)
      } catch (err) {
        console.error('Failed to load', err)
        Toast.show((err as Error).toString())
        resolve(false)
      }
    })
  }

  return {
    setup: async () => {
      if (!(await loadWechatScript())) {
        Toast.show('NO LOADED!')
        return false
      }
      return true
    },
    callback: async () => {
      // If you are in mobile, but not in wechat, we generate a code that
      // when logged in wechat will do the redirect automatically to them
      if (wechatRedirect && isWeChatBrowser()) {
        wechatRedirectAuth(wechatRedirect)
        return ''
      }
      if (!callbackState || !callbackCode) {
        return ''
      }
      const savedState = (await AsyncStorage.getItem(WECHAT_STATE_KEY)) || ''
      if (
        callbackState !== savedState &&
        !Env.IS_DEVELOP &&
        !isMobileBrowser()
      ) {
        Toast.show('Invalid Wechat state, please try again')
        return ''
      }
      return callbackCode
    },
    auth: (path: string): Promise<boolean> => {
      if (isWeChatBrowser()) {
        return wechatRedirectAuth(path)
      }
      if (isMobileBrowser()) {
        return mobileQRCodeAuth(path)
      }
      return desktopQRCodeAuth(path)
    },
    isInstalled: () => Promise.resolve(true),
  }
}

/**
 *  An abstraction to simplify the code on the screen.
 * That loads web or native libraries accordingly
 */
export const Wechat = async (wechatId: string): Promise<WechatWrapper> => {
  if (Platform.OS !== 'web') {
    return WechatNativeWrapper(wechatId)
  }
  return WechatWebWrapper(wechatId)
}
