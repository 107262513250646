import { useState, useEffect, useCallback } from 'react'
import { Change, diffWords } from 'diff'
import { Contribution } from '../types'

const generateValidationOutput = (html: string, delta = 150) => {
  const differenceSpanRegex = /<span class="difference"[^>]*>.*?<\/span>/g
  const matches = html.match(differenceSpanRegex)
  // Collect chunks start and end
  const chunks = (matches || []).map((match) => {
    const from = html.indexOf(match)
    const to = from + match.length
    return { from, to }
  })

  // Group chunks that are close together
  const groupedChunks = chunks.reduce(
    (groups: Array<{ from: number; to: number }>, chunk, index) => {
      if (index === 0) {
        return [chunk]
      }

      const lastGroup = groups[groups.length - 1]

      if (chunk.from - lastGroup.to <= delta) {
        lastGroup.to = chunk.to
      } else {
        groups.push(chunk)
      }

      return groups
    },
    []
  )
  console.log('chunks', { chunks, groupedChunks })

  // Return the text around the chunks
  return groupedChunks.map((chunk) => {
    return html.slice(chunk.from - delta, chunk.to + delta)
  })
}
const validateHTML = (html: string, delta = 50) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  if (doc.body.innerHTML === html) {
    return []
  }

  const differences = diffWords(html, doc.body.innerHTML)

  const result = differences.reduce((text: string, part: Change) => {
    const escapedValue = part.value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')

    const value = part.added
      ? `<span class="difference" style="color: #006400; font-weight: bold;">${escapedValue}</span>`
      : part.removed
      ? `<span class="difference" style="color: #8B0000; font-weight: bold;">${escapedValue}</span>`
      : `${escapedValue}`
    return text + value
  }, '')

  /**
   * We don't want to show all the text (can be too big) but just the text around the issues
   */
  return generateValidationOutput(result)
}

export const useValidationMessage = (
  inputText: string,
  contribution: Contribution,
  context: string
) => {
  const [validationResult, setValidationResult] = useState<string[]>([])

  const validateMessage = useCallback(() => {
    const beforeContribution = context.slice(0, contribution.begin)
    const afterContribution = context.slice(
      contribution.begin + contribution.originalText.length
    )
    const contextAfterContribution =
      beforeContribution + inputText + afterContribution
    console.log('contextAfterContribution', { contextAfterContribution })

    const result = validateHTML(contextAfterContribution)

    setValidationResult(result)
  }, [inputText, contribution, context])

  useEffect(() => {
    validateMessage()
  }, [validateMessage])

  return validationResult
}
