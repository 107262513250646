import { StackScreenProps } from '@react-navigation/stack'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextInput, View } from 'react-native'
import Toast from 'react-native-root-toast'
import {
  ArticleEditor,
  ArticleEditorRef,
} from '../components/ArticleEditor/ArticleEditor'
import MelddButton from '../components/MelddButton'
import {
  ArticleDetailFragmentDoc,
  CreateArticleInput,
  useCreateArticleMutation,
} from '../generated/graphql'
import { useKeyboard } from '../hooks/use-keyboard-hook'
import { AppNavigatorParams } from '../navigation/types'
import { MessageType } from '../utils/message-type'
import { Theme } from '../utils/theme'
import { isDesktopBrowser } from '../utils/wechat.login'

const trimProcessor = (content: string) => content.trim()
const convertLinksProcessor = function processOrphanLinks(
  content: string
): string {
  // First, temporarily replace existing <a> tags with a placeholder
  const placeholders: { [key: string]: string } = {}
  let counter = 0

  const contentWithoutATags = content.replace(
    /<a\s+[^>]*>.*?<\/a>/g,
    (match) => {
      const placeholder = `__LINK_PLACEHOLDER_${counter}__`
      placeholders[placeholder] = match
      counter++
      return placeholder
    }
  )

  // Find all URLs and create placeholders for them
  const urlRegex = /https?:\/\/[^\s<>,()[\]{}'"]+\b/g

  const contentWithNewATags = contentWithoutATags.replace(urlRegex, (url) => {
    return `<a href="${url}" title="${url}">${url}</a>`
  })

  // Restore original <a> tags
  const finalContent = contentWithNewATags.replace(
    /__LINK_PLACEHOLDER_\d+__/g,
    (placeholder) => placeholders[placeholder]
  )

  return finalContent
}

function CreateArticleScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'CreateArticle'>) {
  const { clusterId, content, title: docTitle } = route.params
  const { t } = useTranslation('articles')

  const [title, setTitle] = useState(docTitle || '')
  const editorRef = useRef<ArticleEditorRef>(null)
  const keyboardHeight = useKeyboard()

  const [doCreateArticle, { loading }] = useCreateArticleMutation({
    update: (cache, result) => {
      const { data } = result
      if (data) {
        cache.modify({
          id: `Cluster:${clusterId}`,
          fields: {
            articles: (currentArticles: any[]) => {
              const newArticleRef = cache.writeFragment({
                data: data.createArticle,
                fragment: ArticleDetailFragmentDoc,
              })
              return [...currentArticles, newArticleRef]
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (!clusterId) {
      Toast.show(
        t('No cluster found. Please refresh the app.'),
        MessageType.error
      )
      navigation.pop()
    }
  })

  const handleCreateArticle = async (
    originalContent: string,
    originalText: string
  ) => {
    if (!originalText.trim()) {
      return Toast.show(t("The content can't be empty"), MessageType.warn)
    }
    const processors = [trimProcessor, convertLinksProcessor]

    const finalContent = processors.reduce(
      (acc, processor) => processor(acc),
      originalContent
    )

    const _title = !title.trim() ? originalText.trim().slice(0, 30) : title

    const input: CreateArticleInput = {
      originalContent: finalContent,
      title: _title,
      clusterId,
      isAutoTitle: !title.trim(),
    }
    try {
      const result = await doCreateArticle({ variables: { input } })
      Toast.show(t('Your article has been created'), MessageType.info)

      navigation.replace('Perspective', {
        articleId: result.data?.createArticle?.id || '',
      })
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
    }
  }

  const onCreateArticle = () => {
    editorRef.current?.requestContent()
  }

  function toHomepage() {
    navigation.pop()
  }

  const styles = StyleSheet.create({
    container: {
      paddingVertical: 10,
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },

    title: {
      height: 32,
      justifyContent: 'flex-start',
      backgroundColor: '#f5f2f7',
      minHeight: 30,
      paddingHorizontal: 15,
      flex: 1,
      width: '100%',
    },
    button: {
      marginHorizontal: 10,
      marginTop: 10,
    },
    containerValidation: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    editor: {
      flex: 1,
      width: isDesktopBrowser() ? '50%' : '100%',
    },
  })

  return (
    <View style={styles.container}>
      <View
        style={{
          width: isDesktopBrowser() ? '50%' : 360,
          marginVertical: 20,
        }}
      >
        <TextInput
          editable={true}
          style={[styles.title]}
          underlineColorAndroid="transparent"
          placeholder={t('Automatic title')}
          placeholderTextColor="grey"
          onChangeText={(text) => setTitle(text)}
          value={title}
        />
      </View>

      <ArticleEditor
        style={styles.editor}
        ref={editorRef}
        initialContentHTML={content || ''}
        editorMode={'edit'}
        onHtmlContent={handleCreateArticle}
      />

      <View style={styles.containerValidation}>
        <MelddButton
          onPress={toHomepage}
          isDisabled={loading}
          color="grey"
          title={t('Cancel')}
          textColor="white"
          fontSize={15}
          style={styles.button}
        />
        <MelddButton
          onPress={onCreateArticle}
          isDisabled={loading}
          isLoading={loading}
          color={Theme.color.primary.main}
          textColor="white"
          title={t('Publish')}
          fontSize={15}
          style={styles.button}
        />
      </View>

      <View style={{ height: keyboardHeight }} />
    </View>
  )
}

export default CreateArticleScreen
