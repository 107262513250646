import { View } from 'react-native'
import { AppNavigation, AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { TaskList } from './components/task-list'
import MButton from '../../components/m-button/MButton'
import {
  getTargetDetail,
  MelddTaskTarget,
  MelddTaskTargetType,
  navigateToTaskTarget,
  useGetTarget,
} from './task.types'
import LoadingFullScreen from '../../components/containers/LoadingFullScreen'
import { EmptyList } from '../../components/containers/EmptyList'
import { useTranslation } from 'react-i18next'
import {
  IconAssignedTask,
  IconCompleted,
  IconCreatedTask,
  IconGoTo,
  IconOpenContribution,
} from '../../utils/meldd-icons'
import Row from '../../components/containers/Row'
import { IconButton, Text } from 'react-native-paper'
import colors from '../../constants/colors'
import { TaskTypeLabel } from './components/task-type.button'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../../auth/auth-context'
import { useMemo, useState } from 'react'
import MelddTabs from '../../components/containers/MelddTabs'
import { TaskContributorsByTarget } from './components/task-contributors'
import { usePendingTasksQuery } from '../../generated/graphql'

const TaskListTabs = ({
  targetId,
  selectedTab,
  setSelectedTab,
}: {
  targetId: string
  selectedTab: 'pending' | 'completed'
  setSelectedTab: (value: 'pending' | 'completed') => void
}) => {
  const { t } = useTranslation(['tasks', 'common'])
  const { isAnonymous } = useAuthContext()
  const { data } = usePendingTasksQuery({
    variables: {
      targetId,
    },
    skip: isAnonymous || !targetId,
  })

  const tabs = useMemo(
    () => [
      // Manage task is hidden yet
      // { label: t('tasks'), value: 'tasks', icon: IconAssignedTask },
      {
        label: t('pending-tasks'),
        value: 'pending',
        icon: IconCreatedTask,
        badge: data?.pendingTasks?.pendingContributions,
      },
      {
        label: t('completed'),
        value: 'completed',
        icon: IconCompleted,
      },
    ],
    [t, data?.pendingTasks]
  )
  return (
    <MelddTabs
      items={tabs}
      selected={selectedTab}
      onSelect={(value) => {
        setSelectedTab(value as 'pending' | 'completed')
      }}
    />
  )
}
const TargetTaskHeader = ({
  target,
  targetType,
}: {
  target: MelddTaskTarget
  targetType: MelddTaskTargetType
}) => {
  const styles = useStyle()
  const info = getTargetDetail({ target, targetType })
  const navigation = useNavigation<AppNavigation>()
  return (
    <View style={styles.header}>
      <Row flex={1} columnGap={8} wrap>
        <View>
          <Text variant={'titleLarge'} style={{ marginBottom: 4 }}>
            {info.title}
          </Text>
          <TaskTypeLabel task={{ targetType, targetId: info.id }} />
        </View>
      </Row>
      <Row alignItems={'center'}>
        <IconButton
          icon={IconGoTo}
          size={24}
          onPress={() =>
            navigateToTaskTarget({
              task: { targetType, targetId: info.id },
              navigation,
            })
          }
          style={styles.backButton}
        />
      </Row>
    </View>
  )
}
export function TaskListScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'TaskList'>) {
  const { status, targetId, targetType } = route.params
  const { userId } = useAuthContext()
  const { t } = useTranslation(['tasks', 'common'])
  const styles = useStyle()
  const { data, loading } = useGetTarget({ targetType, targetId })
  const [selectedTab, setSelectedTab] = useState<'pending' | 'completed'>(
    'pending'
  )

  if (loading) return <LoadingFullScreen />
  if (!data) return <EmptyList message={t('empty-item')} />

  return (
    <ScreenWrapper contentContainerStyle={styles.pageContainer}>
      <TargetTaskHeader
        target={data}
        targetType={targetType as MelddTaskTargetType}
      />
      <TaskListTabs
        targetId={targetId}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {/* 
      Manage task is not yet done
      {selectedTab === 'tasks' && (
        <TaskList
          fixedFilters={{ targetId, targetType }}
          filters={{ status: status || 'all', owner: 'all' }}
          groupedBy="target"
        />
      )} 
      */}
      {selectedTab === 'pending' && (
        <TaskContributorsByTarget
          fixedFilters={{
            targetId,
            targetType,
            completed: false,
            userId,
          }}
        />
      )}
      {selectedTab === 'completed' && (
        <TaskContributorsByTarget
          fixedFilters={{
            targetId,
            targetType,
            completed: true,
            userId,
          }}
        />
      )}
      <MButton
        targetId={targetId}
        targetType={targetType as MelddTaskTargetType}
        preview={''}
      />
    </ScreenWrapper>
  )
}

const useStyle = createStyle(({ theme }) => ({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    color: colors.grey600,
    marginTop: theme.spacing(1),
  },
}))

export default TaskListScreen
