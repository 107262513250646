import * as React from 'react'
import { FlatList, Keyboard, StyleProp, Text, View } from 'react-native'
import { Menu } from 'react-native-paper'
import { countryCodes, CountryItem } from './countryCodes'

interface CountryListProps
  extends Omit<
    React.ComponentProps<typeof FlatList<any>>,
    'data' | 'renderItem'
  > {
  lang?: string
  searchValue?: string
  pickerButtonOnPress?: (item: CountryItem) => void
}

export const CountryList = ({
  lang = 'en',
  searchValue = '',
  pickerButtonOnPress,
  ...rest
}: CountryListProps) => {
  const resultCountries = React.useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase()

    return countryCodes.filter((country) => {
      if (
        country?.dial_code.includes(searchValue) ||
        // Allow always to search by English name
        country?.name['en'].toLowerCase().includes(lowerSearchValue) ||
        country?.name[lang].toLowerCase().includes(lowerSearchValue)
      ) {
        return country
      }
    })
  }, [searchValue])

  const renderItem = ({
    item,
    index,
  }: {
    item: CountryItem
    index: number
  }) => {
    let itemName = item.name[lang]
    const dial_code = item.dial_code
    let checkName = itemName.length ? itemName : item.name['en']

    return (

      <Menu.Item
        style={{ maxWidth: '100%' }}
        title={
          <Text >
            <View style={{ minWidth: 50, width: '100%' }}>
              <Text>{dial_code}</Text>
            </View>
            <Text>{checkName}</Text>
          </Text>
        }
        onPress={() => {
          Keyboard.dismiss()
          typeof pickerButtonOnPress === 'function' && pickerButtonOnPress(item)
        }}
      />
    )
  }

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={resultCountries}
      keyExtractor={(item, index) => '' + item.code + index}
      initialNumToRender={10}
      maxToRenderPerBatch={10}
      keyboardShouldPersistTaps={'handled'}
      renderItem={renderItem}
      {...rest}
    />
  )
}
