import { io, Socket } from 'socket.io-client'
import { Env } from '../env'
import { Platform } from 'react-native'

const urlHost = new URL(Env.API_URL).host
const host = Platform.OS === 'web' ? urlHost : 'wss://' + urlHost
const _socket = io(host, {
  autoConnect: true,
  transports: ['websocket'],
})

/**
 * Socket Class
 */
export class SocketClient {
  public static KEY = 'test'
  private static socket: Socket = _socket
  public static init(auth: {
    token: string
    userId: string
    username: string
    anonymous?: boolean
  }) {
    this.socket.auth = auth

    this.socket.on('connect', () =>
      console.info('Socket: Connected', Platform.OS)
    )
    this.socket.on('disconnect', () =>
      console.info('Socket: disconnect', Platform.OS)
    )
    this.socket.on('error', (error) =>
      console.info('Socket: Error', Platform.OS, error)
    )
    this.socket.on('connect_error', (error) =>
      console.info('Socket: Connect Error', Platform.OS, error)
    )
    this.socket.on('connect_timeout', () =>
      console.info('Socket: Connect Timeout')
    )
    this.socket.connect()
  }

  public static disconnect() {
    if (!this.socket) {
      return
    }

    this.socket.disconnect()
  }

  public static get() {
    return this.socket as Socket
  }
}
