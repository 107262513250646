import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Text } from 'react-native-paper'
import HTML from 'react-native-render-html'

interface ValidationMessageProps {
  title: string
  html: string[]
}

export const HTMLErrorBox: React.FC<ValidationMessageProps> = ({
  title,
  html,
}) => {
  const { t } = useTranslation('contributions')
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(!!html.length)
  }, [html])

  if (!isVisible) {
    return null
  }
  return (
    <View style={styles.wrapper}>
      <Text style={styles.errorMessage}>{title}</Text>

      {html.map((html, index) => (
        <View style={styles.container} key={index}>
          <HTML source={{ html }} />
        </View>
      ))}

      <TouchableOpacity
        style={styles.closeButton}
        onPress={() => setIsVisible(false)}
      >
        <Text style={styles.closeButtonText}>{t('close')}</Text>
      </TouchableOpacity>
    </View>
  )
}

/**
 * TODO: We shall use standard color library
 * But GPT chosed those ones and looks really nice in deed
 */
const styles = StyleSheet.create({
  wrapper: {
    padding: 15,
  },
  container: {
    backgroundColor: '#ffeeba',
    borderColor: '#fff3cd',
    borderWidth: 1,
    borderRadius: 4,
    padding: 15,
    marginVertical: 10,
    color: '#696969',
    fontSize: 18,
  },
  errorMessage: {
    color: '#856404',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: '#856404',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 14,
  },
})
