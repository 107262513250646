import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { AppNavigatorParams } from '../../navigation/types'

export function VisualizationScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'Visualization'>) {
  return null
}
export default VisualizationScreen
