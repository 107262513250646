import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { IconAscending, IconDescending } from '../../../utils/meldd-icons'

interface SortSelectorProps {
  direction: 'asc' | 'desc'
  onDirection: (direction: 'asc' | 'desc') => void
}

const styles = StyleSheet.create({
  container: {},
})

export function SortSelector(props: SortSelectorProps) {
  const { direction, onDirection } = props
  const theme = useTheme()
  const { t } = useTranslation('common')
  return (
    <Button
      style={styles.container}
      onPress={() => onDirection(direction === 'asc' ? 'desc' : 'asc')}
      icon={direction === 'asc' ? IconAscending : IconDescending}
      textColor={theme.colors.primary}
    >
      {t('Date')}
    </Button>
  )
}
