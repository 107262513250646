import { useNavigation } from '@react-navigation/native'
import { debounce } from 'lodash'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions } from 'react-native'
import { DataTable, Icon, Menu, Searchbar, Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { AuthContext } from '../../../auth/auth-context'
import {
  ConfirmDialogue,
  ConfirmDialogueRef,
} from '../../../components/ConfirmDialogue'
import Row from '../../../components/containers/Row'
import { TitledTable } from '../../../components/TitledTable/TitledTable'
import {
  SearchUserQuery,
  TransferArticleOwnershipInput,
  useArticleQuery,
  useSearchUserLazyQuery,
  useTransferArticleOwnershipMutation,
} from '../../../generated/graphql'
import { useTheme } from '../../../hooks/use-theme'
import { AppNavigation } from '../../../navigation/types'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import { MessageType } from '../../../utils/message-type'
import { Unpacked } from '../../../utils/types'

interface Props {
  articleId: string
}

type ListUser = Unpacked<SearchUserQuery['usersBySearchUsername']>

const windowWidth = Dimensions.get('window').width

export function TransferOwnership(props: Props) {
  const { articleId } = props
  const { t } = useTranslation('articles')
  const theme = useTheme()

  const user = useContext(AuthContext)
  const navigation = useNavigation<AppNavigation>()

  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [searchResult, setSearchResult] = useState<ListUser[]>([])
  const [pendingUserId, setPendingUserId] = useState<string | null>(null)

  const confirmDialogueRef = useRef<ConfirmDialogueRef>(null)

  const [searchByUsername] = useSearchUserLazyQuery()
  const [transferArticleOwnership] = useTransferArticleOwnershipMutation()

  const { data: articleData } = useArticleQuery({
    variables: { id: articleId },
  })

  const clearInput = useCallback(() => {
    setShowSearch(false)
    setSearchResult([])
    setSearch('')
  }, [])

  const showConfirmDialogue = useCallback(
    (userId: string) => {
      setPendingUserId(userId)
      clearInput()
      confirmDialogueRef.current?.open()
    },
    [clearInput]
  )

  const handleConfirmTransfer = useCallback(async () => {
    if (!pendingUserId || !articleId) {
      return
    }
    const input: TransferArticleOwnershipInput = {
      articleId,
      newOwnerId: pendingUserId,
    }
    try {
      const result = await transferArticleOwnership({ variables: { input } })
      if (result.data) {
        Toast.show(t('transfer-success'), MessageType.info)
        navigation.navigate('Perspective', {
          articleId: articleId,
        })
      }
    } catch (err) {
      Toast.show(t('transfer-error'), MessageType.error)
    } finally {
      // Clean up state
      setPendingUserId(null)
      clearInput()
      confirmDialogueRef.current?.close()
    }
  }, [pendingUserId, articleId, transferArticleOwnership, clearInput, t])

  const handleSearch = useCallback(
    // Debounce to prevent too many API calls while typing
    debounce(async (input: string) => {
      try {
        setSearch(input)
        const trimmedInput = input.trim()
        if (trimmedInput.length < 1) {
          setSearchResult([])
          return
        }
        const query = await searchByUsername({
          variables: { username: trimmedInput.toLowerCase() },
          fetchPolicy: 'network-only',
        })
        setSearchResult(query.data?.usersBySearchUsername ?? [])
      } catch (err) {
        setSearchResult([])
      }
    }, 100),
    []
  )

  const transferCandidateList = useMemo(() => {
    if (!searchResult.length) {
      return null
    }
    return searchResult.map((item) => (
      <Menu.Item
        key={item.id}
        disabled={item.username === user.username}
        onPress={() => item.id && showConfirmDialogue(item.id)}
        title={item.username || ''}
      />
    ))
  }, [searchResult, articleData?.article.owner?.username, showConfirmDialogue])

  return (
    <>
      <TitledTable
        header={
          <Row justifyContent="space-between" flex={1}>
            <DataTable.Title>{t('transfer-ownership')}</DataTable.Title>
            <Menu
              visible={showSearch}
              style={{
                flex: 1,
                marginTop: theme.spacing(1),
                marginLeft: theme.spacing(4),
                width: windowWidth - 2 * theme.spacing(4),
              }}
              anchorPosition={'bottom'}
              onDismiss={() => {
                // Only dismiss if search isn't focused
                if (!isSearchFocused) {
                  setShowSearch(false)
                }
              }}
              anchor={
                <TouchableIconWithToolTip
                  icon={<Icon source="plus-circle-outline" size={20} />}
                  tooltip={t('add-member')}
                  onPress={() => setShowSearch(true)}
                />
              }
            >
              <Searchbar
                placeholder={t('search')}
                onChangeText={handleSearch}
                value={search}
                onBlur={() => setIsSearchFocused(false)}
                onFocus={() => setIsSearchFocused(true)}
              />
              {transferCandidateList}
            </Menu>
          </Row>
        }
      ></TitledTable>
      <ConfirmDialogue
        ref={confirmDialogueRef}
        onContinue={handleConfirmTransfer}
        label={t('transfer-warning-title')}
      >
        <Text>{t('transfer-warning')}</Text>
      </ConfirmDialogue>
    </>
  )
}

export default TransferOwnership
