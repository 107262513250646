export default {
  primary: '#6050dc',
  primaryInactive: '#acaada',
  primaryB: '#3e8ab0',
  accent: '#ee6f57',
  low: '#A6DCEF',
  screen: '#f2f2f2',
  primaryAccent: '#2d2d97',
  grey: '#DDDDDD',
  grey50: '#EBEEED',
  grey400: '#E2E2E3',
  grey200: '#c4c4c7',
  grey600: '#858686',
}
