import * as React from 'react'
import { View, Text } from 'react-native'
import WebView from 'react-native-webview'
interface ReadOnlyArticleProps {
  html: string
  width: number
}

export function ReadOnlyArticle(props: ReadOnlyArticleProps) {
  const { html } = props
  // language=CSS
  const customStyle = `
html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
body {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
}
#editor {
  margin: 0;
  color: #000;
    user-select: none;
}
p:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}
`
  // return <View><Text>This would have been perspective content - DEBUG</Text></View>
  return (
    <WebView
      style={{ opacity: 0.99 }}
      source={{
        html: `<html lang="en"><head><meta charset="UTF-8"/><meta name="viewport" content="width=${props.width}, user-scalable=0"/><title></title><style>${customStyle}</style></head><body><div id="editor">${html}</div></body></html>`,
      }}
      scalesPageToFit={false}
      scrollEnabled={true}
      showsVerticalScrollIndicator={true}
      textZoom={100}
    />
  )
}
