import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { DataTable } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import SingleCell from '../../../components/TitledTable/SingleCell'
import { ApolloError } from '@apollo/client'
import { NodeStatsQuery } from '../../../generated/graphql'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { TouchableRipple } from 'react-native-paper'

interface NodeCorankStatsProps {
  loading: boolean
  error: ApolloError | undefined
  users: NodeStatsQuery['nodeStats']['users']
}

export const NodeUserStats: React.FC<NodeCorankStatsProps> = ({
  loading,
  users,
  error,
}) => {
  const { t } = useTranslation('nodeStats')
  const navigation = useNavigation<AppNavigation>()

  if (loading) return <SingleCell message={''} />
  if (error) return <SingleCell key="0" message={t('Failed to load users')} />

  if (!users.length)
    return <SingleCell key="0" message={t('No Contributions')} />

  return (users || []).map((user, ix) => {
    const onPress = () => navigation.push('Profile', { userId: user.id })

    return (
      <Animated.View key={user.id} entering={FadeIn} exiting={FadeOut}>
        <TouchableRipple onPress={onPress}>
          <DataTable.Row>
            <DataTable.Cell>{user.username}</DataTable.Cell>
            <DataTable.Cell numeric>{ix + 1}</DataTable.Cell>
          </DataTable.Row>
        </TouchableRipple>
      </Animated.View>
    )
  })
}
