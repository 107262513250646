import { useTranslation } from 'react-i18next'
import MelddChipPicker from '../../../components/form/MelddChipPicker'
import Row from '../../../components/containers/Row'
import { useState } from 'react'
import { Text } from 'react-native-paper'
import { deleteUndefined } from '../../../utils/pagination'
import { TaskTypeFilter, TaskTypeFilterComponent } from './task.filter'

export type TaskContributionStatusFilter = 'all' | 'pending' | 'completed'

const StatusFilterMap: Record<
  TaskContributionStatusFilter,
  Record<string, unknown>
> = {
  all: {},
  completed: { completed: true },
  pending: { completed: false },
}

export type TaskContributionFilterDefaultValues = {
  status?: TaskContributionStatusFilter
  type?: TaskTypeFilter
}
export const getDefaultFilters = (
  defaultValues: TaskContributionFilterDefaultValues
) => {
  const filters = {
    status: defaultValues?.status
      ? StatusFilterMap[defaultValues.status] || StatusFilterMap['all']
      : undefined,
  }
  deleteUndefined(filters)
  return Object.values(filters).reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {}
  ) as Record<string, unknown>
}

export type TaskContributionFilterProps = {
  defaultValues: TaskContributionFilterDefaultValues
  fixedFilters: Record<string, unknown>
  onFilter: (value: Record<string, unknown>) => void
}

const StatusFilterComponent = ({
  enabled,
  onFilter,
  defaultValue,
}: {
  enabled: boolean
  defaultValue: TaskContributionStatusFilter
  onFilter: (value: Record<string, unknown>) => void
}) => {
  const { t } = useTranslation('tasks')
  const [selected, setSelected] =
    useState<TaskContributionStatusFilter>(defaultValue)
  const onSelect = (value: TaskContributionStatusFilter) => {
    setSelected(value)
    onFilter(StatusFilterMap[value])
  }

  if (!enabled) return null

  return (
    <Row columnGap={2}>
      <Text>{t('status')}</Text>
      <MelddChipPicker<TaskContributionStatusFilter>
        selected={selected}
        options={[
          { label: t('status.all'), value: 'all' },
          { label: t('status.pending'), value: 'pending' },
          { label: t('status.completed'), value: 'completed' },
        ]}
        onSelect={onSelect}
      />
    </Row>
  )
}

export const TaskContributionFilter = ({
  defaultValues,
  fixedFilters,
  onFilter,
}: TaskContributionFilterProps) => {
  const [currentFilters, setCurrentFilters] = useState({ status: {}, type: {} })

  const _onFilter = (key: string, filter: Record<string, unknown>) => {
    const updatedFilters = { ...currentFilters, [key]: filter }
    setCurrentFilters(updatedFilters)
    onFilter(
      Object.values(updatedFilters).reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      )
    )
  }
  return (
    <Row columnGap={10} alignItems={'center'}>
      <StatusFilterComponent
        enabled={typeof defaultValues?.status === 'string'}
        onFilter={(filter) => _onFilter('status', filter)}
        defaultValue={defaultValues?.status || 'all'}
      />
      <TaskTypeFilterComponent
        enabled={typeof defaultValues?.type === 'string'}
        onFilter={(filter) => _onFilter('type', filter)}
        defaultValue={defaultValues?.type || 'all'}
      />
    </Row>
  )
}
