import { Icon, IconButton, Text } from 'react-native-paper'
import { IconAssignTask } from '../../../utils/meldd-icons'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { TouchableOpacity, View } from 'react-native'
import { usePendingTasksQuery } from '../../../generated/graphql'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import { useAuthContext } from '../../../auth/auth-context'
import { SocketEvents, useSocketRoom } from '../../../socket/socket.hooks'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'

export const TaskPendingIcon = ({
  targetId,
  targetType,
  size = 20,
}: {
  targetId: string
  targetType: string
  size?: number
}) => {
  const { isAnonymous } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()
  const styles = useStyle()
  const { t } = useTranslation('tasks')
  const { data, refetch } = usePendingTasksQuery({
    variables: {
      // targetId, // Originally this goes to see the task of the specific item
      // But for now we just show if you have pending tasks in general
      targetId: undefined,
    },
    skip: isAnonymous,
  })
  useSocketRoom(targetId).event(SocketEvents.Refresh, refetch)

  const pendingTasksCount = data?.pendingTasks
    ? (data.pendingTasks.pendingTasks || 0) +
      (data.pendingTasks.pendingContributions || 0)
    : 0

  return (
    <View style={{ position: 'relative' }}>
      <TouchableIconWithToolTip
        mode="text"
        tooltip={t('tasks')}
        icon={<Icon size={size} source={IconAssignTask} />}
        onPress={() =>
          // Originally this goes to see the task of the specific item
          // navigation.navigate('TaskList', {
          //   targetId: targetId,
          //   targetType: targetType,
          // })
          // But for now we just show if you have pending tasks in general
          navigation.navigate('MyTasks', { tab: 'pending' })
        }
      />
      {pendingTasksCount > 0 && (
        <View style={styles.badge}>
          <Text style={styles.badgeText}>{pendingTasksCount}</Text>
        </View>
      )}
    </View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  badge: {
    position: 'absolute',
    top: 1,
    right: 1,
    backgroundColor: theme.colors.danger.main,
    borderRadius: 10,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
}))
