import * as React from 'react'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { View, StyleSheet, Text, Pressable } from 'react-native'
import { MdNode } from '../types'
import { Theme } from '../../../utils/theme'

interface NodeListItemProps {
  node: MdNode
  onNavigate: (node: MdNode) => void
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: Theme.padding['3'],
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: Theme.color.separator,
  },
  text: {
    flexDirection: 'column',
    flexGrow: 1,
    margin: 3,
    textAlignVertical: 'center',
    // borderWidth: 1,
    // borderColor: 'red'
  },
  title: {
    fontSize: 14,
  },
  description: {
    marginTop: 4,
    fontSize: 11,
  },
  icon: {
    alignSelf: 'center',
    // borderWidth: 1,
    // borderColor: 'red'
  },
})

export function NodeListItem({ node, onNavigate }: NodeListItemProps) {
  return (
    <Pressable style={styles.container} onPress={() => onNavigate(node)}>
      <View style={styles.text}>
        <Text style={styles.title}>
          {node.name}
          {node.isPrivate ? ' (Private)' : ''}
        </Text>
        {node.description && (
          <Text style={styles.description}>{node.description}</Text>
        )}
      </View>
      <View style={styles.icon}>
        <Icon name={'chevron-right'} size={Theme.size.icon} />
      </View>
    </Pressable>
  )
}
