import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useNodeStatsQuery } from '../../generated/graphql'
import { DataTable, IconButton, TouchableRipple } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import {
  createStyle,
  useGlobalStyles,
} from '../../contexts/GlobalStylesProvider'
import { TitledTable } from '../../components/TitledTable/TitledTable'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import SingleCell from '../../components/TitledTable/SingleCell'
import { CenteredAnimatedActivity } from '../../components/TitledTable/CenteredAnimatedActivity'
import { IconClusterSettings } from '../../utils/meldd-icons'
import { NodeStatsPerspectives } from './components/NodeStatsPerspectives'
import { NodeContributionsStats } from './components/NodeContributorsStats'
import { NodeUserStats } from './components/NodeUserStats'
import { useNode } from '../../contexts/CurrentNodeProvider'

export function NodeStatsScreen({
  route,
  navigation,
}: StackScreenProps<AppNavigatorParams, 'NodeStats'>) {
  const { nodeId } = route.params
  const { loading, error, data } = useNodeStatsQuery({
    variables: { input: { nodeId } },
    fetchPolicy: 'no-cache',
  })
  const { setCurrentNode } = useNode()

  const node = data?.nodeStats.node

  const { t } = useTranslation('nodeStats')
  const globalStyles = useGlobalStyles()
  const styles = useStyle()

  useEffect(() => {
    if (node) setCurrentNode(node.id)
  }, [nodeId])

  if (loading) {
    return (
      <View style={styles.fullPage}>
        <Text>{t('Loading')}</Text>
      </View>
    )
  }

  if (!node) return <SingleCell message={t('Node not found')} />

  return (
    <ScreenWrapper style={[globalStyles.pageContainer]}>
      <View style={{ marginBottom: 10 }}>
        <View style={styles.title}>
          <Text style={styles.mainTitle}>{node.name}</Text>
          {node?.isAdmin && (
            <IconButton
              style={styles.button}
              onPress={() =>
                navigation.navigate('NodeSettings', { nodeId: node.id })
              }
              icon={IconClusterSettings}
            />
          )}
        </View>

        <Text style={{ textAlign: 'center', fontSize: 18, fontWeight: '500' }}>
          {node.description}
        </Text>
      </View>
      <TitledTable
        title={t('Top Perspectives')}
        header={
          <>
            <DataTable.Title>{t('Title')}</DataTable.Title>
            <DataTable.Title numeric>{t('Votes')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          <NodeStatsPerspectives
            loading={loading}
            error={error}
            perspectives={data?.nodeStats.perspectives || []}
          />
        </View>
      </TitledTable>
      <View style={globalStyles.verticalSpacer} />
      <View style={globalStyles.verticalSpacer} />
      <TitledTable
        title={t('Top Contributions')}
        header={
          <>
            <DataTable.Title>{t('Contributions Type')}</DataTable.Title>
            <DataTable.Title numeric>{t('Net Bumps')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          <NodeContributionsStats
            loading={loading}
            error={error}
            contributions={data?.nodeStats.contributions}
          />
        </View>
      </TitledTable>
      <View style={globalStyles.verticalSpacer} />
      <View style={globalStyles.verticalSpacer} />
      <TitledTable
        title={t('CoRank')}
        header={
          <>
            <DataTable.Title>{t('Username')}</DataTable.Title>
            <DataTable.Title numeric>{t('Rank')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          <NodeUserStats
            loading={loading}
            error={error}
            users={data?.nodeStats.users || []}
          />
        </View>
      </TitledTable>
    </ScreenWrapper>
  )
}

export default NodeStatsScreen

const useStyle = createStyle(({ theme }) =>
  StyleSheet.create({
    title: {
      flex: 1,
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'center',
    },
    fullPage: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mainTitle: {
      textAlign: 'center',
      fontSize: 22,
      fontWeight: '700',
    },
    button: {
      marginTop: -theme.spacing(1.5),
      marginRight: -theme.spacing(1),
      justifyContent: 'center',
    },
  })
)
