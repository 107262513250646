import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import * as Clipboard from 'expo-clipboard'
import { url } from 'inspector'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { Button, Icon, IconButton, Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { useAuthContext } from '../../auth/auth-context'
import { EmptyList } from '../../components/containers/EmptyList'
import LoadingFullScreen from '../../components/containers/LoadingFullScreen'
import Row from '../../components/containers/Row'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import colors from '../../constants/colors'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { useGetTaskQuery } from '../../generated/graphql'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigation, AppNavigatorParams } from '../../navigation/types'
import { TouchableIconWithToolTip } from '../../utils/meldd-tooltip'
import { UserLink } from '../ClusterCards/components/ClickableClusterMember'
import { TaskActions } from './components/task-actions'
import { TaskContributorsByTask } from './components/task-contributors'
import { TaskStatus } from './components/task-status'
import { TaskTypeButton } from './components/task-type.button'
import { MelddTasks, navigateToTaskTarget, useGetTarget } from './task.types'

const TaskHeader = ({ task }: { task: MelddTasks }) => {
  const styles = useStyle()
  const navigation = useNavigation<AppNavigation>()
  const { t } = useTranslation('tasks')

  const copy = async () => {
    await Clipboard.setStringAsync(
      Platform.OS === 'web' ? location.href.split('#')[0] : ''
    )
    Toast.show(t('copied'))
  }

  return (
    <View style={styles.header}>
      <Row flex={1} columnGap={8} wrap>
        <View style={{ flex: 1, gap: 16 }}>
          <Row alignItems={'center'} columnGap={2}>
            <TaskStatus task={task} />
            <Text variant={'titleLarge'}>{task.title}</Text>
            <TouchableIconWithToolTip
              icon={<Icon source="content-copy" size={18} />}
              tooltip={t('copy')}
              onPress={copy}
            />
          </Row>
          <Row alignItems={'center'} style={styles.subtitle} columnGap={2}>
            {task.owner && (
              <>
                <UserLink user={task.owner} />
                <Text>{' •'}</Text>
              </>
            )}
            <TaskTypeButton task={task} />
          </Row>
          <Row>{t(`type.${task.type}`)}</Row>
        </View>
      </Row>
      <Row gap={2} alignItems={'center'}>
        <Button
          onPress={() => navigateToTaskTarget({ task, navigation })}
          mode="outlined">
          <Text>{t('view')}</Text>
        </Button>
      </Row>
    </View>
  )
}

const TaskContributions = ({ task }: { task: MelddTasks }) => {
  const { userId } = useAuthContext()
  // Owner can see all the contributions
  if (task.owner.id === userId)
    return <TaskContributorsByTask fixedFilters={{ taskId: task.id }} />
  // Other users already got their own info, no need to display anything

  return null
}
export function TaskDetail({
  route,
}: StackScreenProps<AppNavigatorParams, 'Task'>) {
  const taskId = route.params.taskId || ''
  const { t } = useTranslation('tasks')
  const { loading, data } = useGetTaskQuery({
    variables: {
      taskId,
    },
    skip: taskId === '',
  })

  const task = data?.getTask

  useWechatShareInit(() => {
    if (!task) return null
    return {
      title: task.title,
      description: `${t(`type.${task.type}`)}!`,
    }
  }, [task])

  if (loading) {
    return <LoadingFullScreen />
  }

  if (!task) {
    return <EmptyList message={t('task-not-found')} />
  }

  return (
    <ScreenWrapper>
      <TaskHeader task={task} />
      <TaskActions task={task} />
      <View style={{ margin: 4 }} />
      {!task.assignedId && <TaskContributions task={task} />}
      {/* // TODO: When having assignees show the assignee information here */}
    </ScreenWrapper>
  )
}

const useStyle = createStyle(({ theme }) => ({
  contributeButton: {
    marginTop: theme.spacing(2),
  },
  contributeListItem: {
    paddingVertical: theme.spacing(2),
    paddingHorizontal: theme.spacing(4),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(6),
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.border,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    color: colors.grey600,
    marginTop: theme.spacing(1),
  },
  centerContainer: {
    flex: 1,
    marginVertical: theme.spacing(6),
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default TaskDetail
