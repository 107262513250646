import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { List, ListItemProps } from 'react-native-paper'
import { useTheme } from '../../hooks/use-theme'

export const MelddListItem = (
  props: Exclude<ListItemProps, ['style', 'titleStyle']>
) => {
  const styles = useStyle()
  return (
    <List.Item
      {...props}
      style={styles.listItem}
      titleStyle={styles.titleStyle}
    />
  )
}

const useStyle = () => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        titleStyle: {},
        listItem: {
          padding: 0,
          backgroundColor: theme.colors.background,
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.surfaceVariant,
          paddingRight: theme.spacing(5),
        },
      }),
    [theme]
  )
}
