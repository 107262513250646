import * as Clipboard from 'expo-clipboard'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button, Dialog, IconButton, Portal, Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'

type ShareModalProps = {
  url: string
}

export type ShareModalRef = {
  open: () => void
  close: () => void
}

const CopyLinkButton = (props: ShareModalProps) => {
  const { t } = useTranslation('common')
  const copyToClipboard = async () => {
    Clipboard.setStringAsync(props.url)
    Toast.show(t('Success'))
  }

  return <IconButton mode="outlined" icon={'link'} onPress={copyToClipboard} />
}

export const ShareModal = forwardRef<ShareModalRef, ShareModalProps>(
  (props: ShareModalProps, ref) => {
    const { t } = useTranslation('share')
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }))

    return (
      <>
        <IconButton size={20} icon="share" onPress={() => setOpen(true)} />
        <Portal>
          <Dialog
            visible={open}
            onDismiss={() => setOpen(false)}
            dismissable={true}
            dismissableBackButton={true}>
            <View style={styles.modalWrapper}>
              <Dialog.Title>
                <Text variant="bodyMedium">{t('Share')}</Text>
              </Dialog.Title>
              <Dialog.Content>
                <View style={styles.row}>
                  <CopyLinkButton {...props} />
                </View>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={() => setOpen(false)}>{t('Cancel')}</Button>
              </Dialog.Actions>
            </View>
          </Dialog>
        </Portal>
      </>
    )
  }
)

const styles = StyleSheet.create({
  title: { fontSize: 24, fontWeight: '600' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
  },
  modalWrapper: {
    borderRadius: 4,
    margin: 32,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
