import { Platform } from 'react-native'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Subscription } from 'expo-modules-core'

import React, { useEffect, useRef, useState } from 'react'
import { useRegisterPushTokenMutation } from '../generated/graphql'
import { MessageType } from '../utils/message-type'
import Toast from 'react-native-root-toast'
import { useFeatures } from './FeatureProvider'

interface PushTokenProviderProps {
  children: React.ReactNode
}

async function registerForPushNotificationsAsync(developerMode: boolean) {
  let token
  let deviceToken
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      return
    }
    if (developerMode) {
      try {
        deviceToken = await Notifications.getDevicePushTokenAsync()
        Toast.show(
          `Device token succeeded: ${deviceToken.data}`,
          MessageType.info
        )
      } catch (e) {
        Toast.show(
          `Failed to get device token ${(e as Error).message}`,
          MessageType.error
        )
      }
    }
    token = (await Notifications.getExpoPushTokenAsync()).data
    console.log(token)
  }
  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  return token
}

const PushTokenContext = React.createContext<string | undefined>(undefined)
export function PushTokenProvider(props: PushTokenProviderProps) {
  const { developerMode } = useFeatures()
  const [pushToken, setPushToken] = useState<string>()
  const [doRegisterPushToken] = useRegisterPushTokenMutation()
  const notificationListener = useRef<Subscription>()
  const responseListener = useRef<Subscription>()

  const register = async () => {
    try {
      const token = await registerForPushNotificationsAsync(developerMode)
      if (token) {
        setPushToken(token)

        try {
          const result = await doRegisterPushToken({
            variables: { input: { token } },
          })
        } catch (e) {
          if (developerMode) {
            Toast.show((e as Error).message, MessageType.error)
          }
        }
      }
    } catch (e) {
      if (developerMode) {
        Toast.show((e as Error).message, MessageType.error)
      }
    }
  }
  useEffect(() => {
    register().then() //.catch(e => Toast.show((e as Error).message, MessageType.error))
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        console.log('NotificationReceived', notification)
        const { data } = notification.request.content
        if ('pendingCount' in data) {
          console.log('Setting badge count to', data.pendingCount)
          Notifications.setBadgeCountAsync(data.pendingCount as number)
        }
      })

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current!
        )
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current!)
      }
    }
  }, [developerMode])
  return (
    <PushTokenContext.Provider value={pushToken} children={props.children} />
  )
}

export function usePushToken() {
  return React.useContext(PushTokenContext)
}
