import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, IconButton, List, Portal, Text } from 'react-native-paper'
import { useTheme } from '../../hooks/use-theme'
import { FlatList, StyleSheet, View } from 'react-native'
import { IconClose } from '../../utils/meldd-icons'
import { AppNavigation } from '../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { ArticleVote } from './types'

type VoteDetailsProviderContextShape = (toShow: ArticleVote[] | null) => void

export const VoteDetailsProviderContext =
  createContext<VoteDetailsProviderContextShape>(() => { })

type VoteDetailsProviderProps = {
  children: ReactNode
}
export const VoteDetailsProvider = ({ children }: VoteDetailsProviderProps) => {
  const [votes, setVotes] = React.useState<ArticleVote[] | null>(null)
  const { t } = useTranslation('bumps')
  const navigation = useNavigation<AppNavigation>()

  const theme = useTheme()
  const value = React.useCallback((toShow: ArticleVote[] | null) => {
    setVotes(toShow)
  }, [])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
          borderRadius: 4,
          padding: theme.spacing(0),
        },
        title: {},
        header: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(0),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: theme.spacing(2),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        content: {
          paddingHorizontal: 0,
          paddingBottom: 0,
        },
        heading: {
          flex: 1,
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          paddingHorizontal: theme.spacing(2),
        },
        user: {
          color: theme.colors.primary,
        },
        listItem: {
          padding: 0,
          backgroundColor: theme.colors.background,
          alignItems: 'center',
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.surfaceVariant,
          paddingRight: theme.spacing(5),
        },
        footer: {
          flexDirection: 'row',
          // margin: theme.spacing(2),
          padding: theme.spacing(4),
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        summary: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(1),
          columnGap: theme.spacing(2),
        },
      }),
    [theme]
  )
  const renderItem = ({ item }: { item: ArticleVote }) => {
    return (
      <List.Item
        onPress={() => {
          setVotes(null)
          navigation.navigate('Profile', { userId: item.userId })
        }}
        style={styles.listItem}
        title={item.user.username}
        titleStyle={styles.user}
        description={item.justification}
      />
    )
  }
  return (
    <VoteDetailsProviderContext.Provider value={value}>
      {children}
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={!!votes}
          onDismiss={() => setVotes(null)}
        >
          <View style={styles.header}>
            <Text variant={'titleMedium'} style={styles.heading}>
              {t('Current votes:')}
            </Text>
            <IconButton icon={IconClose} onPress={() => setVotes(null)} />
          </View>
          <Dialog.Content style={styles.content}>
            <FlatList
              data={votes}
              renderItem={renderItem}
              keyExtractor={(item) => item.userId}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </VoteDetailsProviderContext.Provider>
  )
}

export const useVoteDetailsProvider = () => {
  return useContext(VoteDetailsProviderContext)
}
