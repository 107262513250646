import * as React from 'react'
import { Appbar } from 'react-native-paper'
import { getHeaderTitle } from '@react-navigation/elements'
import {
  StackHeaderProps,
  StackNavigationProp,
  StackScreenProps,
} from '@react-navigation/stack'
import { AppNavigatorParams } from '../navigation/types'
import { useTheme } from '../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import LanguageButtonMenu from './LanugageButtonMenu'
import { extractReturnPath, goAfterLogin } from '../screens/Login/utils'
import { getReturnPath } from '../screens/Login/login-required.modal'
import { RouteProp } from '@react-navigation/native'

export interface LoginNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function LoginNavigationBar({
  navigation,
  route,
  options,
}: LoginNavigationBarProps) {
  const theme = useTheme()
  const { t } = useTranslation('screens')
  const title = t(getHeaderTitle(options, route.name))
  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      <Appbar.BackAction
        onPress={() =>
          goAfterLogin({
            navigation,
            returnPath:
              extractReturnPath(route as RouteProp<AppNavigatorParams>) ?? '',
          })
        }
      />
      <Appbar.Content title={title} />
      <LanguageButtonMenu />
    </Appbar.Header>
  )
}

export function RegisterNavigationBar({
  navigation,
  route,
  options,
}: LoginNavigationBarProps) {
  const theme = useTheme()
  const { t } = useTranslation('screens')
  const title = t(getHeaderTitle(options, route.name))
  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      <Appbar.BackAction onPress={() => navigation.goBack()} />
      <Appbar.Content title={title} />
      <LanguageButtonMenu />
    </Appbar.Header>
  )
}
