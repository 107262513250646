import * as React from 'react'
import {
  StyleProp,
  TextStyle,
  View,
  Text,
  ViewStyle,
  StyleSheet,
} from 'react-native'
import { useTheme } from '../hooks/use-theme'

interface LabeledItemProps {
  label: string
  wrapperStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  onLabelPress?: () => void
  children: React.ReactNode | React.ReactNode[]
}

export function LabeledItem(props: LabeledItemProps) {
  const theme = useTheme()
  const styles = StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    label: {
      color: theme.colors.text,
      fontSize: theme.fonts.labelLarge.fontSize,
    },
  })
  const { wrapperStyle, label, labelStyle, children } = props
  return (
    <View style={[styles.wrapper, wrapperStyle]} >
      <Text style={[styles.label, labelStyle]} onPress={() => props.onLabelPress?.()}>{label}</Text>
      {children}
    </View>
  )
}
