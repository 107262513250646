import { RouteProp } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from './../../navigation/types'

const AUTH_PAGES = ['Register', 'LoginMobileNumber', 'Login']
export const goAfterLogin = ({
  navigation,
  returnPath,
}: {
  navigation: StackScreenProps<AppNavigatorParams>['navigation']
  returnPath: string
}) => {
  const prevScreen = navigation.getState().routes.slice(-2, -1)[0]
  if (returnPath) {
    const [name, queryString] = returnPath.split('?')

    const params = Object.fromEntries(new URLSearchParams(queryString))
    // Check if is actually the prev screen, if is like that we can go back, the state will persist

    if (prevScreen?.name === name && navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    // If is not  (we navigate through auth), we just load the screen again
    navigation.replace(name as keyof AppNavigatorParams, params || {})
    return
  }
  if (navigation.canGoBack() && !AUTH_PAGES.includes(prevScreen?.name)) {
    navigation.goBack()
    return
  }
  // Before was ClusterList, but if they have no access (private node) this go back will end in a loop
  navigation.replace('ClusterList', {})
}

export const extractReturnPath = (obj: RouteProp<AppNavigatorParams>) => {
  const { params, path } = obj
  let returnPath = ''

  // If path is defined, extract returnPath using the updated regex
  if (path) {
    const returnPathStart = path.indexOf('returnPath=') + 'returnPath='.length
    const returnPathEnd = path.indexOf('&code', returnPathStart)

    if (returnPathStart !== -1 && returnPathEnd !== -1) {
      return path.substring(returnPathStart, returnPathEnd)
    } else {
      return null
    }
  } else if (params && params.returnPath) {
    // If path is undefined, use the returnPath from params
    returnPath = params.returnPath
  }

  // Return the returnPath if found
  if (returnPath) {
    return returnPath
  }

  return null
}
