import { Dispatch } from 'react'
import { Env } from '../env'
import { useWechatSignatureMutation } from '../generated/graphql'
import wx from '../wx.jssdk'

const WECHAT_CONSTANTS = {
  SCRIPT_URL: 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js',
  DEFAULT_SHARE_IMAGE: 'https://app.meldd.cn/static/media/meldd.jpg',
  API_LIST: ['updateAppMessageShareData'] as wx.jsApiList,
}

export type WechatSDK = typeof import('../wx.jssdk').default

export type WechatShareData = {
  title: string
  description: string
}

type WechatShareProps = {
  sign: ReturnType<typeof useWechatSignatureMutation>[0]
  url: string
}

type WechatWindow = Window & {
  wx?: typeof wx
}

export const wechatShare = {
  init: async ({ sign, url }: WechatShareProps) => {
    const wechatWindow = window as WechatWindow

    const loadScript = () => {
      return new Promise<void>((resolve, reject) => {
        // Check if script is already loaded
        if (wechatWindow.wx) {
          resolve()
          return
        }

        const ele = document.createElement('script')
        ele.type = 'text/javascript'
        ele.async = true
        ele.src = WECHAT_CONSTANTS.SCRIPT_URL
        ele.onload = () => resolve()
        ele.onerror = () => reject('Error occurred while loading script')
        document.head.appendChild(ele)
      })
    }

    const configSDK = async () => {
      // Early return if wx is not available
      if (!wechatWindow.wx) {
        throw new Error('jweixin not defined')
      }

      const wx = wechatWindow.wx

      try {
        const result = await sign({
          variables: {
            input: {
              url: encodeURIComponent(url),
            },
          },
        })
        if (!result.data) {
          throw new Error('Failed to get signature data')
        }

        const wechatSignature = result.data.wechatSignature
        if (!wechatSignature) {
          throw new Error('WeChat signature is undefined')
        }

        wx.config({
          debug: Env.IS_DEVELOP, // Only enable debug in development
          appId: Env.WECHAT_PUBLIC_ACCOUNT_ID,
          timestamp: Number(wechatSignature.timestamp),
          nonceStr: wechatSignature.nonceStr,
          signature: wechatSignature.signature,
          jsApiList: WECHAT_CONSTANTS.API_LIST,
        })

        return await new Promise<WechatSDK>((resolve, reject) => {
          wx.ready(() => resolve(wx))
          wx.error((res) => reject(res.errMsg))
        })
      } catch (err) {
        throw err
      }
    }

    try {
      await loadScript()
      return await configSDK()
    } catch (err) {
      console.error('WeChat JSSDK initialization failed:', { error: err, url })
      return null
    }
  },
  updateData: async (
    wx: WechatSDK,
    { title, description }: WechatShareData,
    url: string
  ): Promise<boolean> => {
    if (!title || !description || !url || !wx) {
      return false
    }

    return new Promise((resolve) =>
      wx.updateAppMessageShareData({
        title,
        desc: description,
        link: url,
        imgUrl: WECHAT_CONSTANTS.DEFAULT_SHARE_IMAGE,
        success: () => resolve(true),
        fail: () => resolve(false),
      })
    )
  },
  createMock: (setWx: Dispatch<WechatSDK | null>) => {
    return {
      isReady: true,
      canUse: false,
      setWx,
      updateShareData: async () => false,
    }
  },
}
