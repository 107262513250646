import { Button, IconButton } from 'react-native-paper'
import { IconImport } from '../../../utils/meldd-icons'
import { useTranslation } from 'react-i18next'
import { importFile } from '../../../utils/import-utils'
import { useAuthContext } from '../../../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { useWindowDimensions } from 'react-native'

export const ImportButton = ({ clusterId }: { clusterId: string }) => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation('clusters')
  const { authAction } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()
  if (!clusterId) {
    return null
  }
  return width > 400 ? (
    <Button
      mode={'outlined'}
      icon={IconImport}
      onPress={() => {
        authAction(navigation, () => importFile(navigation, clusterId))
      }}
    >
      {t('Import')}
    </Button>
  ) : (
    <IconButton
      mode="contained"
      icon={IconImport}
      onPress={() => {
        authAction(navigation, () => importFile(navigation, clusterId))
      }}
    />
  )
}
