import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Chip, Menu } from 'react-native-paper'
import { Text } from 'react-native'
import { IconNode } from '../../../utils/meldd-icons'
import { ContributionType } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'

interface NodeSelectorProps {
  selected?: ContributionType
  onSelect: (value?: ContributionType) => void
}

const types = [
  { name: 'ALL', value: undefined },
  { name: ContributionType.Edit, value: ContributionType.Edit },
  { name: ContributionType.Challenge, value: ContributionType.Challenge },
  { name: ContributionType.Inquiry, value: ContributionType.Inquiry },
]

export default function ContributionTypeSelector(props: NodeSelectorProps) {
  const [visible, setVisible] = React.useState(false)
  const { t } = useTranslation('contributions')
  const onSelection = useCallback(
    (status?: ContributionType) => {
      setVisible(false), props.onSelect(status)
    },
    [props.onSelect]
  )
  const menuItems = useMemo(
    () =>
      types.map((statusItem) => (
        <Menu.Item
          key={statusItem.name}
          onPress={() => onSelection(statusItem.value)}
          title={t(statusItem.name)}
        />
      )),
    [t]
  )
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Chip
          mode="outlined"
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => setVisible((prev) => !prev)}
          onClose={() => setVisible((prev) => !prev)}
        >
          <Text>{t(props.selected || 'ALL')}</Text>
        </Chip>
      }
    >
      {menuItems}
    </Menu>
  )
}
