import AsyncStorage from '@react-native-async-storage/async-storage'
import { Env } from '../env'

export const FEATURES_KEY = 'FeatureToggles'

export interface Features {
  showEditorLogs: boolean
  ownerContributions: boolean
  ownerBumps: boolean
  developerMode: boolean
  theme: 'light' | 'dark'
}

export const DEFAULT_FEATURES: Features = {
  showEditorLogs: false,
  ownerContributions: false,
  ownerBumps: false,
  developerMode: false,
  theme: 'light',
}
export const featureLoader: Promise<Features> = AsyncStorage.getItem(
  FEATURES_KEY
).then((str) => {
  console.log('FeatureProvider:loading stored features')
  let features: Features
  if (str) {
    console.log('FeatureProvider:found stored features', str)
    try {
      features = { ...DEFAULT_FEATURES, ...JSON.parse(str) }
    } catch (e) {
      features = DEFAULT_FEATURES
    }
  } else {
    console.log('FeatureProvider:no stored features')
    features = DEFAULT_FEATURES
  }
  return features
})
