import { useNavigation } from '@react-navigation/native'
import { Env } from '../../env'
import {
  MelddTask as GraphMeldTasks,
  TasksContributor,
  useArticleQuery,
  useClusterByIdQuery,
  useContributionByIdQuery,
  useNodeByIdQuery,
} from '../../generated/graphql'
import { AppNavigation } from '../../navigation/types'
import { Article, Contribution } from '../Perspective/types'
import { Cluster } from '../ClusterList/types'
import { MdNode } from '../ListNodes/types'

export type MelddTaskTarget = Article | Contribution | Cluster | MdNode
export enum MelddTaskTargetType {
  CLUSTER_TASK = 'cluster',
  ARTICLE_TASK = 'article',
  NODE_TASK = 'node',
  CONTRIBUTION_TASK = 'contribution',
}
export type MelddTaskContribution = TasksContributor  
export enum MelddTaskTypes {
  VOTE_CONTRIBUTION = 'vote_contribution', // Task to request a bump a specific contribution
  VOTE_PERSPECTIVE = 'vote_perspective',
  APPROVE = 'approve',
  REJECT = 'reject',
  CONTRIBUTE = 'contribute',
  CREATE_PERSPECTIVE = 'create_perspective',
  CREATE_CLUSTER = 'create_cluster',
  BUMP = 'bump', // Task to request a bump in any a open contribution
}

type TargetTasks = {
  [key in MelddTaskTargetType]: MelddTaskTypes[]
}

export const TargetTypeTasks: TargetTasks = Object.freeze({
  [MelddTaskTargetType.ARTICLE_TASK]: [
    MelddTaskTypes.CONTRIBUTE,
   ],
  [MelddTaskTargetType.CONTRIBUTION_TASK]: [
    MelddTaskTypes.APPROVE,
    MelddTaskTypes.REJECT,
    MelddTaskTypes.BUMP,
  ],
  [MelddTaskTargetType.CLUSTER_TASK]: [
    MelddTaskTypes.CREATE_PERSPECTIVE,
    MelddTaskTypes.VOTE_PERSPECTIVE,
  ],
  [MelddTaskTargetType.NODE_TASK]: [
    MelddTaskTypes.CREATE_CLUSTER,
  ],
})

export type MelddTasks = GraphMeldTasks

export function getTaskUrl(task: MelddTasks) {
  return `${Env.BASE_URL}/task/${task.title
    .replaceAll(' ', '-')
    .replace(/[^a-zA-Z0-9-]/g, '')}-${task.id.replaceAll('-', '').substring(0, 16)}`
}

export function getTargetLink(targetType: string, targetId: string) {
  const urls: Record<MelddTaskTargetType, string> = {
    [MelddTaskTargetType.ARTICLE_TASK]: '',
    [MelddTaskTargetType.NODE_TASK]: '',
    [MelddTaskTargetType.CLUSTER_TASK]: '',
    [MelddTaskTargetType.CONTRIBUTION_TASK]: '',
  }
}
export function useGetTarget({ targetType, targetId }: { targetType: string, targetId: string }): {loading: boolean, data: MelddTaskTarget | undefined | null} {
  if (targetType === MelddTaskTargetType.ARTICLE_TASK) {
    const { data, loading } = useArticleQuery({ variables: { id: targetId } })
    return { data: data?.article, loading }
  }

  // TODO this shall be via ID
  if (targetType === MelddTaskTargetType.NODE_TASK){
    const { data, loading  } = useNodeByIdQuery({ variables: { id: targetId } })
    return { data: data?.nodeById, loading }
    }

  if (targetType === MelddTaskTargetType.CONTRIBUTION_TASK){
    const { data, loading } = useContributionByIdQuery({ variables: { contributionId: targetId } })
    return { data: data?.contributionById as MelddTaskTarget, loading }
  }

  if (targetType === MelddTaskTargetType.CLUSTER_TASK){
    const {data, loading} = useClusterByIdQuery({ variables: { id: targetId } })
    return { data: data?.clusterById as MelddTaskTarget, loading }
  }

  console.error('useGetTarget must be used with a valid MelddTaskTargetType')
  return { data: null, loading: false }
}

export function getTargetDetail({ targetType, target }: { targetType: MelddTaskTargetType, target: MelddTaskTarget }) {
  if (targetType === MelddTaskTargetType.ARTICLE_TASK) {
    const article = target as Article
    return { id: article.id, title: article.title, type: 'article' }
  }
  if (targetType === MelddTaskTargetType.NODE_TASK) {
    const node = target as MdNode
    return { id: node.id, title: node.name, type: 'node' }
  }
  if (targetType === MelddTaskTargetType.CONTRIBUTION_TASK) {
    const contribution = target as Contribution
    return { id: contribution.id, title: contribution.input || contribution.response, type: 'contribution' }
  }
  if (targetType === MelddTaskTargetType.CLUSTER_TASK) {
    const cluster = target as Cluster
    return { id: cluster.id, title: cluster.topic, type: 'cluster' }
  }
  return { id: '', title: '', type: '' }
}


export function navigateToTaskTarget({
  task,
  navigation,
}: {
  task: { targetType: MelddTaskTargetType, targetId: string }
  navigation: ReturnType<typeof useNavigation<AppNavigation>>
}) {
  if (task.targetType === MelddTaskTargetType.ARTICLE_TASK)
    return navigation.navigate('Perspective', {
      articleId: task.targetId,
    })

  if (task.targetType === MelddTaskTargetType.NODE_TASK) {
    return navigation.navigate('ClusterList', {
      nodeId: task.targetId,
    })
  }

  if (task.targetType === MelddTaskTargetType.CONTRIBUTION_TASK) {
    console.info('TODO, Contribution id Page not implemented')
    // return navigation.navigate('Contribution', {
    //   id: task.targetId,
    // })
    return navigation.navigate('Contributions')
  }
  if (task.targetType === MelddTaskTargetType.CLUSTER_TASK) {
    return navigation.navigate('ClusterCards', {
      clusterId: task.targetId,
    })
  }

  throw new Error('useGetTarget must be used with a valid MelddTaskTargetType')
}




