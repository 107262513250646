import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'
import {
  ActivityIndicator,
  Button,
  Dialog,
  Portal,
  Text,
  useTheme,
} from 'react-native-paper'
import { isMobileBrowser } from '../../utils/wechat.login'

type WechatQRModalProps = {
  visible: boolean
  loading: boolean
  onDismiss: () => void
}

/**
 * Display Wechat QR for web login
 */
export const WechatQRModal = ({
  visible,
  loading,
  onDismiss,
}: WechatQRModalProps) => {
  const { t } = useTranslation('login_register_reset')
  const theme = useTheme()

  return (
    <>
      <Portal>
        <Dialog
          style={styles.modalWrapper}
          visible={visible}
          onDismiss={() => onDismiss()}
          dismissable={true}
          dismissableBackButton={true}>
          <View style={styles.modalContent}>
            {isMobileBrowser() ? (
              <Dialog.Title>
                <Text variant="bodySmall">{t('Wechat Login')}</Text>
              </Dialog.Title>
            ) : null}
            <Dialog.Content>
              {Platform.OS === 'web' ? (
                <>
                  {loading && (
                    <View style={styles.spinner}>
                      <ActivityIndicator
                        size="large"
                        color={theme.colors.primary}
                      />
                    </View>
                  )}
                  <View id="wechat-login-container" />
                </>
              ) : (
                <ActivityIndicator size="small" color={theme.colors.primary} />
              )}
            </Dialog.Content>
            <Dialog.Actions style={{ paddingVertical: 0 }}>
              <Button onPress={() => onDismiss()}>{t('Cancel')}</Button>
            </Dialog.Actions>
          </View>
        </Dialog>
      </Portal>
    </>
  )
}
const styles = StyleSheet.create({
  title: { fontSize: 24, fontWeight: '600' },
  spinner: { marginTop: 24 },
  modalWrapper: {
    width: isMobileBrowser() ? 'auto' : 518,
    marginHorizontal: 'auto',
  },
  modalContent: {
    borderRadius: 4,
    margin: 24,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
