import * as React from 'react'
import { Text, StyleSheet, Switch, View } from 'react-native'
import { Theme } from '../utils/theme'
import { AppNavigatorParams } from '../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { useFeatures } from '../contexts/FeatureProvider'
import { LabeledSwitch } from '../components/LabeledSwitch'
import { IconButton } from 'react-native-paper'
import * as Sentry from '@sentry/react-native'
import { LabeledItem } from '../components/LabeledItem'
import { MessageType } from '../utils/message-type'
import Toast from 'react-native-root-toast'
import { Env } from '../env'
import { useTranslation } from 'react-i18next'
import { useSocketStatus } from '../socket/socket.hooks'
export function FeatureTogglesScreen(
  props: StackScreenProps<AppNavigatorParams, 'FeatureToggles'>
) {
  const {
    setShowEditorLogs,
    showEditorLogs,
    ownerContributions,
    setOwnerContributions,
    developerMode,
    setDeveloperMode,
    setOwnerBumps,
    ownerBumps,
  } = useFeatures()
  const { t } = useTranslation('menuItems')
  const status = useSocketStatus()

  /**
   * Abel July 2024
   * I hide some dev options, to enable then we do like on android, tab 5 times specific label :D
   * It can be improved and keep it in memory, we have the `dev` mode but is open to anyone and crashed in web.
   * I keep this like that for quick short cut.
   */
  const [secretCount, setSecretCount] = React.useState(0)
  return (
    <View style={styles.container}>
      {Env.IS_DEVELOP && (
        <>
          <View style={styles.row}>
            <LabeledSwitch
              value={showEditorLogs}
              label="Show Editor Logs"
              labelStyle={{ color: 'black', flex: 1 }}
              onValueChange={setShowEditorLogs}
            />
          </View>
          <View style={styles.row}>
            <LabeledSwitch
              value={ownerContributions}
              label="Allow Owner Contributions"
              labelStyle={{ color: 'black', flex: 1 }}
              onValueChange={setOwnerContributions}
            />
          </View>
          <View style={styles.row}>
            <LabeledSwitch
              value={ownerBumps}
              label="Allow Owner Bumps"
              labelStyle={{ color: 'black', flex: 1 }}
              onValueChange={setOwnerBumps}
            />
          </View>

          <View style={styles.row}>
            <LabeledSwitch
              value={developerMode}
              label="Developer Mode"
              labelStyle={{ color: 'black', flex: 1 }}
              onValueChange={setDeveloperMode}
            />
          </View>
        </>
      )}
      <View style={styles.row}>
        <LabeledItem
          label={t('Status')}
          labelStyle={{ color: 'black', flex: 1 }}
          onLabelPress={() => setSecretCount((count) => count + 1)}
        >
          <Text style={{ color: status === 'connected' ? undefined : '#aaa' }}>
            {status === 'connected' ? t('Online') : t('Offline')}{' '}
          </Text>
        </LabeledItem>
      </View>
      <View style={styles.row}>
        <LabeledItem
          label={t('Version')}
          labelStyle={{ color: 'black', flex: 1 }}
          onLabelPress={() => setSecretCount((count) => count + 1)}
        >
          <Text>
            {Env.VERSION} {Env.BRANCH_NAME ? `(${Env.BRANCH_NAME})` : ''}
          </Text>
        </LabeledItem>
      </View>
      {secretCount > 4 && (
        <View style={styles.row}>
          <LabeledItem
            label={'Test Sentry'}
            labelStyle={{ color: 'black', flex: 1 }}
          >
            <IconButton
              style={{ margin: 0 }}
              size={20}
              icon={'bug'}
              onPress={() => {
                if (!Env.SENTRY_SDN) {
                  Toast.show(
                    'Sentry not enabled (develop mode is disable by default)',
                    MessageType.warn
                  )
                  return
                }
                Sentry.captureException(new Error('First error'))
                Toast.show('Error sent', MessageType.info)
              }}
            />
          </LabeledItem>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  row: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderColor: Theme.color.separator,
    borderBottomWidth: 1,
    alignItems: 'stretch',
  },
})
export default FeatureTogglesScreen
