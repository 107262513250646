import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { TouchableRipple, DataTable } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { NodeStatsQuery } from '../../../generated/graphql'
import SingleCell from '../../../components/TitledTable/SingleCell'
import { AppNavigation } from '../../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { MultiLineCell } from '../../../components/TitledTable/MultiLineCell'

interface NodeContributionsStatsProps {
  loading: boolean
  error?: any
  contributions?: NodeStatsQuery['nodeStats']['contributions']
}

export const NodeContributionsStats = ({
  loading,
  error,
  contributions,
}: NodeContributionsStatsProps) => {
  const { t } = useTranslation('nodeStats')
  const navigation = useNavigation<AppNavigation>()

  if (loading) {
    return <SingleCell message={''} />
  }
  if (error) return <SingleCell message={t('Failed to load contributions')} />

  if (!contributions?.length)
    return <SingleCell message={t('No Contributions')} />

  return (contributions || []).map((contribution) => {
    const onPress = () =>
      navigation.push('ListContributionsProfile', {
        contributions: [contribution],
        type: t(''),
      })
    return (
      <Animated.View key={contribution.id} entering={FadeIn} exiting={FadeOut}>
        <TouchableRipple onPress={onPress}>
          <DataTable.Row>
            <MultiLineCell
              title={contribution.originalText}
              description={contribution.contributionType}
            />
            <DataTable.Cell numeric>{contribution.bumpScore}</DataTable.Cell>
          </DataTable.Row>
        </TouchableRipple>
      </Animated.View>
    )
  })
}
