import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { Appbar, Divider, Menu } from 'react-native-paper'
import { AppNavigatorParams } from '../navigation/types'
import { useTranslation } from 'react-i18next'

interface NavigationBarMenuProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export default function AnonymousBarMenu(props: NavigationBarMenuProps) {
  const [visible, setVisible] = React.useState(false)
  const { navigation } = props
  const { t } = useTranslation('menuItems')


  /**
   * NOTE! If you change state and then navigate, the state change fires on an unmounting component.
   * Thats why we have a timeout of 1 ms
   */
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Appbar.Action icon="dots-vertical" onPress={() => setVisible(true)} />
      }>

      <Menu.Item
        leadingIcon="login"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('LoginMobileNumber'), 1)
        }}
        title={t('login')}
      />

      <Menu.Item
        leadingIcon="account-outline"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('Register'), 1)
        }}
        title={t('sign-up')}
      />

      <Menu.Item
        leadingIcon="help-circle-outline"
        onPress={() => {
          setVisible(false)
          setTimeout(() => navigation.navigate('FeatureToggles'), 1)
        }}
        title={t('AboutUs')}
      />



    </Menu>
  )
}
