import { Image, Linking, StyleSheet, Text, View } from 'react-native'
import { AppNavigatorParams } from '../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

export function IntroScreen(
  props: StackScreenProps<AppNavigatorParams, 'Intro'>
) {
  const { i18n } = useTranslation('common')
  const source =
    i18n.language === 'en'
      ? require('../assets/introEN.jpg')
      : require('../assets/introCN.jpg')
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          width: '100%',
          alignItems: 'center',
        },
        image: {
          flex: 1,
          width: '100%',
          height: '100%',
        },
      }),
    [i18n.language]
  )
  return (
    <View style={styles.container}>
      <Image source={source} style={styles.image} resizeMode={'contain'} />
      <Text
        style={{ color: 'blue' }}
        onPress={() => Linking.openURL('https://beian.miit.gov.cn')}
      >
        沪ICP备2023001417号-2A
      </Text>
    </View>
  )
}

export default IntroScreen
