import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { DataTable, TouchableRipple } from 'react-native-paper'
import { $RemoveChildren } from 'react-native-paper/lib/typescript/src/types'
import { useTheme } from '../../hooks/use-theme'

type MultiLineRowProps = $RemoveChildren<typeof TouchableRipple> & {
  title: string
  description: string
}
export function MultiLineCell(props: MultiLineRowProps) {
  const { style, title, description, ...rest } = props
  const theme = useTheme()
  const styles = StyleSheet.create({
    title: {
      color: theme.colors.onSurface,
      fontSize: theme.fonts.bodySmall.fontSize,
    },
    description: {
      color: theme.colors.onSurfaceVariant,
      fontSize: theme.fonts.labelSmall.fontSize,
    },
    container: {
      marginVertical: 6,
      flex: 1,
      flexGrow: 3,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      justifySelf: 'stretch',
    },
  })
  return (
    <TouchableRipple style={styles.container} {...rest}>
      <>
        <Text style={styles.title} numberOfLines={1} ellipsizeMode={'tail'}>
          {props.title}
        </Text>
        <Text
          style={styles.description}
          numberOfLines={1}
          ellipsizeMode={'tail'}
        >
          {props.description}
        </Text>
      </>
    </TouchableRipple>
  )
}
