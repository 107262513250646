import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Portal, Button, Text, TextInput } from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { StyleSheet } from 'react-native'

export const CANCEL_BUMP = Symbol('CANCEL_BUMP')
type Handler = (message: string | typeof CANCEL_BUMP) => void
type BumpJustificationProviderContextShape = (
  handler: Handler | null,
  title?: string
) => void

export const BumpJustificationProviderContext =
  createContext<BumpJustificationProviderContextShape>(() => {})

type BumpJustificationProviderProps = {
  children: ReactNode
}
export const BumpJustificationProvider = ({
  children,
}: BumpJustificationProviderProps) => {
  const [resultHandler, setResultHandler] = React.useState<{
    handler: Handler
  } | null>(null)
  const [title, setTitle] = React.useState<string | undefined>()
  const [justification, setJustification] = React.useState<string>('')
  const [disabled, setDisabled] = React.useState(false)
  const { t } = useTranslation('bumps')
  const theme = useTheme()
  const value = React.useCallback((handler: Handler | null) => {
    setResultHandler(handler ? { handler } : null)
    setTitle(title)
    setJustification('')
    setDisabled(false)
  }, [])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
        },
        title: {
          fontWeight: 'bold',
        },
        heading: {
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          paddingHorizontal: theme.spacing(2),
        },
      }),
    [theme]
  )

  return (
    <BumpJustificationProviderContext.Provider value={value}>
      {children}
      <Portal>
        <Dialog
          style={styles.dialog}
          visible={!!resultHandler}
          onDismiss={() => resultHandler!.handler(CANCEL_BUMP)}
        >
          <Dialog.Title>
            <Text variant="bodyMedium" style={styles.heading}>
              {t('Motivate your bump')}
            </Text>
          </Dialog.Title>
          <Dialog.Content>
            <TextInput
              mode={'outlined'}
              onChangeText={setJustification}
              label={t('Reason (optional)')}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              disabled={disabled}
              onPress={() => resultHandler!.handler(CANCEL_BUMP)}
            >
              {t('Cancel')}
            </Button>
            <Button
              mode={'contained'}
              style={styles.button}
              disabled={disabled}
              onPress={() => {
                setDisabled(true)
                resultHandler!.handler(justification)
              }}
            >
              {t('Bump')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </BumpJustificationProviderContext.Provider>
  )
}

export const useBumpJustificationDialog = () => {
  return useContext(BumpJustificationProviderContext)
}
