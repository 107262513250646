import * as React from 'react'
import { useMemo } from 'react'
import { ContributionType } from '../generated/graphql'
import { useTheme } from '../hooks/use-theme'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import { Icon } from './Icon'
import {
  Icon3dChallenge,
  Icon3dEdit,
  Icon3dInquiry,
} from '../utils/meldd-icons'
import { AppNavigation } from '../navigation/types'
import { useNavigation } from '@react-navigation/native'

interface ContributionsTypeCountProps {
  perspectiveId: string
  contributions: { contributionType: ContributionType }[]
  style?: StyleProp<ViewStyle>
}

export function ContributionsTypesCount(props: ContributionsTypeCountProps) {
  const theme = useTheme()
  const navigation = useNavigation<AppNavigation>()

  const inquiryCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Inquiry
      ).length,
    [props.contributions]
  )
  const challengeCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Challenge
      ).length,
    [props.contributions]
  )
  const editCount = useMemo(
    () =>
      props.contributions.filter(
        (c) => c.contributionType === ContributionType.Edit
      ).length,
    [props.contributions]
  )
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
        },
        count: {
          color: theme.colors.onSurface,
        },
      }),
    [theme]
  )
  return (
    <View style={[styles.container, props.style]}>

      <Icon3dInquiry
        size={16}
        onPress={() =>
          navigation.navigate('PerspectiveContributions', {
            contributionTypeFilter: ContributionType.Inquiry,
            articleId: props.perspectiveId,
          })}
        label={<Text variant={'titleSmall'} style={styles.count}>
          {inquiryCount}
        </Text>
        }
      />

      <Icon3dChallenge
        size={16}
        onPress={() =>
          navigation.navigate('PerspectiveContributions', {
            contributionTypeFilter: ContributionType.Challenge,
            articleId: props.perspectiveId,
          })}
        label={<Text variant={'titleSmall'} style={styles.count}>
          {challengeCount}
        </Text>
        }
      />



      <Icon3dEdit
        size={16}
        onPress={() =>
          navigation.navigate('PerspectiveContributions', {
            contributionTypeFilter: ContributionType.Edit,
            articleId: props.perspectiveId,
          })
        }
        label={<Text variant={'titleSmall'} style={styles.count}>
          {editCount}
        </Text>
        }
      />
    </View>
  )
}
