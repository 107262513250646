import { User } from '@sentry/react-native'
import React, { useContext, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import Toast from 'react-native-root-toast'
import { AuthContext, CurrentUser } from '../../../auth/auth-context'
import { useHeaderButton } from '../../../contexts/HeaderButtonProvider'
import {
  useUpdateUserMutation,
  UpdateUserInput,
} from '../../../generated/graphql'
import { AppTheme } from '../../../hooks/use-theme'
import {
  IconSaveProfile,
  IconEditProfile,
  IconCoRankBkg,
  IconClose,
} from '../../../utils/meldd-icons'
import { MessageType } from '../../../utils/message-type'
import { Icon, Text, TextInput } from 'react-native-paper'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import * as Clipboard from 'expo-clipboard'

function DisplayProfile({
  user,
  theme,
  onEditMelddId,
}: {
  user: CurrentUser
  theme: AppTheme
  onEditMelddId: () => void
}) {
  const styles = useStyle(theme)
  const { t } = useTranslation('profile')

  return (
    <View>
      <View style={styles.titleRow}>
        <Text variant={'titleLarge'}>
          {user?.firstName} {user?.lastName}
        </Text>
      </View>
      <View style={styles.usernameRow}>
        <Text variant={'labelMedium'}>
          Meldd ID:{' '}
          <Text style={{ color: theme.colors.onSurfaceDisabled }}>
            {user.username}
          </Text>
        </Text>

        <TouchableIconWithToolTip
          icon={<Icon source="content-copy" size={10} />}
          tooltip={t('copy')}
          onPress={() => {
            Clipboard.setString(user.username || '')
            Toast.show(t('copied'), MessageType.info)
          }}
        />
        <TouchableIconWithToolTip
          icon={<Icon source={IconEditProfile} size={10} />}
          tooltip={t('edit')}
          onPress={() => onEditMelddId()}
        />
      </View>

      <View style={styles.bio}>
        <Text style={styles.spacing} variant={'labelMedium'}>
          {t('BIO')}
        </Text>
        {user?.bio?.trim() ? (
          <Text>{user?.bio?.trim()}</Text>
        ) : (
          <Text style={{ color: theme.colors.onSurfaceDisabled }}>
            {t('User has currently no bio')}
          </Text>
        )}
      </View>
    </View>
  )
}

function EditMelddId({
  user,
  theme,
  onClose,
}: {
  user: CurrentUser
  theme: AppTheme
  onClose: () => void
}) {
  const { t } = useTranslation('profile')
  const { updateUserData } = useContext(AuthContext)
  const [doUpdateUser] = useUpdateUserMutation()

  const styles = useStyle(theme)

  const [melddId, setMelddId] = React.useState('') // Uniq internal id alias

  useEffect(() => {
    setMelddId(user.username || '')
    updateUserData(user)
  }, [user])

  const updateHandler = async () => {
    const input: UpdateUserInput = {
      id: user.id,
      username: melddId,
    }
    await doUpdateUser({ variables: { input } })
      .then(async () => {
        onClose()
        return Toast.show(t('Saved successfully'), MessageType.info)
      })
      .catch((error) => {
        if (error.graphQLErrors[0]?.extensions?.code === 'CONFLICT') {
          return Toast.show(t('meldd-id-already-exists'), MessageType.error)
        }
        return Toast.show(error.message, MessageType.error)
      })
  }

  return (
    <View style={styles.usernameRow}>
      <TextInput
        label={t('Meldd ID')}
        style={styles.input}
        mode={'outlined'}
        onChangeText={setMelddId}
        value={melddId}
      />
      <TouchableIconWithToolTip
        icon={<Icon source={IconSaveProfile} size={20} />}
        tooltip={t('save')}
        onPress={updateHandler}
      />
      <TouchableIconWithToolTip
        icon={<Icon source={IconClose} size={20} />}
        tooltip={t('cancel')}
        onPress={onClose}
      />
    </View>
  )
}
function EditProfile({
  user,
  theme,
  onEdit,
}: {
  user: CurrentUser
  theme: AppTheme
  onEdit: () => void
}) {
  const styles = useStyle(theme)
  const { updateUserData } = useContext(AuthContext)
  const { setDisabled, setHandler } = useHeaderButton('profileEdit')
  const [doUpdateUser] = useUpdateUserMutation()
  const { t } = useTranslation('profile')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [bio, setBio] = React.useState('')

  const updateHandler = useCallback(async () => {
    const input: UpdateUserInput = {
      id: user.id,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      bio: bio.trim() || null,
    }
    setDisabled(true)
    await doUpdateUser({ variables: { input } })
      .then(async () => {
        onEdit()
        return Toast.show(t('Saved successfully'), MessageType.info)
      })
      .catch(({ message, error }) => {
        return Toast.show(message, MessageType.error)
      })
    setDisabled(false)
  }, [firstName, lastName, bio])

  // We need to pass the update function to the handler whenever the values change
  useEffect(() => {
    setHandler(updateHandler)
  }, [updateHandler])

  useEffect(() => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setBio(user.bio || ' ')
    updateUserData(user)
  }, [user])

  return (
    <View>
      <View style={styles.row}>
        <TextInput
          label={t('First Name')}
          style={styles.input}
          mode={'outlined'}
          onChangeText={setFirstName}
          value={firstName}
        />
        <TextInput
          label={t('Last Name')}
          style={styles.input}
          mode={'outlined'}
          onChangeText={setLastName}
          value={lastName}
        />
      </View>
      <View style={styles.row}>
        <TextInput
          label={t('BIO')}
          style={styles.input}
          mode={'outlined'}
          onChangeText={setBio}
          value={bio}
        />
      </View>
    </View>
  )
}

export default function Profile({
  user,
  theme,
  isProfileOwner,
}: {
  user: CurrentUser
  theme: AppTheme
  isProfileOwner: boolean
}) {
  const [editing, setEditing] = React.useState(false)
  const [editingMelddId, setEditingMelddId] = React.useState(false)

  const { setHandler, setIcon, setDisabled } = useHeaderButton('profileEdit')

  const startEditing = useCallback(() => {
    setEditing(true)
    setIcon(IconSaveProfile)
  }, [])

  useEffect(() => {
    if (isProfileOwner) {
      setIcon(IconEditProfile)
      setDisabled(false)
      setHandler(() => {
        startEditing()
      })
    }
    return () => {
      setEditing(false)
      setHandler(null)
      setDisabled(true)
      setIcon(null)
    }
  }, [isProfileOwner])

  // We need to pass the update function to the handler whenever the values change
  useEffect(() => {
    if (editing) {
      setDisabled(false)
      return
    }
    if (isProfileOwner) {
      setIcon(IconEditProfile)
      setDisabled(false)
      setHandler(() => {
        startEditing()
      })
    }
  }, [editing, isProfileOwner])

  if (!user) {
    return null
  }
  if (editingMelddId) {
    return (
      <EditMelddId
        user={user}
        theme={theme}
        onClose={() => setEditingMelddId(false)}
      />
    )
  }
  if (editing) {
    return (
      <EditProfile user={user} theme={theme} onEdit={() => setEditing(false)} />
    )
  }
  return (
    <DisplayProfile
      user={user}
      theme={theme}
      onEditMelddId={() => setEditingMelddId(true)}
    />
  )
}

const useStyle = (theme: AppTheme) =>
  useMemo(
    () =>
      StyleSheet.create({
        row: {
          flexDirection: 'row',
          columnGap: theme.spacing(2),
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        },
        usernameRow: {
          flexDirection: 'row',
          columnGap: theme.spacing(1),
          alignItems: 'center',
        },
        titleRow: {
          flexDirection: 'row',
          columnGap: theme.spacing(2),
          alignItems: 'flex-end',
          marginBottom: theme.spacing(2),
        },
        input: {
          flex: 1,
        },
        bio: { marginVertical: theme.spacing(4) },
        spacing: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(2),
        },
      }),
    [theme]
  )
