import { ToastOptions } from 'react-native-root-toast'

export type ToastVariant = 'info' | 'warn' | 'error'
export const MessageType: Record<ToastVariant, ToastOptions> = {
  info: {
    duration: 2000,
    position: 50,
  },
  warn: {
    duration: 2000,
    position: 50,
    backgroundColor: '#6e4201',
  },
  error: {
    duration: 2000,
    position: 50,
    backgroundColor: '#570101',
  },
}
