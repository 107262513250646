import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { Icon } from '../Icon'

interface TabItem {
  icon: any // Sadly this is the real component type
  label: string
  value: string
  badge?: number
}

interface MelddTabsProps {
  items: TabItem[]
  selected: TabItem['value']
  onSelect: (value: TabItem['value']) => void
}

const MelddTabs: React.FC<MelddTabsProps> = ({ items, selected, onSelect }) => {
  const styles = useStyle()

  return (
    <View style={styles.container}>
      {items.map((item, index) => (
        <TouchableOpacity
          key={index}
          style={[
            styles.tabItem,
            item.value === selected && styles.selectedTabItem,
          ]}
          onPress={() => onSelect(item.value)}>
          <Icon source={item.icon} size={24} />
          <Text style={styles.tabText}>{item.label || item.value}</Text>
          {(item.badge || 0) > 0 && (
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{item.badge}</Text>
            </View>
          )}
        </TouchableOpacity>
      ))}
    </View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  badge: {
    position: 'absolute',
    top: -9,
    right: -9,
    backgroundColor: theme.colors.danger.main,
    borderRadius: 10,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
    marginBottom: 10,
  },
  tabItem: {
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  selectedTabItem: {
    borderBottomWidth: 2,
    borderColor: 'black',
  },
  tabText: {
    marginTop: 5,
    fontSize: 12,
  },
}))

export default MelddTabs
